import { useState, useEffect } from 'react'
import { DateTime, Interval } from "luxon"
import { useTranslation } from 'react-i18next'
import StyledTimeSelect from './StyledTimeSelect'

type TimeSelectProps = {
  [somekey : string] : any 
}

const TimeSelect = (props : TimeSelectProps) => {

  const { t, i18n } = useTranslation()

  useEffect(() => {
    setDates({
      fromDate: props.state.activeFilters.creation_date_from ? DateTime.fromISO(props.state.activeFilters.creation_date_from).toFormat(t("filter.timeformatstring")) : "",
      toDate: props.state.activeFilters.creation_date_to ? DateTime.fromISO(props.state.activeFilters.creation_date_to).toFormat(t("filter.timeformatstring")) : ""
    })
  }, [i18n.language, props.state.activeFilters.creation_date_from, props.state.activeFilters.creation_date_to, t]);

  const [dates, setDates] = useState({ // in localized format!
    fromDate: props.state.activeFilters.creation_date_from ? DateTime.fromISO(props.state.activeFilters.creation_date_from).toFormat(t("filter.timeformatstring")) : "",
    toDate: props.state.activeFilters.creation_date_to ? DateTime.fromISO(props.state.activeFilters.creation_date_to).toFormat(t("filter.timeformatstring")) : ""
  } as Record<string, string>)

  const [errors, setErrors] = useState({
    fromDate: null,
    toDate: null
  } as Record<string, string | null>)

  const verifyTimeInput = (e: React.FocusEvent<HTMLInputElement> ) => {
    if (e.currentTarget.value !== "") {
      const d = DateTime.fromFormat(e.currentTarget.value, t("filter.timeformatstring")) // `"${t("filter.timeformatstring")}"`
      if (!d.isValid) {
        setErrors({...errors, [e.currentTarget.name] : `filter.${d.invalidReason}`})
      } else {
        setErrors({...errors, [e.currentTarget.name] : null})
      }
    }
  }

   const submitTimeInput = (event : React.KeyboardEvent<HTMLInputElement> ) => {
    if (event.code ==='Enter') {
      
      const dfv = props.dateFromInputRef.current.value
      const dtv = props.dateToInputRef.current.value

      const dfIso = dfv === "" ? DateTime.fromISO("1000-01-01") : DateTime.fromFormat(dfv, t("filter.timeformatstring"))
      const dtIso = dtv === "" ? DateTime.fromISO("9999-12-31") : DateTime.fromFormat(dtv, t("filter.timeformatstring"))
      const i = Interval.fromDateTimes(dfIso, dtIso)
      if (!i.isValid) {
        setErrors({fromDate : `filter.${i.invalidReason}`, toDate: `filter.${i.invalidReason}` })
      } else {
        props.dateFromInputRef.current.blur()
        props.dateToInputRef.current.blur()
        setErrors({
          fromDate: null,
          toDate: null
        })
        props.handleChangeFilters({
          creation_date_from: dfv === "" ? "" : dfIso.toISODate(),
          creation_date_to: dtv === "" ? "" : dtIso.toISODate(),
        })
      }
    }
  }

  const changeDate = (e: React.ChangeEvent<HTMLInputElement> ) => {
    setDates({...dates, [e.currentTarget.name] : e.currentTarget.value})
  }

  return (
    <StyledTimeSelect>
      <form>
        <input className={errors.fromDate ? 'withErrors' : ''} type="text" name="fromDate" placeholder={t("filter.timeformat")}
          onBlur={(e) => verifyTimeInput(e)}
          onKeyDown={submitTimeInput} 
          onChange={changeDate}
          ref={props.dateFromInputRef}
          value={dates.fromDate}
          />{t('filter.until')}<br/>
        {errors.fromDate &&
          <div className="error">{t(errors.fromDate)}</div>
        }
        
        <input className={errors.toDate ? 'withErrors' : ''} type="text" name="toDate" placeholder={t("filter.timeformat")}
          onBlur={(e) => verifyTimeInput(e)}
          onKeyDown={submitTimeInput} 
          onChange={changeDate}
          ref={props.dateToInputRef} 
          value={dates.toDate}
          /><br/>
        {errors.toDate &&
          <div className="error">{t(errors.toDate)}</div>
        }
      </form>
    </StyledTimeSelect>
  )
} 


export default TimeSelect