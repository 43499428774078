import { useEffect, useState } from 'react'
import { Link, useParams, useHistory } from 'react-router-dom'
import Image from '../../components/Image'
import Button from '../../components/Button'
import Grid from '../../components/Grid'
// import Loading from '../../components/Loading'
import StyledDetail from './StyledDetail'
import Icon from '@mdi/react'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { mdiPlaylistPlus as AddIcon} from '@mdi/js'
import { mdiDownload as DownloadIcon } from '@mdi/js'
import { mdiClose as CloseIcon } from '@mdi/js'
import { mdiChevronLeft as LeftIcon} from '@mdi/js'
import { mdiChevronRight as RightIcon} from '@mdi/js'
import { useTranslation } from 'react-i18next'
import { DateTime } from "luxon"
import {UserContext} from '../../App'
import Loading from '../../components/Loading'
import { Helmet } from 'react-helmet-async'


const Detail= (props : any)=> {
  const { t, i18n } = useTranslation()
  const history = useHistory()
  const { id } = useParams<{ id: string }>();
  const [assetHasLoaded, setAssetHasLoaded] = useState(false)
  const [locationKeys, setLocationKeys] = useState([] as any[])
  const [navButtonInvert, setNavButtonInvert] = useState(false)

  useEffect(() => {
    // console.log(history)
    if (!assetHasLoaded && (!props.state.currentAsset || props.state.currentAsset.id !== id) ){
      const getAssetById = props.getAssetById
      getAssetById(id)
    } 
    setAssetHasLoaded(true)
    window.setTimeout(() => { window.scrollTo(0,0)}, 50) // really?
  }, [props.state.currentAsset, props.getAssetById, id, assetHasLoaded]);

  useEffect(() => {
    // if (assetHasLoaded && props.state.currentAsset && props.state.currentAsset.id === id ){
      const getAssetById = props.getAssetById
      getAssetById(id)
    // }
  }, [i18n.language, props.getAssetById, id]); // props.state.currentAsset, , id, assetHasLoaded

  useEffect(() => { // https://stackoverflow.com/a/60125216/2523254
    return history.listen((location, _action) => {
      if (history.action === 'PUSH') {
        setLocationKeys([location.key])
      }
      if (history.action === 'POP') {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([ _, ...keys ]) => keys)
          setAssetHasLoaded(false) // forward
        } else {
          setLocationKeys((keys) => [ location.key, ...keys ])
          setAssetHasLoaded(false) // back
        }
      }
    })
  }, [ locationKeys, history ])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const position = window.pageYOffset
    const navButton = document.getElementsByClassName("detail-nav-button")[0] as HTMLElement
    const moreWrapper = document.getElementsByClassName("detail__more-images-wrapper")[0] as HTMLElement
    if (moreWrapper && navButton) {
      if (position + navButton.offsetTop >= moreWrapper.offsetTop + 50) {
        setNavButtonInvert(true)
      } else if (position + navButton.offsetTop < moreWrapper.offsetTop + 50) {
        setNavButtonInvert(false)
      }
    }
  };

  const handleNextPrev = () => {
    setAssetHasLoaded(false)
    setNavButtonInvert(false)
  }
  
  const asset = props.state.currentAsset
  const pricelists = props.state.currentPricelists
  const currentAssetIndex = props.state.currentAssets.findIndex((a : any) => {return (a.id === id)}) 
  const prevAssetId = props.state.currentAssets[currentAssetIndex - 1]?.id
  const nextAssetId = props.state.currentAssets[currentAssetIndex + 1]?.id

  return (
    <StyledDetail id="detail" className={props.state.calculatorIsOpen ? 'pinned' : ''}>
       <Helmet>
        <title>{`AgFoPro - ${asset && asset.headline}`}</title>
      </Helmet>
      <UserContext.Consumer>
        {(user : any) => { return (
          <>
            <div className="detail__wrapper">
              {asset && assetHasLoaded && props.state.currentAsset.id === id &&
                <>
                  <h1>{asset.headline ? asset.headline : id}</h1>
                  <div className="detail__topWrapper">
                    
                    <div className="detail__imageWrapper">
                      {asset && assetHasLoaded && props.state.currentAsset.id === id &&
                        <>
                          <Image src={asset.associatedMedia[1].contentUrl}
                            className={`${asset.orientation === "vertical" ? "detail__img-portait" : "detail__img-landscape"}`}
                            alt={asset.caption}/>
                            {asset && assetHasLoaded && props.state.currentAssets.length && currentAssetIndex !== -1  
                              ? <>
                                  {prevAssetId &&
                                    <Link to={`/photo/${prevAssetId}`} className={`detail-nav-button left ${navButtonInvert ? "invert" : ""}`} onClick={handleNextPrev}>
                                      <Icon path={LeftIcon} />
                                    </Link>
                                  }
                                  {nextAssetId &&
                                    <Link to={`/photo/${nextAssetId}`} className={`detail-nav-button right ${navButtonInvert ? "invert" : ""}`} onClick={handleNextPrev}>
                                      <Icon path={RightIcon} />
                                    </Link>
                                  }
                                </>
                              : <></>
                            }
                          <div className="mobile-button-wrapper">
                            <Button text="Lightbox" icon={AddIcon} colorScheme="grey" onClick={props.handleOpenLightboxModal}/>
                            { user.customer && user.customer.directdownload === "1" &&
                              <Button text="Download" icon={DownloadIcon} colorScheme="grey" onClick={() => props.handleDownloadHires(id)}/>
                            }
                          </div>
                        </>
                      }
                    </div>
                    <div className="detail__infoWrapper">
                      <ul>
                        { asset.headline &&
                          <li>
                            <div>{t("detail.title")}</div>
                            <div><strong>{asset.headline}</strong></div>
                          </li>
                        }
                        <li>
                          <div>{t("detail.name")}</div>
                          <div><strong>{asset.id} {asset.name ? `/ ${asset.name}` : ""}</strong></div>
                        </li>
                        <li>
                          <div>{t("detail.photographer")}</div>
                          <div><strong>{asset.supplier ? asset.supplier.name : ''}</strong></div>
                        </li>
                        <li>
                          <div>{t("detail.copyright")}</div>
                          <div>{asset.copyright}</div>
                        </li>
                        {asset.contentLocation && asset.contentLocation.length > 0 && 
                          <li>
                            <div>{t("detail.location")}</div>
                            
                            <div>{asset.contentLocation.map((l : any, i : number) => {return (
                              <div key={`l-${i}`}>
                                {(l.address?.addressLocality || l.address?.addressRegion || l.address?.addressCountry) && 
                                  <div>
                                    {`${l.address?.addressLocality && l.address.addressLocality + ", "}${l.address?.addressRegion && l.address.addressRegion + ", "}${l.address?.addressCountry && l.address.addressCountry}`}
                                  </div>
                                }
                                {l.geo?.longitude && l.geo?.latitude && 
                                  <div key={`m-${i}`}>
                                    <a target="blank" href={`https://maps.google.com/?q=${l.geo?.latitude},${l.geo?.longitude}`}>Google Maps</a>
                                  </div>
                                }
                              </div>
                            )})}
                            </div>
                          </li>
                        }
                        <li>
                          <div>{t("detail.date")}</div>
                          <div>{DateTime.fromISO(asset.dateCreated).toLocaleString()}</div>
                        </li>
                        {asset.specialInstructions && asset.specialInstructions.length > 0 &&
                          <li>
                            <div>{t("detail.instructions")}</div>
                            <div>{asset.specialInstructions}</div>
                          </li>
                        }
                      </ul>
                      <div>
                        <p>{t("detail.description")}</p>
                        <p>{asset.caption}</p>
                      </div>

                      <br/>

                      {pricelists && pricelists.map((pl : any) => {
                        return (
                          <div className="detail__pricelistWrapper" key={`pricelist-${pl.id}`}>
                            <p><strong>{t("detail.calculate_for")}</strong></p>
                            <Button text={pl.description} size="large" style={{width: '100%'}} colorScheme="grey" onClick={() => props.handleOpenCalculator(pl.id)}>
                              <Icon style={{float: 'right'}} path={ArrowRightIcon} />
                            </Button>
                          </div>
                        )
                      })}

                    </div>
                  </div>
                
                  <div className="detail__bottomWrapper">
                    
                    <div className="detail__tags">
                      {asset.keywords && asset.keywords.length > 0 &&
                        <>
                        <p>{t("detail.keywords")}</p>
                        {asset.keywords.map((keyword : string) => { return (keyword !== "" &&
                          <Button onClick={() => props.handleSubmitSearch(keyword)} key={`tag-${keyword}`} text={keyword} colorScheme="grey"/>
                        )})}
                        </>
                      }
                    </div>

                    <div className="detail__infoWrapper">
                      <ul>
                        
                        {props.state.currentSeries &&
                          <li>
                            <div>Serie</div>
                            <div><Link to={`/story/${props.state.currentSeries.id}`}>{props.state.currentSeries.title}</Link></div>
                          </li>
                        }
                        
                        <li>
                          <div>{t("detail.image_size")}</div>
                          <div>{`${asset.width} x ${asset.height}px`}</div>
                        </li>
                        <li>
                          <div>{t("detail.licence_category")}</div>
                          <div>{t(`filter.${asset.license}`)}</div>
                        </li>
                        <li>
                          <div>{t("detail.property_release")}</div>
                          <div>{t(`detail.release.${asset.propertyReleased}`)}</div>
                        </li>
                        <li>
                          <div>{t("detail.model_release")}</div>
                          <div>{t(`detail.release.${asset.modelReleased}`)}</div>
                        </li>
                        
                        
                      </ul>
                    </div>
                  </div>
                </>
              }
            </div>

            {props.state.currentSeries &&
              <div className="detail__more-images-wrapper">
                <h1>Aus dieser Serie</h1>
                <Grid 
                  view="grid" 
                  assets={props.state.currentSeries.assets.slice(0, 24).map((a:any) => {return a.asset})} 
                  truncateCaption={true}  
                  handleOpenLightboxModal={props.handleOpenLightboxModal}
                  handleDownloadHires={props.handleDownloadHires}
                  variant="detail"
                  setAssetHasLoaded={setAssetHasLoaded}
                  />
              </div>
            } 

            <Link to={props.state.afterDetailUrl} className="detail-close">
              <Icon path={CloseIcon} />
            </Link>

            <Loading loading={props.state.loading} colorScheme="dark" variant="fixed"/> 
          </>
          )
        }}   
      </UserContext.Consumer>
    </StyledDetail>
  )
}

export default Detail