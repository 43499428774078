import axios from 'axios'
import * as qs from 'qs'
// import i18n from "i18next"

// const baseUrl = "https://api.demo.sodatech.com"

// const language = () => {
//   i18n.changeLanguage()
//   console.log(i18n.language)
//   return i18n.language === "de" ? "de-DE" : "en-US" 
// }

const apiv1 = axios.create({
  baseURL: 'https://api.agenturfocus.sodatech.com/',
  paramsSerializer: function (params) {
    return qs.stringify(params, {arrayFormat: 'brackets'})
  },
});

// const apiv2 = axios.create({
//   baseURL: 'https://api.agenturfocus.sodatech.com/'
// });

export {apiv1}