import StyledLightbox from './StyledLightbox'
import Series from '../../components/Series'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import { apiv1 } from '../../api'
import i18n from '../../i18n'
import { SpaceWrapper } from '../MyAccount/StyledMyAccount'
import { useTranslation } from 'react-i18next'
import Button from '../../components/Button'
import { mdiShare as ShareIcon } from '@mdi/js'
import { mdiPlaylistPlus as AddIcon} from '@mdi/js'
// import { mdiCheckBold as CheckIcon } from '@mdi/js'
// import { mdiClipboardMultiple as ClipBoardIcon} from '@mdi/js'
import { mdiClipboardCheckMultiple as ClipBoardCheckIcon} from '@mdi/js'


interface LightboxProps {
  // id?: string,
  token?: string,
  public?: boolean,
  lightbox?: any,
  style?: any,
  handleOpenLightboxModal?: any,
  handleDownloadHires?: any,
  handleDeleteLightbox?: any,
  handleRemoveFromLightbox?: any,
  handlePrepareDetail: any,
  handleAddAsLightbox?: any,
  userToken?: string,
}

const Lightbox = (props : LightboxProps) => {
  const { t } = useTranslation()
  
  const [loading, setLoading] = useState(true)
  const [lightbox, setLightbox] = useState(props.lightbox)
  const [lightboxCopied, setLightboxCopied] = useState(false)

  useEffect(() => {
    if (!lightbox && props.token) { // public lightbox
      console.log("loading lightbox")
      setLoading(true)
      apiv1.get(`lightboxes/${props.token}`, {
        headers: {'Accept-Language': i18n.language}
      })
      .then((response) => {
        const data = response.data as any
        console.log(data)
        setLightbox(data)
        setLoading(false)
      })
      .catch((error) => {
        console.warn(error)
        setLoading(false)
      })
    // } else if (props.lightbox && lightbox && props.lightbox.id !== lightbox.id) {
    } else if (props.lightbox) {
      console.log("setting lightbox")
      setLightbox(props.lightbox)
      setLoading(false)
    } else {
      setLoading(false)
    }
    window.setTimeout(() => { window.scrollTo(0,0)}, 50) // really?
  }, [lightbox, props.lightbox, props.token]) 

  useEffect(() => {
    window.setTimeout(() => {setLightboxCopied(false)}, 3000)
  }, [lightboxCopied])

  const copyLightboxLink = () => {
    apiv1.post(`/lightboxes/${props.lightbox.id}/share`, {}, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${props.userToken}` },
    })
    .then((response) => {
      const data = response.data as any
      // console.log(data)
      // window.prompt("Copy to clipboard: Ctrl+C, Enter", text)
      // navigator.clipboard.writeText( "http://localhost:3000/lightbox/" + data.hash).then(() => setLightboxCopied(true)) // 
      navigator.clipboard.writeText( "https://agentur-focus.pro/lightbox/" + data.hash).then(() => setLightboxCopied(true)) // 
    })
    .catch((error) => {console.log(error)})
  }

  return (
    <StyledLightbox className={props.public ? "public" : ""} style={props.style}>
      {lightbox 
        ? <>
            <Series
              variant="lightbox"
              series={lightbox}
              handleOpenLightboxModal={props.handleOpenLightboxModal}
              handleDownloadHires={props.handleDownloadHires}
              handleDeleteLightbox={props.public ? null : props.handleDeleteLightbox}
              handleRemoveFromLightbox={props.handleRemoveFromLightbox}
              handlePrepareDetail={props.handlePrepareDetail}
            />
            {props.public 
              ? <div className="center-button">
                  <Button 
                    onClick={() => props.handleAddAsLightbox(lightbox)}
                    text={t('stories.add_as_lightbox')} 
                    colorScheme="white"
                    size="large" 
                    float="center" 
                    icon={AddIcon} 
                    alignIcon="left"/> 
                </div>
              : <div className="center-button">
                  <Button 
                    onClick={() => copyLightboxLink()}
                    text={lightboxCopied ? t('lightboxes.link_copied') : t('lightboxes.copy_link')} 
                    colorScheme="white"
                    size="large" 
                    float="center" 
                    icon={lightboxCopied ? ClipBoardCheckIcon : ShareIcon} 
                    alignIcon="right"/> 
                </div>
            }
          </>
        : !loading && 
            <SpaceWrapper>
              <p><span>{t('lightboxes.lightbox_not_found')}</span></p>
            </SpaceWrapper>
      }
      
      <Loading loading={loading} colorScheme="dark"/> 
    </StyledLightbox>
  )
}

Lightbox.defaultProps = {
  public: false
}

export default Lightbox