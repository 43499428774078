import { keyframes } from '@emotion/css'
import styled from '@emotion/styled'
import StyledButton from '../../components/Button/StyledButton'

const StyledHomeLink = styled('div')`
  padding: 30px 22px 0 0; */
  float: left; 
  position: fixed;
  svg {
    height: 24px;
    width: auto;
  }
`

const StyledHeader = styled('div')`
  position: relative;
  background-color: ${props => props.theme.colors.black};
  z-index: 20;
  border-bottom: 1px solid ${props => props.theme.colors.blackAuxiliary};
  width: 100vw;
  transition: background-color 0.2s ease-out;
  ${StyledHomeLink} {
    a svg:first-of-type {
      display: none;
    }
    a svg:last-of-type {
      display: block;
    }
  }
  .search-icon {
    display: none;
  }
  .login_text_mobile {
    display: none;
  }
  &.sticky {
    position: fixed; // sticky scrolls out
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    .fixed-wrapper {
      position: absolute; // fix for iOS 16
      .center-links {
        display: none;
      }
    }
    ${StyledHomeLink} {
      position: absolute; // fix for iOS 16
      a svg:first-of-type {
        display: block;
      }
      a svg:last-of-type {
        display: none;
      }
    }
  }
  .fixed-wrapper {
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    height: 80px;
    padding: 0 4%;
    background-color: ${props => props.theme.colors.black};
    border-bottom: 1px solid ${props => props.theme.colors.blackAuxiliary};
    z-index: 40;
    .center-links {
      text-align: center;
      margin-top: 28px;
      a {
        text-decoration: none;
        color: ${props => props.theme.colors.white};
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }
  @media (max-width: 991px) {
    .login_text_mobile {
      display: inline;
    }
    .login_text_desktop {
      display: none;
    }
  }
  
  @media (max-width: 767px) {
    &.sticky {
      height: 60px;
      .search-icon {
        display: block;
      }
    }
    ${StyledHomeLink} {
      padding-top: 22px;
      a svg {
        height: 18px !important;
        width: auto;
      }
      a svg:first-of-type {
        display: block !important;
      }
      a svg:last-of-type {
        display: none  !important;
      }
    }
    .fixed-wrapper {
      height: 60px;
      .center-links {
        display: none;
      }
    }
  }
`

const StyledMenuBar = styled('div')`
  padding: 23px 0 0 0;
  position: fixed;
  top: 0;
  right: 4%;
  color: ${props => props.theme.colors.white};
  z-index: 40;
  .menu-icon {
    float: right;
    position: relative;
    top: -3px;
    cursor: pointer;
    svg {
      width: 36px !important;
      height: 36px !important;
      path {
        fill: ${props => props.theme.colors.white};
      }
    }
  }
  ${StyledButton} {
    &.button__light {
      &.language-button {
        border: none;
        text-transform: uppercase;
        margin-right: 15px;
        margin-top: 1px;
      }
      color: ${props => props.theme.colors.white} !important;
      svg {
        path {
          fill: ${props => props.theme.colors.white} !important;
        }
      }
      &:hover {
        &:not(.button__icon-only) {
          color: ${props => props.theme.colors.black} !important;
          svg {
            path {
              fill: ${props => props.theme.colors.black} !important;
            }
          }
        }
        &.button__icon-only {
          color: ${props => props.theme.colors.blackHover} !important;
          svg {
            path {
              fill: ${props => props.theme.colors.blackHover} !important;
            }
          }
        }
      }
    }
  }
  @media (max-width: 767px) {
    padding-top: 15px;
    .menu-icon {
      top: 3px;
      margin-left: 0;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
    button {
      margin-right: 8px !important;
      margin-left: 5px !important;
      &.language-button {
        margin-right: 8px !important;
        margin-left: 0 !important;
      }
    }
    .desktop-only,
    .search-icon {
      display: none;
    }
    &.sticky {
      .search-icon {
        display: block;
      }
    }
  }
`

const fade = keyframes`
  0% { opacity: 1; }
  75% { opacity: 1; }
  90% { opacity: 0; }
`
const bounce = keyframes`
  0% { border-width: 10px; }
  15% { border-width: 0; }
  90% { border-width: 0; }
`

const StatusIcon = styled('div')`
  float: right;
  position: relative;
  top: 5px;
  animation: ${fade} 1.8s linear;
  animation-iteration-count: 1;
  &.success, 
  &.fail {
    svg {
      border-width: 0px;
      border-style: solid;
      border-color: rgba(255,255,255,0.7);
      border-radius: 50%;
      animation: ${bounce} 1.8s linear;
      animation-iteration-count: 1;
      width: 20px !important;
      height: 20px !important;
      path {
        fill: ${props => props.theme.colors.confirm} !important;
      }
    }
  }
  &.fail {
    svg {
      path {
        fill: #f80c0c !important;
      }
    }
  }
`

export { StyledHeader, StyledHomeLink, StyledMenuBar, StatusIcon }