import {StyledSidebarLayout, StyledLeftColumn, StyledRightColumn, StyledMobileTab} from './StyledSidebarLayout'
import Icon from '@mdi/react'
import { mdiTuneVertical as SettingsIcon} from '@mdi/js';
import { mdiArrowCollapseLeft as CloseSettingsIcon } from '@mdi/js';

interface SidebarLayoutProps {
  colorScheme: "light" | "dark",
  showMobileSidebar: boolean,
  handleSetShowMobileSidebar: any,
  leftColumn: JSX.Element | null,
  rightColumn: JSX.Element | null,
  className?: string,
  style?: {[somekey : string] : string },
}

const SidebarLayout = (props : SidebarLayoutProps) => {

  const toggleSidebar = () => {
    props.handleSetShowMobileSidebar(!props.showMobileSidebar)
  }

  return (
    <StyledSidebarLayout style={props.style} className={`${props.className} ${props.colorScheme}`}>
      <StyledLeftColumn className={`${props.colorScheme} ${props.showMobileSidebar ? 'sidebar-open' : 'sidebar-closed'}`}>
        <div className="fixed-wrapper">
          <StyledMobileTab onClick={toggleSidebar} className={`${props.colorScheme}`}>
            <Icon path={props.showMobileSidebar ? CloseSettingsIcon : SettingsIcon} />
          </StyledMobileTab>
          <div className="crop-wrapper">
            <div className="scroll-wrapper"> {/* scroll container*/}
              {props.leftColumn}
            </div>
          </div>
        </div>
      </StyledLeftColumn>
      <StyledRightColumn className={props.colorScheme}>
        {props.rightColumn}
      </StyledRightColumn>
      
    </StyledSidebarLayout>
  )
}

SidebarLayout.defaultProps = {
  colorScheme: "light",
  className: ''
}

export default SidebarLayout