import styled from '@emotion/styled'

const StyledFeed = styled('div')`
  position: relative;
  margin-top: 80px;
  min-height: 100vh;
  @media (max-width: 767px) {
    margin-top: 60px;
  }
`

export default StyledFeed