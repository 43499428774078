// https://stackoverflow.com/a/54343182/2523254

import{ useEffect, Fragment, ComponentType } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps, StaticContext } from 'react-router'

const ScrollToTop : ComponentType<RouteComponentProps<any, StaticContext, unknown>> = ({ history, children}) => {

  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0)
    });
    return () => {
      unlisten()
    }
  }, [history])

  return <Fragment>{children}</Fragment>
}

export default withRouter(ScrollToTop)