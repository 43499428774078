import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import Grid from '../../components/Grid'
import StyledFeed from './StyledFeed'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import SectionHeader from '../../components/SectionHeader'
import pools from '../../config/pools'
import Button from '../../components/Button'


const Feed = (props : any) => {

  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => { // load
    if (!props.state.loading && !props.state.feeds.magnum && !props.state.feeds.maps && !props.state.feeds.focus ) { 
      console.log("load feeds")
      const handleSelectPool = props.handleSelectPool
      handleSelectPool(null)
    } 
  }, [props.state.feeds, props.state.loading, props.handleSelectPool])

  useEffect(() => { // language change
    if (language !== i18n.language) {
      const getFeed = props.getFeed 
      getFeed(null)
      setLanguage(i18n.language)
    }
  }, [i18n.language, language, props.getFeed])

  return (
    <StyledFeed>
      <>
        {Object.entries(props.state.feeds).map(([poolSlug, assets] : any) => {
          const poolName = pools.find((p:Pool) => p.slug === poolSlug)?.name
          return (
            <div key={`feed-${poolSlug}`}>
              <section className={props.state.loading ? 'loading' : ''}>
                <div >

                  {/* Magnum image reload fix */}
                  {poolSlug === "magnum" && assets && assets[0] && assets[0]?.associatedMedia[0] &&
                    <img alt={assets[0]?.caption} style={{width: "0", height: "0", position: "absolute"}} className="magnum-test" src={assets[0]?.associatedMedia[0]?.contentUrl} onError={() => {props.getFeed(null)}} />
                  }

                  <SectionHeader variant="sticky" title={`${t('stories.new_photos')} — ${poolName}`} byline="" style={{marginBottom: "50px"}}/>
                  <Grid 
                    view="grid" 
                    assets={assets} 
                    truncateCaption={true} 
                    handleOpenLightboxModal={props.handleOpenLightboxModal} 
                    handleDownloadHires={props.handleDownloadHires}
                    handlePrepareDetail={props.handlePrepareDetail}
                    scrollToTop={true}
                    scrollToAsset={props.state.currentAsset}
                    scrollToSeries={props.state.currentSeries}
                  />
                </div>
              </section>

              {(!props.state.loading) &&
                <div className="center-button border-top" > 
                  <Button href={`/feed/${poolSlug}`} text={`${t('stories.fotofeed')} ${poolName}`} size="large" float="center" icon={ArrowRightIcon} alignIcon="right" colorScheme="grey"/>
                </div>
              }
              <Loading loading={props.state.loading} variant="section"/>
            </div>
          )

        })}
      </>
    </StyledFeed>  
  )
}

export default Feed
