import { useTranslation } from 'react-i18next'
import SectionHeader from '../../components/SectionHeader'
import Series from '../../components/Series'
import StyledHome from './StyledHome'
import Button from '../../components/Button'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import Grid from '../../components/Grid'
import pools from '../../config/pools'

// leaving this for reference of prop destructuring
// interface HomeState {
//   state: {
//     homeStories: any,
//     loadingHomeStories: boolean,
//     selectedPool: Pool | null,
//     [key: string] : any
//   },
//   [key: string] : any
// }
// const Home = (props : any, {getHomeContent = () => {return}, state : {homeStories, loadingHomeStories, selectedPool} = {homeStories: null, loadingHomeStories: false, selectedPool: null}} : HomeState, ) => {

const Home = (props : any) => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)
  
  useEffect(() => { // language change
    if (language !== i18n.language) {
      const getHomeContent = props.getHomeContent 
      getHomeContent(props.state.selectedPool)
      setLanguage(i18n.language)
    }
  }, [i18n.language, language, props.getHomeContent, props.state.selectedPool])

  useEffect(() => { // load
    if (!props.state.loadingHomeStories && (!props.state.homeStories || (props.match.params.pool !== props.state.selectedPool?.slug))) {
      const handleSelectPool = props.handleSelectPool
      handleSelectPool(pools.find((p:Pool) => {return (p.slug === props.match.params.pool)}) || null)
    }
  }, [props.state.homeStories, props.state.loadingHomeStories, props.match.params.pool, props.state.selectedPool, props.handleSelectPool])

  console.log("render home")
  // console.log(props.state.homeStories)
  // console.log(props.state.homeFeed)

  return (
    <StyledHome>
      
      <section className={`faded ${props.state.loadingHomeStories ? 'loading' : ''}`} style={{position: "relative"}}>
        <SectionHeader variant="sticky faded" title={`${t("stories.new_stories")} ${props.state.selectedPool ? `— ${props.state.selectedPool.name}` : '' }`} byline=""/>
        { props.state.homeStories && props.state.homeStories.slice(0,6).map((s : any, i: number) => {
          return(
            <Series
              key={`story-${s.id}-${i}`}
              series={s}
              handleOpenLightboxModal={props.handleOpenLightboxModal}
              handleDownloadHires={props.handleDownloadHires}
              handlePrepareDetail={props.handlePrepareDetail}
              noOfPhotos={4}
              preview={true}
              scrollToAsset={props.state.currentAsset}
              scrollToSeries={props.state.currentSeries}
            />
          )
        })}
        
        <div className="all-button main-button">
          <Button href="/stories" text={`${t('stories.all_stories')}`} size="large" float="center" icon={ArrowRightIcon} alignIcon="right" colorScheme="grey"/>
        </div>
        <Loading loading={props.state.loadingHomeStories} variant="section"/>
      </section>

      {(!props.state.selectedPool || ["focus", "magnum", "maps"].includes(props.state.selectedPool.slug)) &&
        <section className={props.state.loadingHomeFeed ? 'loading' : ''}>

          {Object.entries(props.state.homeFeed).map(([poolSlug, assets] : any) => {
            
            if (!props.state.selectedPool || props.state.selectedPool.slug === poolSlug ) {
              return (
                <div key={`feed-${poolSlug}`}>

                  {/* Magnum image reload fix */}
                  {poolSlug === "magnum" && assets && assets[0] && assets[0]?.associatedMedia[0] &&
                    <img alt={assets[0]?.caption} style={{width: "0", height: "0", position: "absolute"}} className="magnum-test" src={assets[0]?.associatedMedia[0]?.contentUrl} onError={() => {props.getHomeContent(props.state.selectedPool)}} />
                  }

                  <SectionHeader variant="sticky" title={`${t('stories.new_photos')} — ${pools.find((p:Pool) => p.slug === poolSlug)?.name}`} byline="" style={{marginBottom: "50px"}}/>
                  <Grid 
                    view="grid" 
                    assets={assets} 
                    truncateCaption={true} 
                    handleOpenLightboxModal={props.handleOpenLightboxModal} 
                    handleDownloadHires={props.handleDownloadHires}
                    handlePrepareDetail={props.handlePrepareDetail}
                    scrollToAsset={props.state.currentAsset}
                    scrollToSeries={props.state.currentSeries}
                  />
                
                  <div className="all-button main-button border-top" > 
                    <Button href={`/feed/${poolSlug}`} text={`${t('stories.fotofeed')} ${pools.find((p:Pool) => p.slug === poolSlug)?.name}`} size="large" float="center" icon={ArrowRightIcon} alignIcon="right" colorScheme="grey"/>
                  </div>
                </div>
              )
            } else {
              return null
            }
          })}

          <Loading loading={props.state.loadingHomeFeed} variant="section"/>
        </section>
      }
      <Loading loading={props.state.loading && !props.state.loadingHomeFeed && !props.state.loadingHomeStories}/> 
    </StyledHome>

  )
}

export default Home