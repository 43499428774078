import styled from '@emotion/styled'

const StyledSectionHeader = styled('div')`
  width: 100%;
  padding: 40px 4% 40px 4%;
  overflow: hidden;
  border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  h1 {
    text-transform: uppercase;
    float: left;
    margin: 0;
  }
  h2 {
    float: right;
    margin: 0;
    margin-top: 5px;
  }
  &.top-border {
    border-top: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  }
  &.sticky {
    position: sticky;
    top: calc(80px - 1px);
    z-index: 9;
    background-color: ${props => props.theme.colors.secondary};
  }
  &.faded {
    background-color: ${props => props.theme.colors.secondaryShaded} !important;
  }
  @media (max-width: 1199px) {
    
  }
  @media (max-width: 767px) {
    padding: 20px 4% 20px 4%;
    &.sticky {
      top: calc(60px - 1px);
    }
  }
  `

export default StyledSectionHeader
