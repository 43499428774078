import { useTranslation } from 'react-i18next'
import { StyledSidebarGroup } from '../../components/SidebarLayout/StyledSidebarLayout'
import { useHistory } from 'react-router-dom'
import Button from '../../components/Button'

const SidebarUser = (props : any) => {

  const { t } = useTranslation()
  const history = useHistory()

  const logOutAndLeave = () => {
    history.push('/')
    props.handleLogOut()
  }

  return (
    <div style={{paddingBottom: "30px"}}>
      <StyledSidebarGroup>
        <p>{t('account.my_account')}</p>
        <Button href="/my-account/profile" onClick={() => props.handleSetShowMobileSidebar(false)} colorScheme="grey" style={{clear: "left"}}>{t('account.profile')}</Button>
        <Button href="/my-account/change-password" onClick={() => props.handleSetShowMobileSidebar(false)} colorScheme="grey" style={{clear: "left"}}>{t('account.change_password')}</Button>
        <Button href="/my-account/orders" onClick={() => props.handleSetShowMobileSidebar(false)} colorScheme="grey" style={{clear: "left"}}>{t('account.offers_invoices')}</Button>
        <Button href="/my-account/downloads" onClick={() => props.handleSetShowMobileSidebar(false)} colorScheme="grey" style={{clear: "left"}}>{t('account.downloads')}</Button>
        <Button href="/my-account/cart" onClick={() => props.handleSetShowMobileSidebar(false)} colorScheme="grey" style={{clear: "left"}}>{`${t('account.cart')} (${props.state.carts[0] && props.state.carts[0].articles ? props.state.carts[0].articles.length : 0})`}</Button>
        <Button onClick={logOutAndLeave} colorScheme="grey" style={{clear: "left"}}>{t('menu.sign_out')}</Button>
      </StyledSidebarGroup>
      <StyledSidebarGroup>
        <p>{t('account.lightboxes')}</p>
        <Button href={`/my-account/lightboxes`} onClick={() => props.handleSetShowMobileSidebar(false)} colorScheme="grey" style={{clear: "left"}}>{t('account.all_lightboxes')}</Button>
        { props.state.lightboxes && props.state.lightboxes.map( (lb : any, i : number) => {
            return (
              <Button href={`/my-account/lightboxes/${lb.id}`} onClick={() => props.handleSetShowMobileSidebar(false)} key={`lightbox-${i}`} colorScheme="grey" style={{clear: "left"}}>{`${lb.name}`}</Button> //  (${lb.assetCount})
            )
          })
        }  
      </StyledSidebarGroup>
    </div>
  )
}

export default SidebarUser