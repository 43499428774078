import StyledGridViewToggle from './StyledGridViewToggle'
import { mdiViewModule as GridIcon} from '@mdi/js'
import { mdiViewList as ListIcon} from '@mdi/js'
import { mdiViewQuilt as WallIcon} from '@mdi/js'
import Button from '../Button'
import { useTranslation } from 'react-i18next'

interface GridViewToggleProps {
  view: string,
  handleChangeGridView: any,
  style?: any
}

const GridViewToggle = (props : GridViewToggleProps) => {
  const { t } = useTranslation()
  return (
    <StyledGridViewToggle style={props.style}>
      <Button text="Grid" icon={GridIcon} float="right" selected={props.view === "grid"} onClick={() => props.handleChangeGridView("grid")}/>
      <Button text="List" icon={ListIcon} float="right" selected={props.view === "list"} onClick={() => props.handleChangeGridView("list")}/>
      <Button text="Wall" icon={WallIcon} float="right" selected={props.view === "wall"} onClick={() => props.handleChangeGridView("wall")}/>
      <div className="label"> {t("filter.view")}:</div>
    </StyledGridViewToggle>
  )
}

export default GridViewToggle