import styled from '@emotion/styled'

const StyledSeries = styled('div')`
  width: 100%;
  position: relative;
  padding-bottom: 56px;
  .headline {
    margin: 0 4% 0 4%;
    padding: 25px 0;
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    h1 {
      display: inline-block;
      max-width: calc(100% - 100px);
    }
    .pool {
      float: right;
      font-size: 13px;
      font-family: 'SoehneBold';
      margin-top: 9px;
    }
  }
  &.preview {
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    &.lightbox {
      border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    }
  }
  &.lightbox{
    .headline {
      border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    }
  }
  .info {
    padding: 20px 4% 25px 4%;
    overflow: hidden; 
    & > div:first-of-type {
      margin: 0;
      padding: 0;
      float: left;
    }
    & > div:last-of-type {
      float: right;
    }
  }
  .description {
    margin: 0 4%;
    width: 60%;
    padding: 20px 0 20px 0;
    overflow: hidden; 
    font-size: 18px;
    line-height: 1.55;
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  }
  .all-button {
    margin: 0 4% 0 4%;
    text-align: center;
    a {
      text-decoration: none !important;
    }
  }
  @media (max-width: 1199px) {
    .all-button {
      padding: 30px 0;
    }
    .description {
      width: unset;
    }
  }
  @media (max-width: 767px) {
    .headline {
      h1 {
        max-width: calc(100% - 70px);
      }
      .pool {
        font-size: 10px;
        margin-top: 4px;
      }
    }
    .info {
      font-size: 13px;
    }
    .description {
      font-size: 13px;
    }
    .all-button {
      padding: 0;
    }
  }
`

export default StyledSeries
