import styled from '@emotion/styled'
import { SpaceWrapper } from '../../containers/MyAccount/StyledMyAccount'
import StyledGrid from '../Grid/StyledGrid'
import StyledSeries from '../Series/StyledSeries'

const StyledSidebarLayout = styled('div')`
  position: relative;
  top: 80px; // sticky header!
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
  transition: background-color 0.2s ease-out;
  &.dark {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
  }
  @media (max-width: 767px) {
    top: 60px;
  }
`
const StyledMobileTab = styled('div')`
  display: none;
  @media (max-width: 991px) { 
    display: block;
    width: 37px;
    height: 40px;
    position: absolute;
    top: 60px;
    right: -37px;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 0 20px 20px 0;
    padding: 9px 4px;
    cursor: pointer;
    z-index: 10;
    transition: left 0.25s ease-out;
    svg {
      width: 20px !important;
      height: 20px !important;
      path {
        fill: ${props => props.theme.colors.primaryContrast} !important;
      }
    }
  }
`
const StyledSidebarGroup = styled('div')`
  width: 100%;
  font-size: 13px;
  font-family: 'SoehneBuch';
  padding: 25px 15.78% 30px 21.05%;
  border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  overflow: hidden;
  p {
    margin-bottom: 15px;
  }
  button {
    max-width: 100%;
  }
`
const StyledLeftColumn = styled('div')`
  flex-grow: 1;
  float: left;
  width: 19%;
  /* min-width: 290px; */
  border-right: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  min-height: 100vh; // ??? should be calc(100vh - 80px); 
  background-color: ${props => props.theme.colors.secondary};
  transition: background-color 0.2s ease-out;
  padding-bottom: 50px;
  &.dark {
    border-right: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    background-color: ${props => props.theme.colors.primary};
    ${StyledSidebarGroup} {
      border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary}
    }
    ${StyledMobileTab} {
      background-color: ${props => props.theme.colors.primaryContrast};
      svg {
        path {
          fill: ${props => props.theme.colors.primary} !important;
        }
      }
    }
  }
  div.fixed-wrapper {
    position: sticky;
    top: 80px;
  }
  div.scroll-wrapper { /* scroll container*/
    min-height: 200px;
    /* padding-top: 13px; */
    /* position: fixed;
    width: 19%; // necessary for fixed, as does not inherit width */
    top: 80px;
    max-height: calc(100vh - 80px);
    overflow: auto;
    /* width: calc(19% + 15px); // hide scrollbar */
    /* min-width: 290px; */
    width: calc(100% + 16px);
  }
  @media (max-width: 991px) { 
    /* display: block; */
    position: fixed;
    top: 0;
    left: -267px;
    z-index: 10;
    width: 270px;
    border-right: 3px solid ${props => props.theme.colors.primary};
    transition: left 0.25s ease-out;
    &.sidebar-open {
      left: 0;
    }
    &.dark {
      border-right: 3px solid ${props => props.theme.colors.primaryContrast};
    }
    div.fixed-wrapper {
      position: absolute;
      top: 80px; // header offset 
      width: 270px;
      left: 0;
    }
    div.crop-wrapper {
      position: relative;
      top: 0;
      left: 0;
      width: 267px;
      overflow: hidden;
      height: calc(100vh - 80px);
    }
    div.scroll-wrapper {
      position: relative;
      top: 0;
      width: 285px; /* hide scrollbar*/
    } 
    ${StyledSidebarGroup} {
      width: calc(100% - 2px);
    }
  }
`
const StyledRightColumn = styled('div')`
  flex-basis: 0;
  flex-grow: 999;
  float: left;
  position: relative;
  width: 81%;   
  min-height: 100vh; // ??? should be calc(100vh - 80px);
  background-color: ${props => props.theme.colors.secondary};
  color: ${props => props.theme.colors.primary};
  transition: background-color 0.2s ease-out;
  padding-bottom: 80px;
  &.dark {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
  }
  ${StyledSeries} {
    .headline,
    .all-button {
      margin-right: 6%;
    }
    .info {
      padding-right: 6%;
    }
  }
  ${StyledGrid} {
    padding-right: 6%;
  }
  ${SpaceWrapper} {
    margin-right: 6%;
  }
  @media (max-width: 991px) {
    ${StyledSeries} {
      .headline,
      .all-button {
        margin-right: 4%;
      }
      .info {
        padding-right: 4%;
      }
    }
    ${StyledGrid} {
      padding-right: 4%;
    }
    ${SpaceWrapper} {
      margin-right: 4%;
    }
  }
`

export {StyledSidebarLayout, StyledLeftColumn, StyledRightColumn, StyledSidebarGroup, StyledMobileTab}