import styled from '@emotion/styled'

const StyledButton = styled('button')`
  /* --webkit-appearance: none; */
  all: unset;
  box-sizing: border-box;
  height: 28px;
  border: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  border-radius: 2px;
  float: left;
  padding: 1px 10px 3px 10px;
  margin-right: 10px;
  margin-bottom: 9px;
  font-size: 13px;
  color: ${props => props.theme.colors.primary};
  background-color: transparent;
  user-select: none;
  text-decoration: none !important;
  line-height: 1.7;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &.button__interactive:not(.button__icon-only) { // default color: dark type on tranparent bg, light grey borders
    cursor: pointer;
    &:hover,
    &.selected {
      background-color: ${props => props.theme.colors.secondaryAuxiliary};
    }
    &.selected {
      cursor: default;
      pointer-events: none;
    }
  }
  svg {
    float: left;
    width: 22px !important;
    height: 22px !important;
    padding-right: 5px;
    padding-top: 2px;
    path {
      fill: ${props => props.theme.colors.primary};
    }
  }
  &.button__icon-right {
    svg {
      float: right;
      padding-left: 8px;
      padding-right: 3px;
      fill: ${props => props.theme.colors.primary};
    }
  }
  &.button__grey { // white on dark, _grey outline, _grey hover, e.g. My Account nav buttons, Detail toolbar
    /* background-color: transparent; */
    background-color: ${props => props.theme.colors.primary};;
    color: ${props => props.theme.colors.primaryContrast};
    border: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    svg {
      path {
        fill: ${props => props.theme.colors.primaryContrast};
      }
    }
    &.button__interactive:not(.button__icon-only) {
      &:hover,
      &.selected {
        background-color: ${props => props.theme.colors.primaryHover} !important;
      }
    }
  }
  &.button__light:not(.button__icon-only) {
    color: ${props => props.theme.colors.primaryContrast};
    svg {
      path {
        fill: ${props => props.theme.colors.primaryContrast};
      }
    }
    &.button__interactive {
      &:hover,
      &.selected {
        color: ${props => props.theme.colors.primary};
        svg {
          path {
            fill: ${props => props.theme.colors.primary};
          }
        }
      }
    }
  }
  &.button__white:not(.button__icon-only) {
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryContrast};
    svg {
      path {
        fill: ${props => props.theme.colors.primary};
      }
    }
    &.button__interactive {
      &:hover,
      &.selected {
        background-color: ${props => props.theme.colors.secondaryAuxiliary};
      }
    }
  }
  &.button__icon-only {
    border: none;
    padding: 3px 0;
    svg {
      width: 20px !important;
      height: 20px !important;
      padding-right: 2px;
    }
    &.button__icon-large {
      width: 25px !important;
      height: 25px !important;
    }
    &.button__interactive:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${props => props.theme.colors.primaryHover};
        }
      }
    }
    &.button__light {
      color: ${props => props.theme.colors.primaryContrast};
      svg {
        path {
          fill: ${props => props.theme.colors.primaryContrast};
        }
      }
    }
  }
  &.button__large {
    padding: 12px 18px 12px 20px;
    font-size: 16px;
    line-height: 1.35;
    height: 48px;
    svg {
      padding-top: 0;
      width: 30px !important;
      height: 22px !important;
    }
    small { // hack for Detail
      line-height: 1;
      vertical-align: text-top;
      margin-right: 8px;
    }
    big {
      /* position: relative;
      top: -5px; */
      font-size: 28px;
      line-height: 0.7;
    }
  }
  &.button__float-center {
    float: none;
    clear: both;
    margin: 0 auto;
  }
  &.button__float-right {
    float: right;
    margin-right: 0;
    margin-left: 10px;
  }
  &.button__clear {
    clear: left;
  }
  &:disabled {
    pointer-events: none;
    opacity: 0.7;
    cursor: not-allowed;
  }
  span {
    text-decoration: none !important;
  }
  @media (max-width: 767px) {
    &.button__large {
      padding: 12px 18px 12px 20px;
      font-size: 13px;
    }
    &.button__float-right {
      margin-left: 18px;
    }
  }
`

export default StyledButton