import styled from '@emotion/styled'
import caret from './caret.svg'

const StyledSelect = styled('div')`
  padding: 15px 0;
  &.half {
    width: calc((100% - 20px)/2);
    float: left;
    &:first-of-type {
      margin-right: 20px;
      clear: left;
    }
  }
  label {
    display: block;
    margin-bottom: 15px;
  }
  select {
    --webkit-appearance: none;
    appearance: none;
    width: 100%;
    padding: 12px 42px 12px 16px;
    font-size: 16px;
    line-height: 1.35;
    height: 48px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    border: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    background-image: url(${caret});
    background-position: right .7em top 50%;
    background-repeat: no-repeat;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
    &:focus {
      outline: 1.5px solid ${props => props.theme.colors.primaryHighlight}; 
      outline-offset: -1px;
    }
    &:disabled {
      opacity: 0.7;
    }
  }
  &.withErrors {
    select {
      border: 1px solid ${props => props.theme.colors.warn};
      border-color: ${props => props.theme.colors.warn};
    }
  }
  &.grey {
    select {
      background-color: ${props => props.theme.colors.primaryHighFaded};
    }
  }
  @media (max-width: 767px) {
    &.half {
      width: auto;
      float: none;
      &:first-of-type {
        margin-right: 0;
        clear: none;
      }
    }
  }
`
  
export default StyledSelect
