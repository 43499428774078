const pools : Pool[] = [
  {
    id: "ME-CO-0",
    name: "Agentur Focus",
    webseries: "Focus",
    slug: "focus",
  },
  {
    id: "MG-CO-0",
    name: "Magnum",
    webseries: "Magnum",
    slug: "magnum",
  },
  {
    id: "ME-CO-14",
    name: "MAPS",
    webseries: "MAPS",
    slug: "maps",
  },
  {
    id: "OK-CO-0",
    name: "Ostkreuz",
    webseries: "Ostkreuz",
    slug: "ostkreuz",
  },
  // {
  //   id: "CP-CO-0",
  //   name: "Contacto",
  //   webseries: "Contacto",
  //   path: "contacto",
  // }
  
]

export default pools