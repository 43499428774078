import { LegacyRef, ReactElement, SyntheticEvent } from 'react'
import StyledInput from './StyledInput'
import FormError from '../FormError'

interface InputProps {
  name?: string
  type?: "text" | "password" | "submit" | "radio" | "checkbox",
  label?: string | ReactElement | null,
  value?: string | number | readonly string[] | undefined,
  password?: boolean,
  className?: string,
  error?: string | null,
  style?: {[somekey : string] : string },
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  inputRef?: LegacyRef<HTMLInputElement> | undefined,
  id?: string,
  checked?: boolean,
  disabled?: boolean,
  variant?: "half" | "",
  placeholder?: string,
}

const Input = (props : InputProps) => {

  const inputElement =
    <input
      type={props.type} 
      name={props.name} 
      value={props.value}
      onChange={props.onChange}
      className={props.className}
      style={props.style}
      ref={props.inputRef}
      id={props.id}
      checked={props.checked}
      disabled={props.disabled}
      placeholder={props.placeholder}
    />

  return (
    <StyledInput className={`${props.error ? 'withErrors' : ''} input-${props.type}-wrapper ${props.variant}`}>
      {(props.type === "text" || props.type === "password") && props.label
        ? <label>
            <p>{props.label}</p>
            {inputElement}
          </label>
        : props.type === "checkbox" && props.label
          ? <label>
              {inputElement}
              <span>{props.label}</span>
            </label>
          : <>{inputElement}</>
      }
      {props.type === "radio" && props.label
        ? <label htmlFor={props.id}>
            {props.label}
          </label>
        : props.type === "radio" 
          ? "[Radiobutton needs label]"
          : null
      }

      <br/>
      {props.error &&
        <FormError text={props.error} />
      }
    </StyledInput>
  )
} 

Input.defaultProps = {
  onChange: (_ : SyntheticEvent) => {},
  type: "text",
  variant: "",
  disabled: false,
}

export default Input