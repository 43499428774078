import StyledFormError from './StyledFormError'
import { mdiAlert as WarnIcon } from '@mdi/js'

interface FormErrorProps {
  text?: string
  className?: string,
  style?: {[somekey : string] : string },
}

const FormError = (props : FormErrorProps) => {
  return (
    <StyledFormError style={props.style}>
      <svg viewBox="0 0 24 24" >
        <path d={WarnIcon}></path>
      </svg>
      {props.text}
    </StyledFormError>
  )
}

export default FormError