import styled from '@emotion/styled'
import StyledButton from '../../components/Button/StyledButton'

const StyledLighboxBar = styled('div')`
  position: fixed;
  bottom: 0;
  width: 100vw;
  height: 50px;
  padding: 10px 4% 0 4%;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  z-index: 10;
  ${StyledButton} {
    &.button__light {
      color: ${props => props.theme.colors.white} !important;
      svg {
        path {
          fill: ${props => props.theme.colors.white} !important;
        }
      }
    }
    &.button__grey { 
      background-color: ${props => props.theme.colors.black} !important;
      color: ${props => props.theme.colors.white} !important;
      border: 1px solid ${props => props.theme.colors.blackAuxiliary} !important;
      svg {
        path {
          fill: ${props => props.theme.colors.white} !important;
        }
      }
      &.button__interactive:not(.button__icon-only) {
        &:hover,
        &.selected {
          background-color: ${props => props.theme.colors.blackHover} !important;
        }
      }
    }
  }
  @media (max-width: 767px) {
    display: none;
  }
`


export default StyledLighboxBar