import { useTranslation } from 'react-i18next'
import FilterBar from '../../components/FilterBar'
import FilterBarByFacet from '../../components/FilterBarByFacet'
import TimeSelect from '../../components/TimeSelect'
import { StyledSidebarGroup } from '../../components/SidebarLayout/StyledSidebarLayout'
import { useEffect, useState } from 'react'

type Tuples = Tuple[]
type Tuple = [string, number]

const Sidebar = (props : any) => {

  const { t } = useTranslation()

  const [photographers, setPhotographers] = useState<Tuples>([])
  // const [categories, setCategories] = useState<Tuples>([])

  useEffect(() => {
    const photographersArray = props.state.searchFacets?.photographers ? Object.entries(props.state.searchFacets.photographers) as Tuples : []
    // const categoriesArray = props.state.searchFacets?.categories ? Object.entries(props.state.searchFacets.categories) as Tuples : []
    setPhotographers(props.state.searchFacets?.photographers ? photographersArray.sort((a , b) =>  b[1] - a[1]) : [])
    // setCategories(props.state.searchFacets?.categories ? categoriesArray.sort((a , b) =>  b[1] - a[1]) : [])
    // console.log(photographersArray)
  }, [props.state.searchFacets])

  return (
    <div style={{paddingBottom: "30px"}}>
      <StyledSidebarGroup>
        <p>{t('filter.timeframe')}</p>
        <TimeSelect 
          state={props.state} 
          handleChangeFilters={props.handleChangeFilters}
          dateFromInputRef={props.dateFromInputRef} 
          dateToInputRef={props.dateToInputRef} 
          />
      </StyledSidebarGroup>
      <StyledSidebarGroup>
        <span>{t('filter.properties')}</span>
        <FilterBar 
          title={t('filter.coloration')} 
          facet="color" 
          values={['color', 'black-white', 'monochrome']}
          activeFilters={props.state.activeFilters}
          handleChangeFilters={props.handleChangeFilters}
          handleClearFilters={props.handleClearFilters}/>
        <FilterBar 
          title={t('filter.format')} 
          facet="orientation" 
          values={['h', 'v' ,'q' ,'p' ,'pv']}
          activeFilters={props.state.activeFilters}
          handleChangeFilters={props.handleChangeFilters}
          handleClearFilters={props.handleClearFilters}/>
      </StyledSidebarGroup>
      {/* <StyledSidebarGroup>
        <span>{t('filter.category')}</span>
        <FilterBarByFacet
          facet="categories" 
          facetArray={categories} // sorted tuple array of [supplierID, count]
          facetsByIdObject={props.state.categories} // id lookup
          activeFilters={props.state.activeFilters}
          handleChangeFilters={props.handleChangeFilters}
          handleClearFilters={props.handleClearFilters}/>
      </StyledSidebarGroup> */}
      <StyledSidebarGroup>
        <span>{t('filter.photographer')}</span>
        <FilterBarByFacet
          facet="photographers" 
          facetArray={photographers} // sorted tuple array of [supplierID, count]
          facetsByIdObject={props.state.suppliers} // id lookup
          activeFilters={props.state.activeFilters}
          handleChangeFilters={props.handleChangeFilters}
          handleClearFilters={props.handleClearFilters}/>
      </StyledSidebarGroup>
    </div>
      
  )

}

export default Sidebar