import styled from '@emotion/styled'

const StyledHome = styled('div')`
  min-height: 100vh;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.secondary};
  transition: background-color 0.2s ease-out;
  & > section {
    position: relative;
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    transition: background-color 0.2s ease-out;
    &.faded {
      background-color: ${props => props.theme.colors.secondaryShaded};
    }
    &.loading{
      height: 500px;
      overflow: hidden;
      button {
        display: none;
      }
      .sticky{
        display: none;
      }
    }
  }
  .all-button {
    margin: 0 4%;
    padding: 20px 0;
    text-align: center;
    &.border-top {
      border-top: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    }
    &.main-button { // hack - main buttons
      padding: 60px 0;
    }
    a {
      text-decoration: none !important;
    }
  }
`

export default StyledHome
