import React, { Component, createContext, createRef, Suspense } from 'react'
import { Router, Switch, Route, Redirect } from "react-router-dom"
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { createBrowserHistory } from "history"
import i18n from "i18next"
import { Translation } from 'react-i18next'
import { Theme, ThemeProvider } from '@emotion/react'
import { apiv1 } from '../api'
// import qs from 'qs'
import { themeLight, themeDark } from '../theme'
import '../global-styles'

import Authentication from '../containers/Authentication'
import Header from '../containers/Header'
import Home from '../containers/Home'
import Results from '../containers/Results'
import Detail from '../containers/Detail'
import Calculator from '../containers/Calculator'
import MyAccount from '../containers/MyAccount'
import LightboxBar from '../components/LightboxBar'
import LightboxModal from '../containers/LightboxModal'
import FeedAll from '../containers/FeedAll'
import FeedPool from '../containers/FeedPool'
import StoriesAll from '../containers/StoriesAll'
import StoryDetail from '../containers/StoryDetail'
import Loading from '../components/Loading'
import Lightbox from '../containers/Lightbox'
import industries from '../config/industries'
import Menu from '../components/Menu';
import StoriesPool from '../containers/StoriesPool'
// import Lightbox from '../components/Lightbox'
// import { SpaceWrapper } from '../containers/MyAccount/StyledMyAccount'
// import ScrollToTop from '../components/ScrollToTop'

type Props = { }
type State = {
  loading : boolean,
  loadingHomeFeed : boolean,
  loadingHomeStories : boolean,
  success : boolean,
  fail: boolean,
  isDarkTheme : boolean,
  activeTheme : Theme,
  searchTerm : string | null,
  searchPage: number,
  itemsPerPage: number,
  searchTotal : number,
  searchFacets : Facets,
  suppliers: any,
  categories: any,
  activeFilters : { // request params not facets!
    collection?: string[] | null 
    color?: string | null
    category?: string | null
    // fcategories?: string[] | null
    // keywords?: string[] | null
    license?: string | null
    // manipulation?: string[] | null
    // model_release?: string[] | null
    orientation?: string | null
    supplier_id?: string | string[] | null // query param = photographers != request param
    // property_release?: string[] | null // 
    // resolution?: string[] | null
    // upload_time?: string[] | null // 
    creation_date_from?: string | null
    creation_date_to?: string | null 
  },
  activeSort: string,
  selectedPool : Pool | null,
  showMobilePoolSelector: boolean,
  showMobileSidebar: boolean,
  assets : Asset[],
  currentAsset : any,
  currentPricelists: any,
  currentPricelistId: string | null,
  currentAssets: any,
  currentSeries: any,
  calculatorIsOpen: boolean,
  lightboxModalIsOpen: boolean,
  gridViewSearch: string,
  afterLoginUrl: string,
  afterDetailUrl: string,
  user: any,
  carts: any,
  lightboxes: any,
  lightboxError: string | null,
  orders: any,
  userLoggedIn: boolean,
  registrationSent: boolean,
  userToken: string | null,
  errors: {
    loginError: string | null,
    registrationError: string | null,
    verifyPasswordError: string | null,
    paymentError: string | null,
  },
  homeStories: any,
  homeFeed: {
    magnum: any,
    focus: any,
    maps: any,
  },
  feed: any,
  feedPage: string,
  feeds: {
    magnum: any,
    focus: any,
    maps: any,
  },
  storiesAll: any,
  stories: any,
  storiesPage: string,
}

const history = createBrowserHistory()
const UserContext = createContext({user: {}});


class App extends Component<Props, State> {
  private searchInputRef : React.RefObject<HTMLInputElement>;
  private dateFromInputRef : React.RefObject<HTMLInputElement>;
  private dateToInputRef : React.RefObject<HTMLInputElement>;
  private pageInputRef : React.RefObject<HTMLInputElement>;
  private lightboxInputRef : React.RefObject<HTMLInputElement>;
  constructor(props : any) {
    super(props)
    this.searchInputRef = createRef<HTMLInputElement>();
    this.dateFromInputRef = createRef<HTMLInputElement>();
    this.dateToInputRef = createRef<HTMLInputElement>();
    this.pageInputRef = createRef<HTMLInputElement>();
    this.lightboxInputRef = createRef<HTMLInputElement>();
    this.state = {
      loading : false,
      loadingHomeFeed : false,
      loadingHomeStories : false,
      success : false,
      fail: false,
      isDarkTheme : false,
      activeTheme : themeLight,
      searchTerm : "",
      searchPage: 1,
      itemsPerPage: 40,
      searchTotal: 0,
      searchFacets : {},
      suppliers: null,
      categories: null,
      activeFilters : {
        supplier_id: null,
      },
      activeSort: "-dateCreated",
      selectedPool : null,
      showMobilePoolSelector: false,
      showMobileSidebar: false,
      assets : [],
      currentAsset : null,
      currentAssets: [],
      currentPricelists: null,
      currentPricelistId: null,
      currentSeries: null,
      calculatorIsOpen: false,
      lightboxModalIsOpen: false,
      gridViewSearch: "grid",
      afterLoginUrl: "/",
      afterDetailUrl: "/",
      user: {},
      carts: null,
      lightboxes: null,
      lightboxError: null,
      orders: null,
      userLoggedIn: false,
      registrationSent: false,
      userToken: window.localStorage.getItem("token"),
      errors: {
        loginError: null,
        registrationError: null,
        verifyPasswordError: null,
        paymentError: null,
      },
      homeStories: null,
      homeFeed: {
        magnum: null,
        focus: null,
        maps: null,
      },
      feed: null,
      feedPage: "1",
      feeds: {
        magnum: null,
        focus: null,
        maps: null,
      },
      storiesAll: null,
      stories: null,
      storiesPage: "1",
    }
  }

  componentDidMount() {
    i18n.changeLanguage() // set language from localStorage or browser

    // SEARCH PARAMS
    const url = new URL(window.location.href)
    // console.log(url)
    const searchParams = new URLSearchParams(url.search) // not IE compatible. expand for additional params if needed
    // console.log('s: ' + searchParams.get('s'))
    // console.log('page: ' + searchParams.get('page'))
    // console.log('page: ' + searchParams.get('photographer'))
    let searchTerm = this.state.searchTerm
    let searchPage = this.state.searchPage
    let searchSupplier = this.state.activeFilters.supplier_id 
    if (searchParams.has('s') || searchParams.has('photographer')) {
      searchTerm = searchParams.get('s') || ""
      const pageAsNumber = Math.floor(Number(searchParams.get('page')))
      searchPage = searchParams.has('page') && !isNaN(pageAsNumber) ? pageAsNumber : searchPage
      searchSupplier = searchParams.has('photographer') ? searchParams.get('photographer') : searchSupplier
      history.push({
        pathname: 'search',
        search: searchParams.toString(),
      })
      this.getAssets({
        search: searchTerm,
        page: searchPage,
        filters: {
          supplier_id: searchSupplier,
        }
      })
    } 

    // USER
    let userLoggedIn = this.state.userLoggedIn 
    let userToken = this.state.userToken || window.localStorage.getItem("token")
    if (userToken && !userLoggedIn){
      this.getUser()
    }

    // lookup objects for search filters
    this.getSuppliers()
    this.getCategories()

    this.setState({
      searchTerm: searchTerm,
    });
  }
 
  private getUser = () => {
    console.log('get user')
    this.setState({
      loading: true
    })
    apiv1.get(`user`, {
      headers: { 'authorization': `Bearer ${this.state.userToken}` },
    })
    .then((response) => {
      const data = response.data as any
      // console.log(response)
      console.log('get user items')
      Promise.all([
        apiv1.get(`carts`, { headers: { 'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }}),
        apiv1.get(`lightboxes`, { headers: { 'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}` }}),
        apiv1.get(`orders`, { headers: { 'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}` }}),
      ])
      .then(async([res1, res2, res3]) => {
        const carts = await res1.data;
        const lightboxes = await res2.data;
        const orders = await res3.data;
        // console.log(carts)
        // console.log(lightboxes)
        // console.log(orders)
        // console.log(data)
        this.setState({
          user : data,
          carts: carts,
          lightboxes: lightboxes,
          orders: orders,
          userLoggedIn: true,
          loading: false
        })
      })
      .catch(e => {console.log(e)})
    })
    .catch((error) => {
      if (error.response) {
        console.log(error.response.data.message.message)
      } else if (error.request) {
        console.log(error.request);
      } else {
        console.log('Error', error.message);
      }
      this.setFail()
      // if fails, presume token is not valid anymore, log out user
      window.localStorage.removeItem("token")
      this.setState({
        // loginError: error.response.data.message.message,
        user: {},
        userLoggedIn: false,
        userToken: null,
        loading: false,
      })
    })
    // redirect on log in error?
  }

  handleRefreshUser = () => {
    this.getUser()
  }

  handleSubmitLogin = (username : string | null, password : string | null) => {
    this.setState({
      loading: true
    })
    apiv1.post(`user/login`, {
      username: username,
      password: password
    }, { 
      headers: {'Accept-Language': i18n.language}
    })
    .then((response) => {
      const data = response.data as any
      let token = ""
      if (data.token) {
        // https://stackoverflow.com/questions/37582444/jwt-vs-cookies-for-token-based-authentication
        // https://stackoverflow.com/questions/54258233/do-i-have-to-store-tokens-in-cookies-or-localstorage-or-session
        // https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage 
        // TODO: rethink using localStorage - token will never expire, user has to manually log out! Is this what we want
        // "Cookies, with httpOnly, secure and SameSite=strict flags, are more secure"
        // https://coolgk.medium.com/localstorage-vs-cookie-for-jwt-access-token-war-in-short-943fb23239ca
        token = data.token as string
        window.localStorage.setItem("token", token)
        history.push(this.state.afterLoginUrl)
        this.setState({
          errors: {
            ...this.state.errors,
            loginError: null,
          },
          userToken: token,
          afterLoginUrl: "/",
        })
        this.setSuccess()
        this.getUser()
      } else {console.log('no token')}
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(error.response.data.message.message)
        const loginError = error.response.data.code === 401 ? "errors.bad_credentials" : error.response.data.message.message
        this.setState((state : State) => ({   
          errors: {
            ...state.errors,
            loginError: loginError,
          },
          loading: false
        }))
        this.setFail()
      } else if (error.request) {
        // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
    })
  }

  handleForgotPassword = (email : string) => {
    // /user/request-reset-token -> triggers an email with token link
    apiv1.post(`/user/request-reset-token`, {
      email: email,
    }, { 
      headers: {'Accept-Language': i18n.language}
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
    })
    .catch((e) => {console.warn(e); this.setFail()})
  }

  handleVerifyPassword = (email : string, token : string, password : string  ) => {
    this.setState({
      loading: true
    })
    apiv1.post(`/user/reset-password`, {
      email: decodeURIComponent(email),
      token: token,
      password: password,
    }, { 
      headers: {'Accept-Language': i18n.language}
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      this.setState((state : State) => ({   
        errors: {
          ...state.errors,
          verifyPasswordError: null,
        },
        loading: false
      }))
      this.setSuccess()
    })
    .catch((error) => {
      if (error.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(error.response.data)
        const verifyPasswordError = error.response.data.message
        this.setState((state : State) => ({   
          errors: {
            ...state.errors,
            verifyPasswordError: verifyPasswordError,
          },
          loading: false
        }))
        this.setFail()
      } else if (error.request) {
        // The request was made but no response was received. `error.request` is an instance of XMLHttpRequest in the browser and an instance of http.ClientRequest in node.js
        console.log(error.request)
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message)
      }
    })
  }

  handleLogOut = () => {
    window.localStorage.removeItem("token")
    this.setState({
      user: {},
      userLoggedIn: false,
      userToken: null,
    })
  }
  
  handleCreateUser = (p : any) => { 
    this.setState((state : State) => ({   
      loading: true,
      errors: {
        ...state.errors,
        registrationError: null,
      }
    }))

    let data = {
      gender: p.salutation,
      givenName: p.firstName,
      familyName: p.lastName,
      email: p.email,
      password: p.password,
      repeatPassword: p.repeatPassword,
      language: p.language,
      company: p.company,
      // industry: {
      //   id: parseInt(p.industry),
      //   name: industries.find((industry:Industry) => {return(industry.id === p.industry)})?.name.de,
      // },
      url: p.companyWebsite,
      taxNumber: p.companyVatId,
      customField2: `${p.hiresDownload ? "Hi-Res Download angefragt!" : ""} ${p.newsletterSubscribed ? "Newsletter abonniert!" : ""} `,
      address: [{
        contactType: "main",
        givenName: p.firstName,
        familyName: p.lastName,
        telephone: p.phone,
        company: p.company,
        // email: p.companyEmail,
        streetAddress: p.street,
        postalCode: p.zip,
        addressLocality: p.city,
        addressCountry: p.country,
      },
      {
        contactType: "billing",
        // givenName: p.firstName,
        // familyName: p.lastName,
        // telephone: p.phone,
        // company: p.company,
        email: p.companyEmail,
        // streetAddress: p.street,
        // postalCode: p.zip,
        // addressLocality: p.city,
        // addressCountry: p.country,
      }]
    }

    apiv1.post(`user`, data, {
      headers: {'Accept-Language': i18n.language},
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      this.setState((state : State) => ({   
        loading: false,
        registrationSent: true,
        errors: {
          ...state.errors,
          registrationError: null,
        }
      }))
      this.setSuccess()
    })
    .catch((error) => {
      console.warn(error); 
      this.setState((state : State) => ({   
        loading: false,
        errors: {
          ...state.errors,
          registrationError: error.response.data.message,
        }
      }))
      this.setFail()
    })
  }

  handleUpdateUser = (p : any) => {
    this.setState({
      loading: true
    })

    // let data = this.state.user
    // // this could be so much nicer if params weren't nested, maybe refactor with key split
    // if (p.hasOwnProperty("salutation")) {data.gender = p.salutation}
    // if (p.hasOwnProperty("firstName")) {data.givenName = p.firstName}
    // if (p.hasOwnProperty("email")) {data.email = p.email}
    // if (p.hasOwnProperty("language")) {data.language = p.language}
    // if (p.hasOwnProperty("company")) {data.company = p.company}
    // if (p.hasOwnProperty("companyWebsite")) {data.url = p.companyWebsite}
    // if (p.hasOwnProperty("companyVatId")) {data.taxNumber = p.companyVatId}
    // if (p.hasOwnProperty("companyVatId")) {data.taxNumber = p.companyVatId}
    
    let data = {
      gender: p.salutation,
      givenName: p.firstName,
      familyName: p.lastName,
      email: p.email,
      language: p.language,
      company: p.company,
      industry: {
        id: parseInt(p.industry),
        name: industries.find((industry:Industry) => {return(industry.id === p.industry)})?.name.de,
      },
      url: p.companyWebsite,
      taxNumber: p.companyVatId,
      address: [{
        contactType: "main",
        givenName: p.firstName,
        familyName: p.lastName,
        telephone: p.phone,
        company: p.company,
        // email: p.companyEmail,
        streetAddress: p.street,
        postalCode: p.zip,
        addressLocality: p.city,
        addressCountry: p.country,
      },
      {
        contactType: "billing",
        givenName: p.firstName,
        familyName: p.lastName,
        telephone: p.phone,
        company: p.company,
        email: p.companyEmail,
        streetAddress: p.street,
        postalCode: p.zip,
        addressLocality: p.city,
        addressCountry: p.country,
      }]
    }

    apiv1.put(`user`, data, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}` },
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      // this.setState({
      //   user: data,
      //   loading: false
      // })
      this.getUser()
      this.setSuccess()
      // history.push("/my-account")
    })
    .catch((error) => {
      console.warn(error); 
      this.setFail()
    })
  }

  handleChangePassword = (password : string) => {
    this.setState({
      loading: true
    })

    let data = {
      ...this.state.user,
      password: password,
    }

    apiv1.put(`user`, data, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}` },
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      this.setState({
        user: data,
        loading: false
      })
      this.setSuccess()
    })
    .catch((error) => {
      console.warn(error); 
      this.setFail()
    })
  }

  getHomeContent = (pool : Pool | null | undefined) => {
    console.log("get home content")
    this.setState({
      loadingHomeFeed : true,
      loadingHomeStories : true,
      homeStories: null,
      homeFeed: {
        magnum: null,
        focus: null,
        maps: null,
      },
    })

    Promise.all([
      apiv1.get(`webseries`, { // Story/ies
        params: { 
          number: pool?.webseries || "STORIES",
          itemsPerPage: pool?.webseries ? 16 : 1, 
          page : 1, 
        }, 
        headers: {'Accept-Language': i18n.language }
      }),
      apiv1.get(`assets`, { // Magnum
        params: { itemsPerPage: 16, page : 1, sort: "-dateCreated", collection: "MG-CO-0" }, headers: {'Accept-Language': i18n.language }
      }),
      apiv1.get(`assets`, { // Maps
        params: { itemsPerPage: 16, page : 1, sort: "-dateCreated", collection: "ME-CO-14" }, headers: {'Accept-Language': i18n.language }
      }),
      apiv1.get(`assets`, { // Focus
        params: { itemsPerPage: 16, page : 1, sort: "-dateCreated", collection: "ME-CO-0" }, headers: {'Accept-Language': i18n.language }
        // Ausnahme von MAPS per Liste nicht möglich, da Focus selbst keine "collection" 
        // cf: https://sodatech.atlassian.net/servicedesk/customer/portal/6/DESK-4683
        // params: { itemsPerPage: 16, page : 1, sort: "-dateCreated", collection: "ME-CO-2,ME-CO-4,ME-CO-6,ME-CO-7,ME-CO-8,ME-CO-9,ME-CO-10,ME-CO-11,ME-CO-12" }, headers: {'Accept-Language': i18n.language }
      }),
    ])
    .then(async([res1, res2, res3, res4]) => {
      const parentStory = await res1.data as Webseries
      const newsMagnum = await res2.data as any
      const newsMaps = await res3.data as any
      const newsFocus = await res4.data as any

      // console.log(parentStory)
      // console.log(newsMagnum)
      // console.log(newsMaps)
      // console.log(newsFocus)

      let stories = null

      if (pool?.webseries) { // get pool
        stories = parentStory
      } else {
        apiv1.get(`webseries`, {
          params: { parent_id: parentStory.id, itemsPerPage: 6, page : 1,}, headers: {'Accept-Language': i18n.language }
        })
        .then((response) => {
          stories = response.data as Webseries
          this.setState({
            homeStories: stories,
            loadingHomeStories : false,
          })
          this.setSuccess()
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            loadingHomeStories : false,
          })
          this.setFail()
        })
      }

      this.setState({
        homeStories: stories,
        homeFeed: {
          magnum: newsMagnum.data,
          focus: newsFocus.data,
          maps: newsMaps.data,
        },
        loadingHomeFeed : false,
        loadingHomeStories : stories ? false : true,
      })
      this.setSuccess()
    }) 
    .catch((error) => {
      console.log(error)
      this.setState({
        loadingHomeFeed: false,
      })
      this.setFail()
    })
  }

  getFeed = (pool : Pool | null | undefined, page? : string) => {
    console.log("get feed")
    if (pool) { 
      console.log("get feed: " + pool.slug + " , page: " + page)
      this.setState({
        loading: true,
        feed: null,
      })
      const pageNumber = page || "1"
      history.push(`/feed/${pool.slug}/${pageNumber}`)
      apiv1.get(`assets`, {
        params: { 
          itemsPerPage: 16,
          page : pageNumber,
          sort: "-dateCreated",
          collection: pool?.id || "", 
        },
        headers: {'Accept-Language': i18n.language }
      })
      .then((response) => {
        const data = response.data as any
  
        this.setState({
          feed: data,
          loading : false,
          feedPage: pageNumber,
        })
        
        this.setSuccess()
      }) 
      .catch((error) => {
        console.log(error)
        this.setState({
          loading: false,
        })
        this.setFail()
      })
    } else if (!pool) { // "all" feed
      this.setState({
        loading: true,
        feeds: {
          magnum: null,
          focus: null,
          maps: null,
        },
      })
      history.push(`/feeds`)
      Promise.all([
        apiv1.get(`assets`, { // Magnum
          params: { itemsPerPage: 12, page : 1, sort: "-dateCreated", collection: "MG-CO-0" }, headers: {'Accept-Language': i18n.language }
        }),
        apiv1.get(`assets`, { // Maps
          params: { itemsPerPage: 12, page : 1, sort: "-dateCreated", collection: "ME-CO-14" }, headers: {'Accept-Language': i18n.language }
        }),
        apiv1.get(`assets`, { // Focus
          params: { itemsPerPage: 12, page : 1, sort: "-dateCreated", collection: "ME-CO-0" }, headers: {'Accept-Language': i18n.language }
        }),
      ])
      .then(async([res1, res2, res3]) => {
        const newsMagnum = await res1.data as any
        const newsMaps = await res2.data as any
        const newsFocus = await res3.data as any

        this.setState({
          feeds: {
            magnum: newsMagnum.data,
            focus: newsFocus.data,
            maps: newsMaps.data,
          },
          loading : false,
        })
        this.setSuccess()
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          loading: false,
        })
        this.setFail()
      })
    }
  }

  getStories = (pool : Pool | null | undefined, page? : string) => {
    console.log("get stories")
    const pageNumber = page || "1"
    if (pool ) { 
      console.log("get stories: " + pool?.slug + " , page: " + page)
      this.setState({
        loading: true,
        stories: null,
      })
      history.push(`/stories/${pool.slug}/${pageNumber}`)
      apiv1.get(`webseries`, {
        params: { 
          number: pool?.webseries || "STORIES",
          itemsPerPage: 30, // undocumented param
          page : pageNumber, // undocumented param
        },
        headers: {'Accept-Language': i18n.language }
      })
      .then((response) => {
        const data = response.data as Webseries
        // console.log(data)
        this.setState({
          loading: false,
          stories: data,
        })
      })
      .catch((error) => {
        console.log(error)
        this.setState({
          loading: false,
        })
        this.setFail()
      })
    } else { // get all STORIES
      console.log("get all stories")
      this.setState({
        loading: true,
        storiesAll: null,
      })
      history.push(`/stories`)
      apiv1.get(`webseries`, {
        params: { 
          number: "STORIES",
          itemsPerPage: 30, // undocumented param
          page : pageNumber, // undocumented param
        },
        headers: {'Accept-Language': i18n.language }
      })
      .then((response) => {
        const data = response.data as Webseries
        apiv1.get(`webseries`, {
          params: { parent_id: data.id},
          headers: {'Accept-Language': i18n.language }
        })
        .then((response) => {
          const data = response.data as Webseries
          // console.log(data)
          this.setState({
            loading: false,
            storiesAll: data,
          })
        })
        .catch((error) => {
          console.log(error)
          this.setState({
            loading: false,
          })
          this.setFail()
        })
      })
    }
  } 

  private getSuppliers = () => {
    apiv1.get(`suppliers`, {
      headers: {'Accept-Language': i18n.language},
    })
    .then((response) => {
      const data = response.data as any
      this.setState({   
        suppliers : data, 
      })
    })
    .catch((e) => { console.log(e) })
  }

  private getCategories = () => {
    apiv1.get(`categories`, {
      headers: {'Accept-Language': i18n.language},
    })
    .then((response) => {
      const data = response.data as any
      this.setState({   
        categories : data, 
      })
    })
    .catch((e) => { console.log(e) })
  }

  private getAssets = (params : any) => { // aka Search
    console.log(params)

    // determine if updates
    const isSearchUpdate = params.hasOwnProperty('search')
    const isPagingUpdate = params.hasOwnProperty('page')
    const isFiltersUpdate = params.hasOwnProperty('filters')
    const isSortUpdate = params.hasOwnProperty('sort')
    const isCollectionUpdate = params.hasOwnProperty('collection')

    const term = isSearchUpdate ? params.search : this.state.searchTerm
    const page = isPagingUpdate ? params.page : isSearchUpdate || isFiltersUpdate ? 1 : this.state.searchPage
    const filters = isFiltersUpdate ? {...this.state.activeFilters, ...params.filters} : this.state.activeFilters
    const sort = isSortUpdate ? params.sort : this.state.activeSort
    const collection = isCollectionUpdate ? params.collection : this.state.selectedPool

    this.setState({
      loading: true,
      assets : [],
      currentAsset: null,
      searchTerm: term,
      searchPage: page,
      activeFilters: filters,
      activeSort: sort,
      selectedPool: collection,
    })

    let searchParams = new URLSearchParams(); // not IE compatible. expand for additional params if needed
    searchParams.set('s', term)
    searchParams.set('page', page)
    if (filters.supplier_id) {
      searchParams.set('photographer', filters.supplier_id)
    }
    
    history.push({
      pathname: '/search',
      search: searchParams.toString()
    })
    
    const fetchParams = {
      itemsPerPage: this.state.itemsPerPage,
      search : term,
      page : page,
      sort : sort,
      collection: collection ? collection.id : "",
      ...filters,
    }
    // console.log(fetchParams)
    apiv1.get(`assets`, {
      params: fetchParams,
      headers: {'Accept-Language': i18n.language},
    })
    .then((response) => {
      const data = response.data as any
      // console.log(data)
      this.setState((state : State) => ({   
        searchTotal: data.total,
        // searchFacets: !isSearchUpdate && !isCollectionUpdate ? state.searchFacets : data.facets, // keep previous facets if update
        searchFacets: data.facets,
        assets: data.data,
        loading: false,
      }))
    })
    .catch((error) => {
      console.warn(error);
    })
  }

  getAssetById = (id : string) => {
    this.setState({loading: true})
    Promise.all([
      apiv1.get(`assets/${id}`, {  headers: {'Accept-Language': i18n.language }, }),
      apiv1.get(`assets/${id}/calculator`, { headers: {'Accept-Language': i18n.language }, })
    ])
    .then(async([res1, res2]) => {
      const assetData = res1.data as any
      // console.log(assetData)
      const calculatorData = res2.data as any
      // console.log(calculatorData)
      
      this.setState({
        currentAsset : assetData,
        currentPricelists: calculatorData.pricelists,
        loading: false
      });
    })
    .catch((error) => { console.warn(error); this.setFail() })
  }

  handleSubmitSearch = (searchTerm: string) => {
    this.getAssets({
      search: searchTerm,
    }) 
  }

  handleSelectPool = (p: Pool | null, page? : string) => {
    console.log("select pool called")
    // console.log(p)
    this.handleSetShowMobilePoolSelector(false)
    if (window.location.pathname === "/" || window.location.pathname.includes("/start")) { // called on initial load or slug change
      this.setState({
        selectedPool: p,
        currentAsset: null,
      })
      this.getHomeContent(p)
      if (p) {
        history.replace(`/start/${p.slug}`)
      } else {
        history.replace(`/`)
      }
    } else if (window.location.pathname.includes("/feed")) { // called on initial load or slug change, also matches 'feeds'!!!
      console.log('feed')
      const pageNumber = page || "1"
      this.setState({
        selectedPool: p,
        currentAsset: null
      })
      if (p && p.slug === "ostkreuz") {
        history.push(`/stories`)
      } else {
        this.getFeed(p, pageNumber) 
      }
    } else if (window.location.pathname.includes("/stories") || window.location.pathname.includes("/story")) {
      console.log(p)
      const pageNumber = page || "1"
      this.setState({
        selectedPool: p,
        currentAsset: null
      })
      this.getStories(p, pageNumber) 
    } else { // Search
      this.getAssets({
        collection: p,
      }) 
    }
  }

  handleSetShowMobilePoolSelector = (value : boolean) => {
    // console.log('handleSetShowMobilePoolSelector')
    this.setState({
      showMobilePoolSelector: value,
    })
  }

  handleSetShowMobileSidebar = (value : boolean) => {
    this.setState({
      showMobileSidebar: value,
    })
  }

  handleChangeFilters = (filterParams : any) => {
    const filters = {
      ...this.state.activeFilters, 
      ...filterParams, 
    }
    this.getAssets({
      filters: filters,
    })
  }

  handleClearFilters = (keys : Array<keyof typeof this.state.activeFilters>) => {
    let filters = {...this.state.activeFilters}
    keys.forEach(key => filters[key] = null)
    
    if (this.dateFromInputRef.current) {
      console.log(this.dateFromInputRef.current.value)
      this.dateFromInputRef.current.value = ""
    }
    if (this.dateToInputRef.current) {
      console.log(this.dateToInputRef.current.value)
      this.dateToInputRef.current.value = ""
    }
    this.getAssets({
      filters: filters
    })
  }

  handleClearSearchTerm = () => {
    this.setState({
      searchTerm: "",
      currentAsset: null,
    })
    window.setTimeout(() => { window.scrollTo(0,0)}, 1) // ?
  } 

  handleHomeLink = () => {
    console.log("home link")
    this.handleClearSearchTerm()
    this.handleClearFilters(Object.keys(this.state.activeFilters) as Array<keyof typeof this.state.activeFilters>)
    if (this.state.selectedPool) {
      this.setState({
        selectedPool: null
      })
      this.getHomeContent(null)
    }
  }

  handleSort = (sorting : string) => {
    this.getAssets({
      sort: sorting
    })
  } 

  handleChangeGridView = (view : string) => {
    this.setState({
      gridViewSearch: view,
      currentAsset: null,
    })
  } 

  handleSetPage = (action : "set" | "increment" , value : number) => {
    console.log(action, value)
    let newPage
    if (action === "set") {
      newPage = value
      this.pageInputRef.current?.blur()
    } else if (action === "increment") {
      newPage = this.state.searchPage + value
    }
    this.getAssets({
      page: newPage
    })
  }

  toggleTheme = () => {
    // TODO: make persitent
    this.setState((state : State) => ({
      isDarkTheme: !state.isDarkTheme,
      activeTheme: state.isDarkTheme ? themeLight : themeDark
    }))
  }

  handleOpenCalculator = (pricelistId : string | null) => { // id? : string | null (of asset)
    // const assetId = id || this.state.currentAsset // -> has to load asset if called from somewhere else than detail! But is not atm.
    if (!this.state.userLoggedIn) {
      this.setState({
        afterLoginUrl: window.location.pathname
      })
      history.push('/login')
    } else {
      this.setState({
        currentPricelistId: pricelistId,
        calculatorIsOpen: true,
      })
    }
  }

  handleCloseCalculator = () => {
    this.setState({
      calculatorIsOpen: false,
    })
  }

  addToCart = (selection : any, pricelist: any, ) => {
    if (!this.state.userLoggedIn) {
      this.setState({
        afterLoginUrl: window.location.pathname
      })
      history.push('/login')

    } else { // loged in
      this.setState({ loading: true, })

      if (this.state.carts && this.state.carts.length) { // add article as cart exists
        console.log ('existing cart: ' + this.state.carts[0].id)
        
        apiv1.post(`carts/${this.state.carts[0].id}/articles`, {
          assetId: this.state.currentAsset.id,
          calculatorArguments: {
            pricelistId: pricelist.id,
            ...selection
          }
        }, { 
          headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
        })
        .then((response) => {
          const data = response.data as any
          console.log(data)
          this.getCurrentCart()
          this.setSuccess()
        })
        .catch((e) => {console.warn(e); this.setFail()})

      } else { // create cart, then add article
        console.log ('new cart')
        apiv1.post(`carts`, {}, { 
          headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
        })
        .then((response) => {
          const data = response.data as any
          // console.log(data)
  
          apiv1.post(`/carts/${data.id}/articles`, {
            assetId: this.state.currentAsset.id,
            calculatorArguments: {
              pricelistId: pricelist.id,
              ...selection
            }
          }, { 
            headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
          })
          .then((_response) => {
            // const data = response.data as any
            // console.log(data)
            this.getCurrentCart()
            this.setSuccess()
          })
          .catch((e) => {console.warn(e); this.setFail()})
        })
        .catch((e) => {console.warn(e); this.setFail()})
      }
    }    
  }

  getCurrentCart = () => {
    this.setState({
      loading: true,
    })
    apiv1.get(`carts`, { 
      headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
    })
    .then((response) => {
      const data = response.data as any
      // console.log(data)
      this.setState({   
        carts : data, 
        calculatorIsOpen: false,
        loading: false
      })
    })
    .catch((e) => {console.warn(e)})
  }

  handleRemoveFromCart = (articleID : string) => {
    this.setState({
      loading: true,
    })
    apiv1.delete(`carts/${this.state.carts[0].id}/articles/${articleID}`, { 
      headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
    })
    .then((_response) => {
      apiv1.get(`lightboxes`,{ 
        headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
      })
      .then((_) => {
        // const lbdata = response.data as any
        this.getCurrentCart()
        this.setSuccess()
      })
    })
  }

  handleSubmitOrder = () => {
    this.setState({
      loading: true
    })
    apiv1.post(`carts/${this.state.carts[0].id}/checkout`, {
    }, { 
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      this.getUser() 
      this.setSuccess()
      history.push('/my-account/orders')
    })
    .catch((e) => {console.warn(e); this.setFail()})
  } 

  handleConfirmOffer = (id : string) => {
    this.setState({
      loading: true
    })
    apiv1.get(`/orders/${id}/payment/options`, { 
    // apiv1.get(`/orders/${id}/payment/form/invoice`, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      if (data.find((t:any) => {return (t.type && t.type ==="invoice")})) {
        console.log("Getting invoice")
        apiv1.get(`/orders/${id}/payment/form/invoice`, { 
          headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
        })
        .then((response) => {
          const data = response.data as any
          
          console.log("Invoice rendered")

          const url = data.actionUrl.split('//')[1]
          const params = url.split('/')
          const token = params[5]
          const paymentmethod = params[6]
          const status = params[7]
          const checksum = params[8]

          let options = {}

          data.options.map((o:any) => {Object.assign(options, {[o.name]: o.value }); return null;})
          console.log(options)

          apiv1.get(`/orders/${id}/payment/notify/${token}/${paymentmethod}/${status}/${checksum}`, { // API answers with a redirect
            params: options,
            headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`  },
          })
          .then((response) => {
            const data = response.data as any
            console.log("Payment")
            console.log(data)

            this.setState((state : State) => ({   
              loading: false,
              errors: {
                ...state.errors, 
                paymentError: null,
              }
            }))
            this.setSuccess()
            this.getUser()
            history.push(`/my-account/orders/${data.id}`)
          })
          .catch((error) => {
            console.warn(error)
            this.setState((state : State) => ({   
              loading: false,
              errors: {
                ...state.errors, 
                paymentError: `errors.payment.${error.response.data.status}`
              }
            }))
            this.setFail()
          })
        })
        .catch((e) => {
          console.warn(e)
          this.setState((state : State) => ({   
            loading: false,
            errors: {
              ...state.errors, 
              paymentError: `${e.message}`
            }
          }))
          this.setFail()
        })

      } else {
        console.log("Kauf auf Rechnung nicht möglich")
        throw new Error("invoice_payment_not_enabled")
      } 
      // this.getUser() 
      // this.setSuccess()
      // history.push('/my-account/orders')
      
    })
    .catch((e) => {
      console.warn(e)
      this.setState((state : State) => ({   
        loading: false,
        errors: {
          ...state.errors, 
          paymentError: `errors.${e.message}`
        }
      }))
      this.setFail()
    })
  } 

  handleDownloadHires = (id : string) => {
    this.setState({
      loading: true
    })
    apiv1.get(`/assets/${id}/files/hires`, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      // // this opens image in window, we want download
      // window.open("https://api.agenturfocus.sodatech.com" + data.downloadUrl)

      // // can't get this to work, no time
      // apiv1.get(`${data.downloadUrl}`, {
      //   headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`, responseType: 'blob'}
      // })
      // .then((res) => {
      //   const data = res.data as any
      //   console.log(typeof (response.data));
      //   console.log(data)

      //   const urlCreator = window.URL || window.webkitURL
      //   const imageUrl = urlCreator.createObjectURL(res )
      //   const tag = document.createElement('a')
      //   tag.href = imageUrl
      //   tag.download = id
      //   document.body.appendChild(tag)
      //   tag.click()
      //   document.body.removeChild(tag)
      //   this.setState({
      //     loading: false
      //   })
      // })
      // .catch((error) => {
      //   console.warn(error);
      //   this.setState({
      //     loading: false
      //   })
      //   this.setFail()
      // })

      // more or less cargo culted from from: https://stackoverflow.com/a/49886131/2523254, should be done with axios (above)
      const xhr = new XMLHttpRequest();
      xhr.open("GET", "https://api.agenturfocus.sodatech.com" + data.downloadUrl, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        console.log( window.URL || window.webkitURL)
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(xhr.response);
        console.log(imageUrl)
        const tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = id;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
        this.setState({
          loading: false
        })
      }
      xhr.send();
    })
    .catch((error) => {
      console.warn(error);
      this.setState({
        loading: false
      })
      this.setFail()
    })
  }

  handleDownloadHiresFromOrder = (orderId : string, articleId : string | null | undefined) => {
    this.setState({
      loading: true
    })
    apiv1.get(`/orders/${orderId}/articles/${articleId}/download`, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      window.open("https://api.agenturfocus.sodatech.com" + data.downloadUrl)
      this.setState({
        loading: false
      })
    })
    .catch((error) => {
      console.warn(error);
      this.setState({
        loading: false
      })
      this.setFail()
    })
  }

  handleDownloadInvoicePdf = (id : string) => {
    apiv1.get(`orders/${id}/pdf`, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
    })
    .then((response) => {
      const data = response.data as any
      // console.log(data)
      window.open("https://api.agenturfocus.sodatech.com" + data.downloadUrl)
    })
    .catch((error) => {
      console.warn(error);
    })
  }

  handleDownloadOrderZip = (id : string) => {
    apiv1.get(`orders/${id}/zip`, {
      headers: {'Accept-Language': i18n.language, 'authorization': `Bearer ${this.state.userToken}`}
    })
    .then((response) => {
      const data = response.data as any
      // console.log(data)
      window.open("https://api.agenturfocus.sodatech.com" + data.downloadUrl)
    })
    .catch((error) => {
      console.warn(error);
    })
  }

  handleOpenLightboxModal = (_e : any, id? : string | null) => {
    if (!this.state.userLoggedIn) {
      this.setState({
        afterLoginUrl: window.location.pathname
      })
      history.push('/login')
    } else  {
      console.log(id)
      if (id) {
        console.log('load asset')
        this.setState({
          currentAsset: null,
          lightboxModalIsOpen: true,
        })
        this.getAssetById(id)
      } else {
        this.setState({
          lightboxModalIsOpen: true,
        })
      }
    }
  }

  handleCloseLightboxModal = () => {
    this.setState({
      lightboxModalIsOpen: false,
      lightboxError: null,
    })
  }

  handleCreateLightbox = (name : string) => {
    console.log('create')
    this.lightboxInputRef.current?.blur()
    this.setState({
      loading: true,
    })
    apiv1.post(`lightboxes`, {
      name: name
    },{ 
      headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      const newLightboxes = [data, ...this.state.lightboxes ]
      this.setState({
        loading: false,
        lightboxError: null,
        lightboxes: newLightboxes,
      })
    })
  }

  handleAddToLightbox = (lightboxId : string) => {
    console.log('add ' + lightboxId)
    
    apiv1.post(`lightboxes/${lightboxId}/assets`, {
      id: this.state.currentAsset.id
    },{ 
      headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
    })
    .then((response) => {
      const data = response.data as any
      console.log(data)
      apiv1.get(`lightboxes`,{ 
        headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
      })
      .then((response) => {
        const lbdata = response.data as any
        // console.log(lbdata)
        this.setState({
          lightboxError: null,
          lightboxes: lbdata,
          lightboxModalIsOpen: false,
        })
        this.setSuccess()
      }) 
    })
    .catch((error) => {
      console.log(error.response.data);
      this.setState({
        lightboxError: error.response.data.message
      })
      this.setFail()
    })
  }

  handleDeleteLightbox = (lightboxId : string ) => {
    this.setState({
      loading: true,
    })
    apiv1.delete(`lightboxes/${lightboxId}`, { 
      headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
    })
    .then((_response) => {
      apiv1.get(`lightboxes`,{ 
        headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
      })
      .then((response) => {
        const lbdata = response.data as any
        console.log(lbdata)
        history.push("/my-account/lightboxes")
        this.setState({
          loading: false,
          lightboxes: lbdata,
          lightboxModalIsOpen: false,
        })
        this.setSuccess()
      })
    })
  }

  handleRemoveFromLightbox = (seriesId : string, assetId : string) => {
    this.setState({
      loading: true,
    })
    apiv1.delete(`lightboxes/${seriesId}/assets/${assetId}`, { 
      headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
    })
    .then((_response) => {
      apiv1.get(`lightboxes`,{ 
        headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
      })
      .then((response) => {
        const lbdata = response.data as any
        // console.log(lbdata)
        this.setState({
          loading: false,
          lightboxes: lbdata,
          lightboxModalIsOpen: false,
        })
        this.setSuccess()
      })
    })
  }

  handleAddAsLightbox = (series : any) => {
    if (!this.state.userLoggedIn) {
      this.setState({
        afterLoginUrl: window.location.pathname
      })
      history.push('/login')
    } else  {
      this.setState({
        loading: true,
      })
      apiv1.post(`lightboxes`, {
        name: series.title || series.name
      },{ 
        headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
      })
      .then((response) => {
        const data = response.data as any
        console.log(data)
        // let lightbox = {...data, assets: series.assets}
        apiv1.put(`lightboxes/${data.id}`, {
          name: series.title || series.name,
          assets: series.assets
        },{ 
          headers: {'Accept-Language': i18n.language,  'authorization': `Bearer ${this.state.userToken}` }
        })
        .then((response) => {
          const data = response.data as any
          console.log(data)
          const newLightboxes = [data, ...this.state.lightboxes ]
          this.setState({
            loading: false,
            lightboxError: null,
            lightboxes: newLightboxes,
          })
          history.push(`/my-account/lightboxes/${data.id}`)
          this.setSuccess()
        })
        .catch((error) => {
          console.warn(error);
          this.setState({
            loading: false,
            // lightboxError: null,
          })
          this.setFail()
        })
      })
      .catch((error) => {
        console.warn(error);
        this.setState({
          loading: false,
          // lightboxError: null,
        })
        this.setFail()
      })
    }
  }

  handlePrepareDetail = (assets : any, currentSeries : any) => {
    this.setState({
      afterDetailUrl: `${window.location.pathname}${window.location.search}`,
      currentAssets: assets,
      currentSeries: currentSeries,
    })
  }

  setSuccess = () =>{
    this.setState({
      success: true,
    })
    window.setTimeout(() => {
      this.setState({
        success: false,
      })
    }, 1500)
  }

  setFail = () =>{
    this.setState({
      fail: true,
    })
    window.setTimeout(() => {
      this.setState({
        fail: false,
      })
    }, 1500)
  }

  render() {    
    return (
      <HelmetProvider>
        <Suspense fallback="loading">
          <Router history={history}>
            <ThemeProvider theme={this.state.activeTheme}>
              <UserContext.Provider value={this.state.user}>
                <div onClick={() => this.handleSetShowMobilePoolSelector(false)}>
                  <Translation>
                    {(t) => <Helmet htmlAttributes={{ lang : i18n.language, dir: i18n.dir() }}>
                      <title>{`AgFoPro — ${t('menu.start')}`}</title>
                      <meta name="description" content={t('header.meta_description')} />
                      <meta property="og:description" content={t('header.meta_description')} />
                      <meta name="twitter:description" content={t('header.meta_description')}></meta>
                    </Helmet> }
                  </Translation>

                  <Switch>
                    <Route exact path={["/login", "/register", "/reset-password", "/reset-password/:email/:token"]}>
                      <Translation>
                        {(t) => <Helmet><title>{`AgFoPro — ${t('login.sign_in')}`}</title></Helmet> }
                      </Translation>
                      {this.state.userLoggedIn 
                        ? <Redirect to="/my-account" />
                        : <Authentication 
                            state={this.state}
                            handleSubmitLogin={this.handleSubmitLogin}
                            handleCreateUser={this.handleCreateUser}
                            // handleUpdateUser={this.handleUpdateUser} // not needed here
                            handleForgotPassword={this.handleForgotPassword}
                            handleVerifyPassword={this.handleVerifyPassword}
                          />
                      }
                    </Route>

                    <Route>
                      <Header 
                        state={this.state}
                        handleLogOut={this.handleLogOut}
                        handleSubmitSearch={this.handleSubmitSearch}
                        handleClearSearchTerm={this.handleClearSearchTerm}
                        handleSelectPool={this.handleSelectPool}
                        handleHomeLink={this.handleHomeLink}
                        toggleTheme={this.toggleTheme}
                        handleSetShowMobilePoolSelector={this.handleSetShowMobilePoolSelector}
                        searchInputRef={this.searchInputRef} 
                        />
                      <Switch>

                        <Route exact path={["/", "/start/:pool"]} children={ ({ match }) => (
                          <>
                            <Helmet>
                              {match && this.state.selectedPool &&
                                <title>{`AgFoPro — ${this.state.selectedPool.name}`}</title>
                              }
                            </Helmet>
                            <Home 
                              state={this.state}
                              match={match}
                              handleOpenLightboxModal={this.handleOpenLightboxModal}
                              handleDownloadHires={this.handleDownloadHires}
                              handlePrepareDetail={this.handlePrepareDetail}
                              getHomeContent={this.getHomeContent}
                              handleSelectPool={this.handleSelectPool}
                            />
                          </>
                        )} />

                        <Route exact path={"/feeds"}>
                          <Translation>
                            {(t) => <Helmet><title>{`AgFoPro — ${t('menu.new_photos')}`}</title></Helmet> }
                          </Translation>
                          <FeedAll
                            state={this.state}
                            handleOpenLightboxModal={this.handleOpenLightboxModal}
                            handleDownloadHires={this.handleDownloadHires}
                            handlePrepareDetail={this.handlePrepareDetail}
                            getFeed={this.getFeed}
                            handleSelectPool={this.handleSelectPool}
                            />
                        </Route>

                        <Route exact path={"/feed/:pool/:pageNumber?"}  children={ ({ match }) => (
                          <>
                            <Translation>
                              {(t) => <Helmet><title>{`AgFoPro ${this.state.selectedPool ? '— ' + this.state.selectedPool.name : ''} — ${t('menu.new_photos')} `}</title></Helmet> }
                            </Translation>
                            <FeedPool
                              state={this.state}
                              match={match}
                              handleOpenLightboxModal={this.handleOpenLightboxModal}
                              handleDownloadHires={this.handleDownloadHires}
                              handlePrepareDetail={this.handlePrepareDetail}
                              getFeed={this.getFeed}
                              handleSelectPool={this.handleSelectPool}
                              />
                          </>
                        )} />

                        <Route exact path={"/stories"}> {/* /:pageNumber? */}
                          <Translation>
                            {(t) => <Helmet><title>{`AgFoPro ${this.state.selectedPool ? '— ' + this.state.selectedPool.name : ''} — ${t('menu.new_stories')}`}</title></Helmet> }
                          </Translation>
                          <StoriesAll 
                            state={this.state}
                            handleOpenLightboxModal={this.handleOpenLightboxModal}
                            handlePrepareDetail={this.handlePrepareDetail}
                            handleDownloadHires={this.handleDownloadHires}
                            getStories={this.getStories}
                            handleSelectPool={this.handleSelectPool}
                            />
                        </Route>

                        <Route exact path={"/stories/:pool/:pageNumber?"}  children={ ({ match }) => (
                          <>
                            <Translation>
                              {(t) => <Helmet><title>{`AgFoPro ${this.state.selectedPool ? '— ' + this.state.selectedPool.name : ''} — ${t('menu.new_stories')}`}</title></Helmet> }
                            </Translation>
                            <StoriesPool
                              state={this.state}
                              match={match}
                              handleOpenLightboxModal={this.handleOpenLightboxModal}
                              handlePrepareDetail={this.handlePrepareDetail}
                              handleDownloadHires={this.handleDownloadHires}
                              getStories={this.getStories}
                              handleSelectPool={this.handleSelectPool}
                              />
                          </>
                        )} />

                        <Route exact path={"/story/:id"}>
                          <StoryDetail
                            state={this.state}
                            handleOpenLightboxModal={this.handleOpenLightboxModal}
                            handleDownloadHires={this.handleDownloadHires}
                            handleChangeGridView={this.handleChangeGridView}
                            handlePrepareDetail={this.handlePrepareDetail}
                            handleAddAsLightbox={this.handleAddAsLightbox}
                            />
                        </Route>

                        <Route path="/search">
                          <Translation>
                            {(t) => <Helmet><title>{`AgFoPro — ${t('header.search_title')}`}</title></Helmet> }
                          </Translation>
                          <Results 
                            state={this.state} 
                            handleClearFilters={this.handleClearFilters}
                            handleChangeFilters={this.handleChangeFilters}
                            handleSort={this.handleSort}
                            handleSubmitSearch={this.handleSubmitSearch}
                            handleChangeGridView={this.handleChangeGridView} 
                            handleSetPage={this.handleSetPage}
                            dateFromInputRef={this.dateFromInputRef} 
                            dateToInputRef={this.dateToInputRef}
                            pageInputRef={this.pageInputRef}
                            handleOpenLightboxModal={this.handleOpenLightboxModal}
                            handleDownloadHires={this.handleDownloadHires}
                            handlePrepareDetail={this.handlePrepareDetail}
                            handleSetShowMobileSidebar={this.handleSetShowMobileSidebar}
                            />
                        </Route>

                        <Route path="/my-account">
                          {this.state.userLoggedIn 
                            ? <MyAccount 
                                state={this.state} 
                                // handleCreateUser={this.handleCreateUser} // not needed here
                                handleLogOut={this.handleLogOut}
                                handleRefreshUser={this.handleRefreshUser}
                                handleUpdateUser={this.handleUpdateUser}
                                handleChangePassword={this.handleChangePassword}
                                handleOpenLightboxModal={this.handleOpenLightboxModal}
                                handleDeleteLightbox={this.handleDeleteLightbox}
                                handleRemoveFromLightbox={this.handleRemoveFromLightbox}
                                handleRemoveFromCart={this.handleRemoveFromCart}
                                handleSubmitOrder={this.handleSubmitOrder}
                                handleConfirmOffer={this.handleConfirmOffer}
                                handleDownloadInvoicePdf={this.handleDownloadInvoicePdf}
                                handleDownloadOrderZip={this.handleDownloadOrderZip}
                                handleDownloadHires={this.handleDownloadHires}
                                handleDownloadHiresFromOrder={this.handleDownloadHiresFromOrder}
                                handlePrepareDetail={this.handlePrepareDetail}
                                handleSetShowMobileSidebar={this.handleSetShowMobileSidebar}
                              />
                            // : <div style={{margin: "15% auto", width: "300px", textAlign: "center"}} ><h1>Please log in to view this page.</h1></div>
                            // : <Redirect to="/login" />
                            : <Loading loading={true} colorScheme="dark" /> // Redirect would be better (eg. Logout)
                          }
                        </Route>

                        <Route path="/lightbox/:token" children={ ({ match }) => ( 
                          <>
                            <Helmet>
                              <title>AgFoPro - Lightbox</title>
                            </Helmet>
                            <Lightbox 
                              token={match?.params.token}
                              public={true}
                              handlePrepareDetail={this.handlePrepareDetail}
                              handleOpenLightboxModal={this.handleOpenLightboxModal}
                              handleDownloadHires={this.handleDownloadHires}
                              handleAddAsLightbox={this.handleAddAsLightbox}
                            />
                          </>
                        )} />

                        <Route path="/photo/:id" children={ 
                          <Detail 
                            state={this.state} 
                            getAssetById={this.getAssetById}
                            handleOpenCalculator={this.handleOpenCalculator}
                            handleOpenLightboxModal={this.handleOpenLightboxModal}
                            handleDownloadHires={this.handleDownloadHires}
                            handleSubmitSearch={this.handleSubmitSearch}
                            /> 
                          } 
                        />
                      </Switch>

                      {this.state.calculatorIsOpen &&
                        <Calculator
                          state={this.state}
                          handleCloseCalculator={this.handleCloseCalculator}
                          addToCart={this.addToCart}
                        />
                      }

                      {this.state.lightboxModalIsOpen &&
                        <LightboxModal
                          state={this.state}
                          handleCloseLightboxModal={this.handleCloseLightboxModal}
                          handleCreateLightbox={this.handleCreateLightbox}
                          handleAddToLightbox={this.handleAddToLightbox}
                          lightboxInputRef={this.lightboxInputRef}
                        />
                      }   

                      {!this.state.loading &&
                        <Menu variant="footer" handleLogOut={this.handleLogOut} userLoggedIn={this.state.userLoggedIn}/>
                      }

                      {this.state.userLoggedIn && this.state.lightboxes.length > 0 &&
                        <LightboxBar state={this.state} />
                      }

                    </Route>
                  </Switch>
                </div>
              </UserContext.Provider>
            </ThemeProvider>
          </Router>
        </Suspense>
      </HelmetProvider> 
    );
  }
}

export { App, UserContext};

