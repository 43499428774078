import styled from '@emotion/styled'

const StyledGridViewToggle = styled('div')`
  & > .label {
    float: right;
    font-size: 13px;
    margin-top: 5px;  
  }
  @media (max-width: 767px) {
    & > .label {
      display: none;
    }
    button {
      padding-left: 5px;
      padding-right: 5px;
      &.button__float-right {
        margin-left: 10px;
      }
      svg{
        padding-right: 0;
      }
      span {
        display: none;
      }
    }
    
  }
`

export default StyledGridViewToggle