import styled from '@emotion/styled'

const StyledSearchForm = styled('form')` // subpage search header
  position: relative;
  background-color: ${props => props.theme.colors.black};
  transition: background-color 0.2s ease-out;
  display: inline-block;
  padding-top: 19px;
  z-index: 50;
  left: calc(4% + 150px);
  .searchbar-wrapper {
    width: 720px;
  }
  h1 {
    display: none;
  }
  .input-wrapper {
    float: left;
    width: 42%;
  }
  input {
    display: block;
    position: relative;
    z-index: 10;
    --webkit-appearance: none;
    appearance: none;
    height: 40px;
    border: unset;
    border-radius: 20px 0 0 20px;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blackHighFaded};
    padding-left: 44px;
    width: 100%;
    max-width: 370px;
    float: left;
    &:focus {
      outline: none;
    }
  }
  .icon {
    position: absolute;
    top: 10px;
    z-index: 10;
    width: 20px;
    height: 20px;
    svg {
      width: 20px !important;
      height: 20px !important;
      path {
        fill: ${props => props.theme.colors.white} !important;
      } 
    }
    &.search {
      left: 14px;
    }
    &.clear {
      right: 15px;
      cursor: pointer;
      svg {
        margin-top: 1px;
        width: 16px !important;
        height: 16px !important;
      }
    }
  }
  .pools-wrapper {
    position: relative;
    width: 58%;
    float: left;
    z-index: 10;
  }
  .pool-selector-mobile {
    display: none;
  }
  .pools {
    height: 40px;
    border-radius: 0 20px 20px 0;
    background-color: ${props => props.theme.colors.blackHighFaded};
    padding-right: 1%;
  }
  ul { // suggestions
    position: absolute;
    list-style: none;
    top: 25px;
    left: 0;
    margin: 0;
    padding: 50px 0;
    width: 470px;
    width: 100%;
    border-radius: 0 0 30px 30px;
    color: ${props => props.theme.colors.secondaryContrast};
    border: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    background-color: ${props => props.theme.colors.secondaryShaded};
    z-index: 1;
    text-align: left;
    li {
      padding: 5px 50px;
      &:hover,
      &.selected,
      &:active {
        background-color: ${props => props.theme.colors.secondaryAuxiliary};
        cursor: pointer;
      }
    }
  }
  .search-submit-mobile {
    display: none;
  }
  p { 
    display: none;
  }
  @media (max-width: 1199px) {
    .searchbar-wrapper {
      width: 42vw;
    }
    .input-wrapper {
      width: 55%;
    }
    .icon.clear {
      right: 5px;
    }
    input {
      max-width: none;
    }
    .pools-wrapper {
      display: block;
      position: relative;
      width: 45%;
      border-radius: 0 20px 20px 0;
      background-color: ${props => props.theme.colors.blackHighFaded};
      border: solid ${props => props.theme.colors.blackHighFaded};
      border-width: 1px 1px 1px 0; 
      z-index: 8;
      height: 40px;
    }
    .pool-selector-mobile {
      display: block;
      position: relative;
      height: 30px;
      text-align: left;
      font-size: 13px;
      padding-left: 23px;
      padding-top: 5px;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.black};
      border-radius: 30px 30px 30px 30px;
      z-index: 2;
      margin: 4px;
      svg {
        position: relative;
        top: -2px;
        right: 15px;
        width: 24px;
        height: auto;
        float: right;
      }
    }
    .pools {
      display: none;
      position: absolute;
      top: 16px;
      left: 4px;
      padding: 30px 0 20px 0;
      width: calc(100% - 8px);
      max-width: 560px;
      margin-left: auto;
      margin-right: auto;
      height: auto;
      float: none;
      clear: left;
      color: ${props => props.theme.colors.white};
      background-color: ${props => props.theme.colors.black};
      border: 1px solid ${props => props.theme.colors.blackAuxiliary};
      border-radius: 0 0 30px 30px;
      &.show {
        display: block;
      }
    }
  }

  @media (max-width: 767px) { 
    &:not(.full) {
      display: none;
    }
    &.show-mobile:not(.full) { // open search on subpages mobile. essentially a duplicate of mobile homepage (.full)
      display: block;
      width: 100%;
      color: ${props => props.theme.colors.white};
      height: auto;
      margin-top: 60px;
      left: 0;
      right: 0;
      text-align: center;
      padding-bottom: 50px;
      .searchbar-wrapper {
        width: 92%;
        max-width: 560px;
      }
      h1, p {
        display: none;
      }
      .title-subpage-mobile {
        display: block;
        font-family: 'SoehneBold';
        font-size: 13px;
        margin-bottom: 20px;
      }
      .input-wrapper {
        width: 100%;
        margin-bottom: 15px;
        float: none;
      }
      input {
        height: 50px;
        border-radius: 30px 30px 30px 30px;
        padding-left: 23px;
        margin-left: auto;
        margin-right: auto;
        float: none;
        background-color: ${props => props.theme.colors.white};
        color: ${props => props.theme.colors.whiteContrast};
        border: 1px solid ${props => props.theme.colors.whiteAuxiliary};
        border-width: 1px 0 1px 1px;
        max-width: 560px;
        font-size: 16px;
      }
      .icon {
        display: none;
      }
      .pools-wrapper {
        display: block;
        position: relative;
        float: none;
        width: 100%;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        z-index: 8;
        background-color: transparent;
        border: none;
        
      }
      .pool-selector-mobile {
        display: block;
        position: relative;
        height: 50px;
        text-align: left;
        font-size: 16px;
        padding-left: 23px;
        padding-top: 13px;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.blackHighFaded};
        border-radius: 30px 30px 30px 30px;
        z-index: 2;
        width: 100%;
        margin: 0;
        svg {
          position: relative;
          top: -2px;
          right: 15px;
          width: 27px;
          height: auto;
          float: right;
          path {

          }
        }
      }
      .pools {
        display: none;
        position: absolute;
        top: 25px;
        left: 0;
        padding: 30px 0 20px 0;
        width: 100%;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        float: none;
        clear: left;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.black};
        border: 1px solid ${props => props.theme.colors.blackAuxiliary};
        border-radius: 0 0 30px 30px;
       
        &.show {
          display: block;
        }
      }
      ul { // suggestions
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        z-index: 10;
        padding: 30px 0;
        li {
          padding: 5px 23px;
          font-size: 16px;
        }
      }
      .search-submit-mobile {
        display: block;
        width: 70px;
        height: 70px;
        background-color: ${props => props.theme.colors.blackHighFaded};
        border-radius: 35px;
        margin: 25px auto 0 auto;
        svg {
          position: relative;
          top: 23px;
          width: 24px;
          height: auto;
        }
      }
    }
  }

  &.full { // homepage search
    width: 100%;
    height: 405px;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 20px;
    color: ${props => props.theme.colors.white};
    margin-top: 80px;
    padding: 0;
    z-index: 20;
    .searchbar-wrapper {
      width: 92%;
      max-width: 1000px;
    }
    h1 {
      display: block;
      margin-top: 60px;
      font-family: 'SoehneBreitBuch';
      /* font-size: 22px; */
      /* margin-bottom: 0; */
      margin-bottom: 65px;
      span.sub-headline {
        display: inline-block;
        font-family: 'SoehneBreitExtraleicht';
        margin-top: 3px;
        letter-spacing: -0.2px;
        
      }
    }
    .input-wrapper {
      width: 49%;
    }
    input {
      height: 50px;
      border-radius: 30px 0 0 30px;
      background-color: ${props => props.theme.colors.white};
      color: ${props => props.theme.colors.whiteContrast};
      border: 1px solid ${props => props.theme.colors.whiteAuxiliary};
      border-width: 1px 0 1px 1px; 
      padding-left: 70px;
      max-width: 560px;
      font-size: 16px;
    }
    .icon {
      z-index: 10;
      position: absolute;
      top: 11px;
      width: 28px;
      height: 28px;
      svg {
        width: 28px !important;
        height: 28px !important;
        path {
          fill: ${props => props.theme.colors.black} !important;
        }
      }
      &.search {
        left: 28px;
      }
      &.clear {
        right: 15px;
        svg {
          margin-top: 3px;
          width: 22px !important;
          height: 22px !important;
        }
      }
    }
    .pools-wrapper {
      height: 50px;
      width: 51%;
    }
    .pools {
      height: 50px;
      border-radius: 0 30px 30px 0;
      background-color: ${props => props.theme.colors.white};
      border: solid ${props => props.theme.colors.whiteAuxiliary};
      border-width: 1px 1px 1px 0; 
      /* min-width: 390px; */
      /* padding-right: 17px; */
      width: 100%;
      float: left;
    }
    ul {
      margin: 0 auto;
    }
    p { 
      display: block;
      font-size: 16px;
      line-height: 1.3125;
      margin-top: 35px;
      a {
        color: ${props => props.theme.colors.white};
      }
    }
    .title-subpage-mobile {
      display: none;
    }
    @media (max-width: 1199px) {
      .input-wrapper {
        width: 60%;
      }
      input {
        max-width: none;
      }
      .pools-wrapper {
        display: block;
        position: relative;
        width: 40%;
        border-radius: 0 30px 30px 0;
        background-color: ${props => props.theme.colors.white};
        border: solid ${props => props.theme.colors.whiteAuxiliary};
        border-width: 1px 1px 1px 0; 
        z-index: 8;
      }
      .pool-selector-mobile {
        display: block;
        position: relative;
        height: 40px;
        text-align: left;
        font-size: 16px;
        padding-left: 23px;
        padding-top: 8px;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.black};
        border-radius: 30px 30px 30px 30px;
        z-index: 2;
        margin: 4px;
        svg {
          position: relative;
          top: -2px;
          right: 15px;
          width: 27px;
          height: auto;
          float: right;
        }
      }
      .pools {
        display: none;
        position: absolute;
        top: 25px;
        left: 4px;
        padding: 30px 0 20px 0;
        width: calc(100% - 8px);
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        height: auto;
        float: none;
        clear: left;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.black};
        border: 1px solid ${props => props.theme.colors.blackAuxiliary};
        border-radius: 0 0 30px 30px;
        &.show {
          display: block;
        }
      }
    }
    @media (max-width: 767px) {
      height: auto;
      margin-top: 60px;
      .searchbar-wrapper {
        width: 92%;
        max-width: 560px;
      }
      h1 {
        margin-top: 50px;
        font-size: 22px;
        margin-bottom: 50px;
        line-height: 1.36;
        width: 76%;
        margin-left: auto;
        margin-right: auto;
        span.sub-headline {
          display: inline-block;
          font-family: 'SoehneBreitExtraleicht';
          margin-top: 3px;
          letter-spacing: -0.2px;
        }
      }
      .input-wrapper {
        width: 100%;
        margin-bottom: 15px;
        float: none;
      }
      input {
        border-radius: 30px 30px 30px 30px;
        padding-left: 23px;
        margin-left: auto;
        margin-right: auto;
        float: none;
      }
      .icon {
        display: none;
      }
      .pools-wrapper {
        float: none;
        width: 100%;
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        background-color: transparent;
        border: none;
        border-width: 0;
      }
      .pool-selector-mobile {
        height: 50px;
        padding-top: 13px;
        width: 100%;
        margin: 0;
        background-color: ${props => props.theme.colors.blackHighFaded};
      }
      .pools {
        left: 0;
        width: 100%;
      }
      ul { // suggestions
        max-width: 560px;
        margin-left: auto;
        margin-right: auto;
        z-index: 10;
        padding: 30px 0;
        li {
          padding: 5px 23px;
          font-size: 16px;
        }
      }
      .search-submit-mobile {
        display: block;
        width: 70px;
        height: 70px;
        background-color: ${props => props.theme.colors.blackHighFaded};
        border-radius: 35px;
        margin: 25px auto 0 auto;
        svg {
          position: relative;
          top: 23px;
          width: 24px;
          height: auto;
        }
      }
      p { 
        font-size: 13px;
        margin-bottom: 53px;
        padding: 0 20px;
      }
    }
  }
  
`

const Wrapper = styled('div')`
  display: inline-block;
  position: relative;  
`

const RoundButton = styled('div')`
  display: block;
  float: left;
  height: 30px;
  border-radius: 15px;
  color: ${props => props.theme.colors.white};
  background-color: transparent;
  font-size: 13px;
  margin-top: 5px;
  margin-right: 2%;
  padding: 5px 3%;
  cursor: pointer;
  user-select: none;
  &:last-of-type {
    margin-right: 0;
  }
  &.selected {
    background-color: ${props => props.theme.colors.black};
  }
  &:hover {
    background-color: ${props => props.theme.colors.black};
  }
  &.full {
    height: 30px;
    border-radius: 15px;
    font-size: 14px;
    margin-top: 9px;
    color: ${props => props.theme.colors.whiteContrast};
    &.selected {
      background-color: ${props => props.theme.colors.black};
      color: ${props => props.theme.colors.white};
    }
    &:hover {
      background-color: ${props => props.theme.colors.black};
      color: ${props => props.theme.colors.white};
    }
  }
  /* @media (max-width: 991px) {
    float: none;
    display: none;
    &:first-of-type {
      display: block;
    }
  } */
  @media (max-width: 1199px) {
    float: none;
    color: ${props => props.theme.colors.white} !important;
    font-size: 16px !important;
    text-align: center;
    &.selected {
      display: none;
    }
    
  }
  /* @media (max-width: 767px) {
    width: 100%;
  } */
`

export { Wrapper, StyledSearchForm, RoundButton }