import styled from '@emotion/styled'

const EqualColumnLayout = styled('div')` // maybe spin off
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 0;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
  & > :first-of-type {
    position: relative;
    position: fixed;
    width: 50vw;
    border-right: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    min-height: 100vh;
    svg {
      position: absolute;
      top: 52px;
      left: 72px;
      width: 300px;
      height: auto;
    }
    & > div {
      position: absolute;
      top: 0;
      width: 50vw;
      height: 100%;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      opacity: 0;
      transition: opacity 0.15s ease-out;
      &.loaded {
        opacity: 1;
      }
    }
  }
  & > :last-of-type {
    width: 50%;
    min-height: 100vh;
    position: relative;
    left: 50%;
    padding: 0 20px;
    & .loginForm,
    & .resetPasswordForm {
      overflow: hidden;
      padding-top: 120px;
      padding-bottom: 70px;
      h1 {
        padding-bottom: 15px;
      }
    }
  }
  a {
    color: ${props => props.theme.colors.primaryContrast};
  }
  section {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
  }
  @media (max-width: 767px) {
    & > :first-of-type {
      width: 0;
      z-index: 10;
      & > div {
        display: none;
      }
      a {
        display: block;
        width: 100vw;
        height: 60px;
        border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
        background-color: ${props => props.theme.colors.primary};
        svg {
          top: 20px;
          left: 20px;
          width: 170px;
        }
      }
    }
    & > :last-of-type {
      width: 100%;
      left: 0;
      padding-top: 60px;
    }
    section {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }
`

const InputWrapper = styled('div')`
  max-width: 520px;
  margin: 0 auto;
  clear: both;
`

export { EqualColumnLayout, InputWrapper }