import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { apiv1 } from '../../api'
import Icon from '@mdi/react'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { mdiClose as CloseIcon } from '@mdi/js'
import Select from '../../components/Select'
import Input from '../../components/Input'
import Loading from '../../components/Loading'
import { StyledCalculator, InputWrapper, CalculatorForm } from './StyledCalculator'
import Button from '../../components/Button'

type CalculatorProps = {
  [somekey : string] : any 
}

const Calculator = (props : CalculatorProps) => {

  const { t, i18n } = useTranslation()

  const [loading, setLoading] = useState(false)
  const [priceList, setPricelist] = useState<{[somekey : string] : any} | null>(null)
  const [selection, setSelection] = useState<{[somekey : string] : any}>({addition: []})
  
  useEffect(() => {
    console.log("loading initial pricelist")
      apiv1.get(`assets/${props.state.currentAsset.id}/calculator/${props.state.currentPricelistId}`, {  // get initial pricelist - based on what? 
        headers: {'Accept-Language': i18n.language},
      })
      .then((response) => {
        const data = response.data as any
        console.log(data)
        setPricelist(data)
        setLoading(false)
      })
      .catch((error) => {
        console.warn(error);
      })
  }, [props.state.currentAsset.id, props.state.currentPricelistId, i18n.language]) 

  useEffect(() => {
    if (priceList?.id) {
      console.log("loading with new selection")
      apiv1.get(`assets/${props.state.currentAsset.id}/calculator/${priceList.id}`, {  
        headers: {'Accept-Language': i18n.language},
        params: selection
      })
      .then((response) => {
        const data = response.data as any
        console.log(data)
        setPricelist(data)
      })
      .catch((error) => {
        console.warn(error);
      })
    }
  }, [selection, i18n.language, props.state.currentAsset.id, priceList?.id]); 

  const handleSelectChange = (event : React.ChangeEvent<HTMLSelectElement>) => {
    // console.log(event.currentTarget.name)
    // console.log(event.currentTarget.value)

    const newSelection = {
      ...selection,
      [event.currentTarget.name]: event.currentTarget.value
    }
    setSelection(newSelection)
  }

  const handleAdditionChange = (event: React.ChangeEvent<HTMLInputElement> ) => { 
    // console.log(event.target.name)
    // console.log(event.target.value)

    let newAdditions = selection.addition

    if (newAdditions.includes(parseInt(event.target.name, 10)) && event.target.value === "0") {
      newAdditions.splice(newAdditions.indexOf(parseInt(event.target.name, 10)), 1);
    } else if (!newAdditions.includes(parseInt(event.target.name, 10)) && event.target.value === "1") {
      newAdditions.push(parseInt(event.target.name, 10))
    }

    const newSelection = {
      ...selection,
      addition: newAdditions
    }
    setSelection(newSelection)
  }

  const handleSubmitCalculator = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    // validation
    setLoading(true) // unrecoverable, maybe handle globally
    props.addToCart(selection, priceList)
  }


  return (
    <StyledCalculator>
      <div>
        <InputWrapper>
          <h1>{t('calculator.headline')}</h1>
          <p>{t('calculator.paragraph')}</p>
        </InputWrapper>
      </div>
      <div>
        <div className="close-icon" onClick={props.handleCloseCalculator}>
          <Icon path={CloseIcon}/>
        </div>
        <div className="calculator">
          <CalculatorForm onSubmit={handleSubmitCalculator}>
            {priceList && priceList.options && priceList.options.map((o : any) => {
              return(
                o.type === "list"
                  ? <div style={{padding: "15px 0"}} key={`${o.key}-div`}>
                      <Select
                        id={`${o.key}-select`}
                        key={`${o.key}-select`}
                        name={o.key}
                        label={o.title}
                        required={o.required}
                        options={[{value: "", text: t('calculator.please_select')}, ...o.values.map((v : any) => {return { value: v.id, text: v.title }})]}
                        onChange={handleSelectChange}
                        selected={selection[o.key] ? selection[o.key] : ""}
                        style={{padding: "30px 0"}}
                      />
                    </div>
                  : o.type === "boolean"
                    ? <div style={{padding: "15px 0", clear: "left"}} key={`${o.key}-div`}>
                        {/* <Input 
                          type="checkbox" 
                          key={`${o.key}-checkbox`}
                          value={selection[o.key] ? "1" : "0" } 
                          name={o.key} 
                          label={o.title} 
                          checked={selection[o.key]} 
                          onChange={handleSelectChangeBool }/>  */}
                          <p>{o.title}</p>
                          <Input id={`${o.key}-radio-false`} type="radio" value="1" name={o.id} label={t('calculator.yes')} checked={selection.addition.includes(o.id) ? true : false} onChange={handleAdditionChange }/> 
                          <Input id={`${o.key}-radio-true`} type="radio" value="0" name={o.id} label={t('calculator.no')} checked={!selection.addition.includes(o.id) ? true : false} onChange={handleAdditionChange } />
                        </div>
                    : <h1>unknown type: {o.type}</h1>
              )
            })}
            {priceList && priceList.price && 
              <InputWrapper>
                {/* <Button type="submit" text={`${priceList.price} €`} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{width: "100%", marginTop: "45px", marginBottom: "100px"}}/> */}
                <Button type="submit" size="large" colorScheme="white" style={{width: "100%", marginTop: "45px"}}>
                  <big>{`${priceList.price} €*`}</big>
                  <Icon style={{float: 'right'}} path={ArrowRightIcon} />
                  <span style={{float: 'right', marginTop: '3px'}}><small>{t('calculator.add_to_cart')}</small></span>
                </Button>
                <p>{t('calculator.include_vat')}</p>
                <p className="footnote">{t('calculator.footnote')}</p>
              </InputWrapper>
            }
          </CalculatorForm>
        </div>
        <Loading loading={loading} colorScheme="dark" /> 
      </div>
    </StyledCalculator>
  )
}

export default Calculator