import styled from '@emotion/styled'

const StyledLightbox = styled('div')`
  &.public {
    padding-top: 80px;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primaryContrast};
    min-height: 100vh;
  }
  .center-button {
    margin: 0 4% 0 4%;
    padding-bottom: 60px;
    text-align: center;
    a {
      text-decoration: none !important;
    }
  }
`

export default StyledLightbox