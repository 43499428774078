import { useTranslation } from 'react-i18next'
import SectionHeader from '../../components/SectionHeader'
// import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { mdiPlaylistPlus as AddIcon} from '@mdi/js'
import StyledStoryDetail from './StyledStoryDetail'
import { useEffect, useState } from 'react'
import { apiv1 } from '../../api'
import Loading from '../../components/Loading'
import { useParams } from 'react-router-dom'
import Series from '../../components/Series'
import pools from '../../config/pools'
import Button from '../../components/Button'
import { Helmet } from 'react-helmet-async'

const StoryDetail = (props : any) => {
  const { t, i18n } = useTranslation()

  const { id } = useParams<{ id: string }>();
  const [story, setStory] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  useEffect(() => { 
    window.setTimeout(() => { window.scrollTo(0,0)}, 1)
    setLoading(true)
    apiv1.get(`webseries/${id}`, {
      headers: {'Accept-Language': i18n.language }
    })
    .then((response) => {
      const data = response.data as Webseries
      console.log(data)
      setLoading(false)
      setStory(data)
    })
    .catch((error) => {
      console.log(error)
      setLoading(false)
    })
    .then(() => {});  
  }, [props.state.selectedPool, id, i18n.language])

  return (
    <StyledStoryDetail>
      { story && 
          <>
            <Helmet>
              <title>{`AgFoPro - ${story && story.title}`}</title>
            </Helmet>
            <SectionHeader title={`STORIES${story.number && pools.find((p: Pool) => {return(p.webseries === story.number)}) ? ' — ' + pools.find((p: Pool) => {return(p.webseries === story.number)})?.name : ""} `} byline=""/>

            <Series
              series={story}
              handleOpenLightboxModal={props.handleOpenLightboxModal}
              handleDownloadHires={props.handleDownloadHires}
              handlePrepareDetail={props.handlePrepareDetail}
              showViewToggle={true}
              view={props.state.gridViewSearch}
              handleChangeGridView={props.handleChangeGridView}
              scrollToAsset={props.state.currentAsset}
            />

            <div className="center-button">
              <Button 
                onClick={() => props.handleAddAsLightbox(story)}
                text={t('stories.add_as_lightbox')} 
                colorScheme="grey"
                size="large" 
                float="center" 
                icon={AddIcon} 
                alignIcon="left"/> 
            </div>
          </>
        }

        {/* <div className="center-button">
          <Button 
            href="/stories" 
            text={t('stories.all_stories')} 
            colorScheme="grey"
            size="large" 
            float="center" 
            icon={ArrowRightIcon} 
            alignIcon="right"/> 
        </div> */}

        
        
        <Loading loading={loading || props.state.loading}/>
    </StyledStoryDetail>

  )
}

export default StoryDetail