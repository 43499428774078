import { injectGlobal } from '@emotion/css'
// import SoehneBreitBuchWoff from './fonts/soehne-breit-web-buch.woff'
// import SoehneBreitBuchWoff2 from './fonts/soehne-breit-web-buch.woff2'


injectGlobal`
  
  @font-face {
    font-family: 'SoehneBreitBuch';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/soehne-breit-web-buch.woff2) format('woff2');
  }

  @font-face {
    font-family: 'SoehneBuch';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/soehne-web-buch.woff2) format('woff2');
  }

  @font-face {
    font-family: 'SoehneDreiViertel';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/soehne-schmal-web-dreiviertelfett.woff2) format('woff2');
  }

  @font-face {
    font-family: 'SoehneBold';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/soehne-web-kraftig.woff2) format('woff2');
  }

  @font-face {
    font-family: 'SoehneBreitExtraleicht';
    font-style: normal;
    font-weight: 400;
    src: url(/fonts/soehne-breit-extraleicht.woff2) format('woff2');
  }

  * {
    box-sizing: border-box;
  }
  html {
    height: 100%;
    width: 100vw;
    /* overflow-x: hidden;
    overflow-y: auto; */
  }
  body {
    margin: 0;
    font-family: 'SoehneBuch', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
  }
  #root {
    height: 100%;
    width: 100vw;
  }
  h1 {
    font-family: 'SoehneBreitBuch';
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 15px;
    margin: 0;
  }
  h2 {
    font-family: 'SoehneBold';
    font-size: 16px;
    font-weight: 400;
    margin: 0 0 15px;
  }
  strong {
    font-family: 'SoehneBold';
    font-weight: 400;
  }
  a {
    --webkit-appearance: none;
    appearance: none;
  }
  @media (max-width: 1199px) {
    
  }
  @media (max-width: 767px) {
    h1 {
      font-size: 16px;
    }
  }
`

export {}