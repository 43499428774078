import { Trans, useTranslation } from 'react-i18next'
import Grid from '../Grid'
import StyledCartOrder from './StyledCartOrder'
import { SpaceWrapper} from '../../containers/MyAccount/StyledMyAccount'
import Button from '../Button'
import FormError from '../FormError'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'

interface CartOrderProps {
  variant: "cart" | "offer" | "invoice", 
  order : any,
  error? : string,
  handleOpenLightboxModal? : any,
  handleDownloadHiresFromOrder?: any,
  handleRemoveFromCart? : any,
  handleSubmitOrder? : any,
  handleConfirmOffer?: any,
  handleDownloadInvoicePdf? : any,
  handleDownloadOrderZip? : any,
  handlePrepareDetail: any,
}

const CartOrder = (props : CartOrderProps) => {
  const { t } = useTranslation()

  return (
    <StyledCartOrder>
      <Grid 
        view="list"
        assets={props.order.articles?.map((art:any) => {return art.asset})}
        cartItems={props.order.articles}
        variant="cart"
        handleOpenLightboxModal={props.handleOpenLightboxModal ? props.handleOpenLightboxModal : null}
        handleDownloadHiresFromOrder={props.variant === "invoice" ? props.handleDownloadHiresFromOrder : null}
        orderId={props.variant === "invoice" ? props.order.id : null}
        articleIds={props.variant === "invoice" ? props.order.articles?.map((art:any) => {return art.id}) : null}
        handleRemoveFromCart={props.handleRemoveFromCart ? props.handleRemoveFromCart : null}
        handlePrepareDetail={props.handlePrepareDetail}
      />
      <section>
        <div className="cart-total">
          <ul>
            <li>
              <div>Summe</div>
              <div>{props.order.totalNettoPrice} {props.order.currency}</div>
            </li>
            <li>
              <div>MwSt</div>
              <div>{props.order.vat} {props.order.currency}</div>
            </li>
            <li>
              <div>Betrag</div>
              <div>{props.order.totalPrice} {props.order.currency}</div>
            </li>
          </ul>
          {/* <p><small>verfügbare Felder: totalNettoPrice, totalNettoPriceRaw, totalPrice, vat, <br/>vatPercentage, discount, discountPercentage, discountDescription</small></p> */}
        </div>
      </section>
      <SpaceWrapper>
        <div className="cart-instructions">
          {props.variant === "cart" &&
            <p>{t("account.cart_instructions")}</p>
          }
          <p>
            <Trans i18nKey="account.cart_contact">
              Contact text<a href="mailto:sales@agentur-focus.com">sales@agentur-focus.com</a>
            </Trans>
          </p>
        </div>
        {props.error && props.error.length &&
          <FormError text={t(props.error)}/>
        }
        {props.variant === "cart" &&
          <Button onClick={props.handleSubmitOrder} text={t("account.cart_request_offer")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="left" style={{width: "40%", marginTop: "60px", marginBottom: "100px", marginLeft: "30px"}}/>
        }
        {props.variant === "offer" &&
          <Button onClick={() => props.handleConfirmOffer(props.order.id)} text={t("account.create_invoice")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="left" style={{width: "40%", marginTop: "60px", marginBottom: "100px", marginLeft: "30px"}}/>
        }
        {props.variant === "invoice" && props.handleDownloadInvoicePdf &&
          <>
            <Button onClick={() => props.handleDownloadInvoicePdf(props.order.id)} text={t("account.download_invoice")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="left" style={{width: "40%", marginTop: "60px", marginBottom: "30px", marginLeft: "30px"}}/>
            <Button onClick={() => props.handleDownloadOrderZip(props.order.id)} text={t("account.download_as_zip")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="grey" float="left" style={{width: "40%", marginTop: "0", marginBottom: "100px", marginLeft: "30px"}}/>
          </>
        }

        </SpaceWrapper>
    </StyledCartOrder>
  )
}

CartOrder.defaultProps = {
  // handleOpenLightboxModal : () => {},
  // handleRemoveFromCart : () => {},
  // handleSubmitOrder: () => {}, 
}

export default CartOrder