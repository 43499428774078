import { Theme } from "@emotion/react"

const themeLight : Theme = {
  colors: {
    primary: '#141414',
    primaryHover: '#4D4D4D', // hover for grey (inverse) buttons
    primaryLowFaded: '#303030', // so far unused, cf. primary auxiliary
    primaryHighFaded: '#414141', // inputs background
    primaryContrast: '#ffffff',
    primaryHighlight: '#A0A0A0', // form borders
    primaryAuxiliary: '#303030',
    secondary: '#ffffff',
    secondaryShaded: '#F9F8F6', // suggestions background
    secondaryHighFaded: '#C3C3C3', // lighbox select
    secondaryContrast: '#000000',
    secondaryAuxiliary: '#EDEDED',
    warn: '#FFAA00',
    confirm: '#00DF38',
    black: '#141414',
    blackAuxiliary: '#303030',
    blackHighFaded: '#414141', // inputs background
    blackHover: '#4D4D4D',
    white: '#ffffff',
    whiteContrast: '#000000',
    whiteAuxiliary: '#EDEDED',
  }
}

const themeDark : Theme = {
  colors: {
    primary: '#ffffff',
    primaryHover: '#EDEDED',  // hover for grey (inverse) buttons **
    primaryLowFaded: '#303030', // so far unused, cf. primary auxiliary **
    primaryHighFaded: '#EDEDED', // inputs background
    primaryContrast: '#000000',
    primaryHighlight: '#A0A0A0', // form borders **
    primaryAuxiliary: '#EDEDED',
    secondary: '#141414',
    secondaryShaded: '#141414', // suggestions background **
    secondaryHighFaded: '#C3C3C3',
    secondaryContrast: '#ffffff',
    secondaryAuxiliary: '#303030',
    warn: '#FFAA00',  
    confirm: '#00DF38',
    black: '#141414',
    blackAuxiliary: '#303030',
    blackHighFaded: '#414141', // inputs background
    blackHover: '#4D4D4D',
    white: '#ffffff',
    whiteContrast: '#000000',
    whiteAuxiliary: '#EDEDED',
  }
}

export { themeLight , themeDark } 