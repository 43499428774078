import styled from '@emotion/styled'

const StyledCalculator = styled('div')` // c.f. Authentication layout
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
  z-index: 100;
  overflow: auto; // needed for scrolling
  & > :first-of-type {
    position: relative;
    position: fixed;
    width: 50vw;
    border-right: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    min-height: 100vh;
    padding: 135px 4% 0 4%;
    p {
      line-height: 1.625;
    }
  }
  & > :last-of-type {
    width: 50%;
    min-height: 100vh;
    position: relative;
    left: 50%;
    padding: 105px 4% 0 4%;
  }
  .close-icon {
    position: fixed;
    cursor: pointer;
    top: 30px;
    right: 30px;
    height: 60px;
    width: 60px;
    z-index: 100;
    svg {
      width: 44px !important;
      height: 44px !important;
      path {
        fill: ${props => props.theme.colors.primaryContrast} !important;
      }
    }
  }
  @media (max-width: 767px) {
    display: block;
    & > :first-of-type {
      position: relative;
      width: 100vw;
      min-height: 0;
      padding-top: 100px;
      padding-bottom: 50px;
      .detail__imageWrapper {
        flex-basis: 100%;
        min-height: 0;
      }
    }
    & > :last-of-type {
      width: 100vw;
      min-height:0;
      position: relative;
      left: 0;
      padding-top: 0;
      padding-bottom: 80px;
    }
    .close-icon {
      position: fixed;
      top: 48px;
      margin-left: 20px;
      right: 4%;
      width: 30px;
      height: 30px;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
  
`
const InputWrapper = styled('div')`
  max-width: 520px;
  margin: 0 auto;
  clear: both;
`
const CalculatorForm = styled('form')`
  max-width: 520px;
  margin: 0 auto;
  clear: both;
  padding-bottom: 150px;
  p.footnote {
    margin-top: 60px;
  }
`
  
export { StyledCalculator, InputWrapper, CalculatorForm }