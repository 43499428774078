import { useState } from 'react'
import StyledImage from './StyledImage'

type ImageProps = {
  src: string,
  className? : string,
  alt? : string,
}

const Image = (props : ImageProps) => {

  const [loaded, setLoaded] = useState(false) 

  return (
    <StyledImage src={props.src} className={`${props.className} ${loaded ? 'loaded' : ''}`} onLoad={() => setLoaded(true)} alt={props.alt}/>
  )
}

export default Image