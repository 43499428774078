import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Icon from '@mdi/react'
import { mdiClose as CloseIcon } from '@mdi/js'
import { mdiPlus as PlusIcon} from '@mdi/js'
import Image from '../../components/Image'
import Input from '../../components/Input'
import Loading from '../../components/Loading'
import { StyledLightboxModal, InputWrapper, AddLightboxForm, LightboxSelector } from './StyledLightboxModal'
import FormError from '../../components/FormError'

type LightboxModalProps = {
  [somekey : string] : any 
}

const LightboxModal = (props : LightboxModalProps) => {

  const { t } = useTranslation()

  const [loadingImage, setLoadingImage] = useState(props.state.currentAsset === null)
  const [newLightboxName, setNewLightboxName] = useState("")
  const [newLightboxErrors, setNewLightboxErrors] = useState<string|null>(null)
  // const [assetHasLoaded, setAssetHasLoaded] = useState(false)

  useEffect(() => {
    if (props.state.currentAsset !== null) {
      setLoadingImage(false)
    }
  }, [props.state.currentAsset]);

  const handleChangeNewLightboxName = (event : React.ChangeEvent<HTMLInputElement> ) => {
    setNewLightboxName(event.target.value)
  }

  const handleSubmitAddLightboxForm = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (newLightboxName.trim().length === 0) {
      setNewLightboxErrors(t("errors.is_required"))
    } else {
      setNewLightboxErrors(null)
      setNewLightboxName("")
      props.handleCreateLightbox(newLightboxName)
    }
  }

  const chooseLightbox = (lightboxId : string) => {
    // console.log(id)
    props.handleAddToLightbox(lightboxId)
  }

  console.log(props.state.currentAsset)

  return (
    <StyledLightboxModal>
      <div>
        <InputWrapper>
          <h1>{t('lightboxes.headline')}</h1>
          <div className="detail__imageWrapper">
            <Image src={props.state.currentAsset?.associatedMedia[1]?.contentUrl}
              className={`${props.state.currentAsset?.orientation === "vertical" ? "detail__img-portait" : "detail__img-landscape"}`}
              alt={props.state.currentAsset?.caption}/>
            <div className={`title ${loadingImage ? '' : 'show'}`}>{props.state.currentAsset?.headline ? props.state.currentAsset?.headline : props.state.currentAsset?.id}</div>
            <Loading loading={loadingImage} colorScheme="dark" variant="section" /> 
          </div>
        </InputWrapper>
      </div>
      <div>
        <div className="close-icon" onClick={props.handleCloseLightboxModal}>
          <Icon path={CloseIcon}/>
        </div>
        <div>
          <AddLightboxForm onSubmit={handleSubmitAddLightboxForm}>
            <InputWrapper>
              <Input 
                error={newLightboxErrors} 
                label={t('lightboxes.new_lightbox')} 
                value={newLightboxName} 
                onChange={handleChangeNewLightboxName} 
                className="no-bg" 
                placeholder={t("lightboxes.lightbox_placeholder")}
                inputRef={props.lightboxInputRef}
                disabled={props.state.loading}
                />
              <button type="submit" disabled={props.state.loading}>
                <Icon path={PlusIcon} />
              </button>
            </InputWrapper>
          </AddLightboxForm>
          <InputWrapper>
            <div>
              {props.state.lightboxes && props.state.lightboxes.map((lb : any, i : number) => {
                  return(
                    <LightboxSelector key={`selector-${i}`} onClick={() => chooseLightbox(lb.id)}>
                      <button>
                        <Icon path={PlusIcon} />
                      </button> {lb.name} <span>{lb.assetCount} {parseInt(lb.assetCount, 10 ) > 1 || parseInt(lb.assetCount, 10 ) === 0 ? t("lightboxes.photos") : t("lightboxes.photo")}</span>
                    </LightboxSelector>
                  )
                })
              }
              {props.state.lightboxError &&
                <FormError text={t(`errors.lightbox.${props.state.lightboxError}`)} />
              }
            </div>
            
            <Loading loading={props.state.loading} colorScheme="dark" variant="section" /> 
           </InputWrapper>
        </div>
        
      </div>
    </StyledLightboxModal>
  )
}

export default LightboxModal