import { useTranslation } from 'react-i18next'
import SectionHeader from '../../components/SectionHeader'
import Series from '../../components/Series'
import StyledStories from './StyledStories'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'

// StoriesAll and StoriesPool could possibly be consolidated, if eg. stories were given as prop

const StoriesAll = (props : any) => {
  const { t, i18n } = useTranslation()

  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => { // load
    if (!props.state.loading && !props.state.storiesAll ) { 
      console.log('select pool from stories / all')
      const handleSelectPool = props.handleSelectPool
      handleSelectPool(null)
    }
  }, [props.state.loading, props.handleSelectPool, props.state.storiesAll])

  useEffect(() => { // language change
    if (language !== i18n.language) {
      const getStories = props.getStories 
      getStories(null)
      setLanguage(i18n.language)
    }
  }, [i18n.language, language,  props.getStories])

  return (
    <StyledStories>
      <section className={`${props.state.loading ? 'loading' : ''}`}>
        <SectionHeader title={`${t('stories.new_stories')} ${props.state.selectedPool ? `— ${props.state.selectedPool.name}` : '' }`} byline=""/>
        { props.state.storiesAll && props.state.storiesAll.filter((s:any) => {return s.assets && s.assets.length > 0}).map((s : any, i : number) => {
          return (
            <Series
              key={`story-${s.id}-${i}`}
              series={s}
              loading={props.state.loading}
              handleOpenLightboxModal={props.handleOpenLightboxModal}
              handleDownloadHires={props.handleDownloadHires}
              handlePrepareDetail={props.handlePrepareDetail}
              noOfPhotos={4}
              preview={true}
              scrollToTop={true}
              scrollToAsset={props.state.currentAsset}
              scrollToSeries={props.state.currentSeries}
            />
          )})
        }
        
        <Loading loading={props.state.loading} variant="section"/>
      </section>
    </StyledStories>

  )
}

export default StoriesAll