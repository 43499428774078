import styled from '@emotion/styled'

const StyledFilterTags = styled('div')` 
  width: 100%;
  /* height: 110px; */
  padding: 42px 6% 38px 4%;
  border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  font-size: 13px;
  overflow: hidden;
  div.searchText {
    float: left;
    padding: 4px 12px 0 0;
  }
  div.clear {
    height: 30px;
    float: right;
    cursor: pointer;
    svg {
      width: 20px !important;
      height: 20px !important;
      float: right;
      padding-left: 5px;
      path {
        fill: ${props => props.theme.colors.secondaryContrast} !important;
      } 
    }
  }
  @media (max-width: 991px) {
    padding: 20px 4% 20px 4%;
    height: auto;
  }
  @media (max-width: 767px) {
    width: 100vw;
    div.searchText {
      float: none;
      font-family: 'SoehneBold';
      padding: 0 0 12px 0;
      &.injection {
        float: left;
        padding: 4px 12px 0 0;
        height: 28px;
        margin-bottom: 9px;
      }
    }
  }
`
const StyledSort = styled('div')`
  width: 100%;
  height: 80px;
  padding: 24px 6% 24px 4%; 
  font-size: 13px;
  div.count{
    float: left;
    font-family: 'SoehneBold';
    padding: 4px 12px 0 0;
  }
  @media (max-width: 991px) {
    padding-right: 4%; 
  }
  @media (max-width: 767px) {
    & > button {
      display: none;
    }
  }
`

export { StyledFilterTags, StyledSort }