import styled from '@emotion/styled'
import StyledButton from '../../components/Button/StyledButton'
import { InputWrapper } from '../Authentication/StyledLogin'

const SpaceWrapper = styled('div')` 
  clear: both;
  margin-left: 4%;
  max-width: none;
  overflow: hidden;
  margin-right: 4%;
  h1 {
    display: inline-block;
  }
  ${StyledButton} {
    margin-bottom: 0;
    position: relative;
    top: 5px;
  }
`

const StyledMyAccount = styled('div')` 
  section {
    padding-top: 35px;
    padding-bottom: 40px;
    overflow: hidden;
    border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    
  }
  form section:last-of-type {
    border-bottom: 0;
  }
  ${InputWrapper} {
    margin-left: 4%;
    margin-right: 4%;
  }
  h1 {
    span {
      text-transform: uppercase;
    }
  }
  h3 {
    font-size: 13px;
    margin-top: 30px;
    font-family: 'SoehneBuch';
    font-weight: normal;
    margin-bottom: 0;
  }
`

 export {  SpaceWrapper, StyledMyAccount }