import styled from '@emotion/styled'

const StyledImage = styled('img')`
  opacity: 0;
  transition: opacity 0.15s ease-out 0.1s;
  &.loaded {
    opacity: 1;
  }
`

export default StyledImage