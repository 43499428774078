// import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from "luxon"
import StyledSeries from './StyledSeries'
import Grid from '../Grid'
import Button from '../Button'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import GridViewToggle from '../GridViewToggle'
import pools from '../../config/pools'

type SeriesProps = {
  series: any | null,
  variant: "webseries" | "lightbox",
  view: string,
  noOfPhotos: number | null,
  loading: boolean,
  handleOpenLightboxModal?: any,
  handleDownloadHires?: any,
  preview?: boolean,
  showViewToggle: boolean,
  handleDeleteLightbox? : any,
  handleRemoveFromLightbox? : any,
  handlePrepareDetail : any,
  handleChangeGridView? : any,
  scrollToAsset?: any,
  scrollToSeries?: any,
  scrollToTop?: boolean,
}

const Series = (props : SeriesProps) => {
  // console.log(props.series)
  const { t } = useTranslation()
  
  // const allAssets = props.series.assets
  let displayAssets = props.series?.assets.map((a : any) => a.asset)
  if (props.noOfPhotos) {
    displayAssets = displayAssets?.slice(0, props.noOfPhotos)
  }

  return (
    <StyledSeries id={props.series?.id} className={`${props.variant} ${props.preview ? 'preview' : ''}`} >
      <div className="wrapper">
        {props.series &&
          <>
            <div className="headline">
              <h1>{props.series.title || props.series.name}</h1>
              {props.variant === "webseries" && props.series.number && props.series.number !== "" &&
                <div className="pool">{pools.find((p:Pool) => {return(p.webseries === props.series.number)})?.name}</div>
              }
              {props.variant === "lightbox" && !props.preview && props.handleDeleteLightbox &&
                <Button 
                  colorScheme="grey" 
                  style={{'border': 'none'}}
                  float="right"
                  onClick={(_) => props.handleDeleteLightbox(props.series.id)}
                  >
                  {t("lightboxes.delete")}
                </Button>
              }
            </div>
            {props.series.description && props.series.description.length > 0 &&
              <div className="description">{props.series.description}</div>
            }
            <div className="info">
              <div>{`${props.series.assets.length} ${props.series.assets.length > 1 ? t('lightboxes.photos') : t('lightboxes.photo')}`}</div>
              <div>{props.series.assets.length > 0 
                ? props.variant === "webseries"
                  ? props.showViewToggle 
                    ? <GridViewToggle
                        view={props.view}
                        handleChangeGridView={props.handleChangeGridView}
                        style={{float: "right"}}
                      />
                    : DateTime.fromISO(props.series.assets[0]?.asset.activationTime).toFormat(t('stories.timeformat')) 
                  : `${t("lightboxes.last_updated")} ${DateTime.fromISO(props.series.modificationDate).toFormat(t('stories.timeformat')) }`
                : null}</div>
            </div>
            
            <Grid 
              view={props.view} 
              assets={displayAssets} 
              truncateCaption={props.preview} 
              handleOpenLightboxModal={props.handleOpenLightboxModal}
              handleDownloadHires={props.handleDownloadHires}
              handleRemoveFromLightbox={props.handleRemoveFromLightbox}
              handlePrepareDetail={props.handlePrepareDetail}
              series={props.series}
              variant= {props.variant === "lightbox" ? "lightbox" : "default"}
              scrollToTop={props.scrollToTop|| !props.preview}
              scrollToAsset={props.scrollToAsset}
              scrollToSeries={props.scrollToSeries}
              />
            {props.preview &&
              <div className="all-button">
                <Button 
                  href={`${props.variant === "webseries" ? "/story" : "/my-account/lightboxes"}/${props.series.id}`} 
                  text={`${props.variant === "lightbox" ? t('lightboxes.lightbox') : t('stories.all_photos')}`} 
                  colorScheme={`${props.variant === "webseries" ? "default" : "white"}`}
                  size="large" 
                  float="center" 
                  icon={ArrowRightIcon} 
                  alignIcon="right"/> 
              </div>
            }
          </>
        }
      </div>
      
    </StyledSeries>
  )
}

Series.defaultProps = {
  series: null,
  variant: "webseries",
  view: "grid",
  noOfPhotos: null,
  loading: false,
  preview: false,
  showViewToggle: false,
  scrollToAsset: null,
  scrollToSeries: null,
  handleChangeGridView: () => {},
}

export default Series