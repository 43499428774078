import styled from '@emotion/styled'

const StyledFilterBar = styled('div')`
  clear: left;
  font-size: 13px;
  margin-top: 5px;
  position: relative;
  overflow: hidden;
` 

export default StyledFilterBar