import { useTranslation } from 'react-i18next'
import Button from '../Button'
import StyledFilterBar from './StyledFilterBar';

interface FilterBarProps {
  title? : string,
  facet : keyof Facets,
  values : string[],
  activeFilters: any, 
  handleClearFilters : any,
  handleChangeFilters : any,

}

const FilterBar = (props : FilterBarProps) => {

  const { t } = useTranslation()

  // translates the key of the search facet into search param
  // activeFilters are also search params, not facet keys
  const facet2param  = {
    collections : 'collection',
    color : 'color',
    licence : 'license',
    orientation : 'orientation',
  } as Facets

  const filterKey = facet2param[props.facet] as keyof Facets

  const isAllSelected = () => {
    if (!props.activeFilters.hasOwnProperty(filterKey) || 
      props.activeFilters[filterKey] === null ||
      props.activeFilters[filterKey].length === 0 ||
      props.activeFilters[filterKey].length === "") {
        return "selected"
      }
    return ""
  }

  const isSelected = (value : string) : string => {
    if (props.activeFilters.hasOwnProperty(filterKey) && 
      props.activeFilters[filterKey] && 
      props.activeFilters[filterKey] === value) {
      return "selected"
    }
    return ""
  }

  return (
    <StyledFilterBar>
      <p>{props.title}</p>
      {props.values && 
        <>
          <Button className={isAllSelected()} onClick={() => props.handleClearFilters([facet2param[props.facet]])}>{t("filter.all")}</Button>
          {props.values.map((value : string) => {

            return(
              <Button key={`facet-${value}`} className={isSelected(value)} onClick={() => props.handleChangeFilters({
                [facet2param[props.facet] as string]: value
              })
              }>
                { t(`filter.${value}`)}
              </Button>
          )})}
        </>
      }
    </StyledFilterBar>
  )
}

FilterBar.defaultProps = {
  // facet: "",
}

export default FilterBar