import styled from '@emotion/styled'

const StyledStoryDetail = styled('div')`
  /* padding: 50px 4% 50px 4%; */
  min-height: 100vh;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.secondary};
  transition: background-color 0.2s ease-out;
  position: relative;
  margin-top: 80px;
  padding-bottom: 150px;
  & > section {
    position: relative;
    /* min-height: 500px; */
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    &.faded {
      background-color: ${props => props.theme.colors.secondaryShaded};
    }
    &.loading{
      height: 500px;
      overflow: hidden;
    }
  }
  .center-button {
    margin: 0 4% 0 4%;
    text-align: center;
    a {
      text-decoration: none !important;
    }
  }
  @media (max-width: 767px) {
    margin-top: 60px;
  }
`

export default StyledStoryDetail
