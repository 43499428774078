import styled from '@emotion/styled'

const StyledLightboxModal = styled('div')` // c.f. Authentication layout
  font-size: 16px;
  display: flex;
  flex-wrap: wrap;
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100vw;
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primaryContrast};
  z-index: 100;
  overflow: auto; // needed for scrolling
  & > :first-of-type {
    position: fixed;
    width: 50vw;
    min-height: 100vh;
    border-right: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    padding: 135px 4% 50px 4%;
    p {
      line-height: 1.625;
    }
    .detail__imageWrapper {
      flex-basis: 70%;
      padding-right: 50px;
      margin-bottom: 50px;
      padding-top: 40px;
      font-size: 16px;
      position: relative;
      min-height: 500px;
      img {
        width: 100%;
        height: auto;
        margin-bottom: 15px;
        &.detail__img-portait {
          width: 70%;
        }
      }
      .title {
        opacity: 0;
        transition: opacity 0.2s ease-out 1s;
        &.show {
          opacity: 1;
        }
      }
    }
  }
  & > :last-of-type {
    width: 50%;
    min-height: 100vh;
    position: relative;
    left: 50%;
    padding: 105px 4% 50px 4%;
  }
  .close-icon {
    position: fixed;
    cursor: pointer;
    top: 30px;
    right: 30px;
    height: 60px;
    width: 60px;
    z-index: 100;
    svg {
      width: 44px !important;
      height: 44px !important;
      path {
        fill: ${props => props.theme.colors.primaryContrast} !important;
      }
    }
  }
  button {
    border: 0;
    box-sizing: border-box;
    height: 32px;
    width: 32px;
    border-radius: 16px;
    float: left;
    padding: 6px;
    margin-right: 10px;
    margin-bottom: 9px;
    font-size: 13px;
    color: ${props => props.theme.colors.primaryContrast};
    background-color: ${props => props.theme.colors.primaryAuxiliary};;
    user-select: none;
    text-decoration: none !important;
  }
  @media (max-width: 767px) {
    & > :first-of-type {
      position: relative;
      width: 100vw;
      min-height: 0;
      padding-top: 50px;
      padding-bottom: 0;
      .detail__imageWrapper {
        flex-basis: 100%;
        min-height: 0;
      }
    }
    & > :last-of-type {
      width: 100vw;
      min-height:0;
      position: relative;
      left: 0;
      padding-top: 0;
    }
    .close-icon {
      position: fixed;
      top: 48px;
      margin-left: 20px;
      right: 4%;
      width: 30px;
      height: 30px;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
`
const InputWrapper = styled('div')`
  max-width: 520px;
  margin: 0 auto;
  clear: both;
  position: relative;
`

const AddLightboxForm = styled('form')`
  max-width: 520px;
  margin: 0 auto;
  clear: both;
  padding-top: 20px;
  padding-bottom: 85px;
  position: relative;
  p.footnote {
    margin-top: 60px;
  }
  button {
    position: absolute;
    top: 55px;
    right: 0;
    float: right;
    cursor: pointer;
    &:hover {
      background-color: ${props => props.theme.colors.primaryHighFaded};
    }
  }
  input.no-bg {
    background-color: transparent !important;
    border: 1px solid ${props => props.theme.colors.primaryAuxiliary} !important;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 50px;
  }
`

const LightboxSelector = styled('div')`
  width: 100%;
  padding: 12px 38px 12px 0;
  border-top: 1px solid ${props => props.theme.colors.primaryAuxiliary};
  cursor: pointer;
  height: 60px;
  line-height: 1.85;
  color: ${props => props.theme.colors.secondaryAuxiliary};
  &:last-of-type {
    border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    margin-bottom: 150px;
  }
  button {
    margin-right: 15px;
  }
  span {
    float: right;
    font-size: 13px;
    opacity: 0.6;
    line-height: 2.5;
  }
  &:hover {
    color: ${props => props.theme.colors.secondary};
    button {
      background-color: ${props => props.theme.colors.primaryHighFaded};
    }
    span {
      opacity: 1;
    }
  }
`

  
export { StyledLightboxModal, InputWrapper, AddLightboxForm, LightboxSelector}