import styled from '@emotion/styled'

const StyledFormError = styled('div')`
  padding-top: 15px;
  padding-bottom: 10px;
  color: ${props => props.theme.colors.warn};
  svg {
    width: 14px;
    height: 14px;
    margin-right: 10px;
    position: relative;
    top: 1px;
    path {
      fill: ${props => props.theme.colors.warn};
    }
  }
`

export default StyledFormError