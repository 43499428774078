import styled from '@emotion/styled'

const StyledStories = styled('div')`
  /* padding: 50px 4% 50px 4%; */
  min-height: 100vh;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.secondary};
  transition: background-color 0.2s ease-out;
  position: relative;
  margin-top: 80px;
  & > section {
    position: relative;
    /* min-height: 500px; */
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    &.faded {
      background-color: ${props => props.theme.colors.secondaryShaded};
    }
    &.loading{
      height: 650px;
      overflow: hidden;
    }
  }
  @media (max-width: 767px) {
    margin-top: 60px;
  }
`

export default StyledStories
