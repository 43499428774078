import { StyledOverlay, StickyWrapper } from './StyledOverlay';
import Spinner from './Spinner';

type LoadingProps = {
  loading : boolean, 
  colorScheme: "light" | "dark", 
  variant: "exclude-header" | "fixed" | "section" | null
}


const Loading = (props : LoadingProps) => {
  return (
    <StyledOverlay className={`${props.loading ? 'show' : 'hide' } ${props.colorScheme} ${props.variant}`}>
      <StickyWrapper>
        <Spinner className={`${props.colorScheme}`}>
          <div className="cube1"></div>
          <div className="cube2"></div>
        </Spinner>
      </StickyWrapper>
    </StyledOverlay>
  ) 
}

Loading.defaultProps = {
  colorScheme: "light",
  variant: null
}

export default Loading