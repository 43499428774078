import styled from '@emotion/styled'

const StyledDetail = styled('div')`
  position: relative;
  margin-top: 80px;
  width: 100vw;
  &.pinned {
    position: fixed;
  }
  .detail__wrapper {
    padding: 50px 8% 50px 8%;
    background-color: ${props => props.theme.colors.secondaryContrast};
    color: ${props => props.theme.colors.primaryContrast};
    min-height: 100vh;
    h1 {
      font-family: 'SoehneBreitBuch';
      font-size: 24px;
      font-weight: 400;
      margin: 0 0 30px;
    }
    .detail__topWrapper,
    .detail__bottomWrapper {
      display: flex;
      .detail__imageWrapper {
        flex-basis: 70%;
        padding-right: 50px;
        margin-bottom: 50px;
        img {
          width: 100%;
          height: auto;
          margin-bottom: 15px;
          &.detail__img-portait {
            height: 80vh;
            /* height: 100%; */
            width: auto;
          }
        }
      }
      .detail__infoWrapper {
        flex-basis: 30%;
        font-size: 13px;
        margin-bottom: 50px;
        margin-top: 25px;
        min-width: 320px;
        a {
          color: ${props => props.theme.colors.primaryContrast}; !important;
        }
        ul {
          list-style: none;
          margin: 0;
          padding: 0;
          li {
            display: flex;
            width: 100%;
            padding-bottom: 15px;
            div:first-of-type {
              min-width: 140px;
              flex-basis: 140px;
            }
            div:last-of-type {
              
            }
          }
        }
        .detail__pricelistWrapper {
          overflow: hidden;
        }
      }
    }
    .detail__bottomWrapper {
      font-size: 13px;
      .detail__tags {
        flex-basis: 70%;
        padding-right: 50px;
        p {
          margin-top: 0;
        }
      }
    }
  }

  .detail-close,
  .detail-nav-button {
    position: absolute;
    cursor: pointer;
    height: 60px;
    width: 60px;
    
    padding-left: 8px;
    svg {
      width: 44px !important;
      height: 44px !important;
      path {
        fill: ${props => props.theme.colors.primaryContrast} !important;
      }
    }
  }
  .detail-close {
    top: 44px;
    right: 4%;
  }
  .detail-nav-button {
    top: calc(50% - 25px);
    padding-top: 8px;
    border: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    border-radius: 2px;
    position: fixed;
    background-color: rgba(0,0,0,0.1);
    transition: all 0.15s ease-out;
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
    &.invert {
      /* border: 1px solid ${props => props.theme.colors.secondaryHighFaded}; */
      border: 1px solid transparent;
      svg {
        path {
          fill: ${props => props.theme.colors.secondaryContrast} !important;
        }
      }
    }
  }
  .detail__more-images-wrapper {
    padding: 50px 4% 50px 4%;
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.secondaryContrast};
    h1 {
      font-size: 22px;
      margin: 0 4% 100px 4%;
    }
  }
  @media (max-width: 1199px) {
    .detail__wrapper {
      padding: 50px 4% 50px 4%;
      .detail__topWrapper,
      .detail__bottomWrapper {
        .detail__imageWrapper {
          flex-basis: 60%;
          padding-right: 30px;
          margin-bottom: 30px;
          img {
            &.detail__img-portait {
              width: 100%;
              height: auto;
            }
          }
        }
        .detail__infoWrapper {
          flex-basis: 40%;
        }
      }
      .detail__bottomWrapper {
        .detail__tags {
          flex-basis: 60%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    margin-top: 60px;
    overflow: hidden;
    .detail__wrapper {
      padding: 20px 4% 50px 4%;
      min-height: 100vh;
      h1 {
        font-size: 16px;
        margin: 0 0 20px;
        width: 80vw;
      }
      .detail__topWrapper,
      .detail__bottomWrapper {
        flex-wrap: wrap;
        .detail__imageWrapper {
          position: relative;
          flex-basis: 100vw;
          width: 100vw;
          padding-right: 0;
          margin-left: -4%;
          margin-right: -4%;
          overflow: hidden;
          text-align: center;
          img {
            width: 100vw;
            &.detail__img-portait {
              width: 100vw;
            }
          }
        }
        .detail__infoWrapper {
          flex-basis: 100%;
          width: 100%;
        }
      }
      .detail__topWrapper {
        .detail__infoWrapper {
          margin-top: 0;
          margin-bottom: 30px;
        }
      }
      .detail__bottomWrapper {
        .detail__tags {
          flex-basis: 100%;
          padding-right: 0;
          order: 2;
        }
        .detail__infoWrapper {
          order: 1;
        }
      }
    }
    .detail-close,
    .detail-nav-button {
      height: 28px;
      width: 28px;
      padding: 0;
      svg {
        width: 28px !important;
        height: 28px !important;
      }
    }
    .detail-close {
      top: 20px;
      right: 4%;
    }
    .detail-nav-button {
      position: absolute;
      top: unset;
      bottom: 13px;
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
      &.invert {
        border: 1px solid ${props => props.theme.colors.primaryAuxiliary};
        svg {
          path {
            fill: ${props => props.theme.colors.primaryContrast} !important;
          }
        }
      }
    }
    .detail__more-images-wrapper {
      h1 {
        margin-bottom: 50px;
      }
    }
    .mobile-button-wrapper {
      display: inline-block;
      button {
        float: n
      } 
    }
  }
`
export default StyledDetail