import styled from '@emotion/styled'

const StyledInput = styled('div')`
  font-size: 16px;  
  /* margin-bottom: 30px; */
  width: 100%;
  padding: 15px 0;
  label {
    p {
      margin-top: 0;
      margin-bottom: 12px;
    }
  }
  &.half {
    width: calc((100% - 20px)/2);
    float: left;
    &:first-of-type {
      margin-right: 20px;
      clear: left;
    }
  }
  input[type=text],
  input[type=password],
  input[type=submit] {
    --webkit-appearance: none;
    appearance: none;
    font-size: 16px;
    height: 48px;
    border: 0;
    outline: 0;
  }
  input[type=text],
  input[type=password] {
    color: ${props => props.theme.colors.primaryContrast};
    background-color: ${props => props.theme.colors.primaryHighFaded};
    border-radius: 2px;
    width: 100%;
    padding-left: 16px;
    &:focus {
      border: 1px solid ${props => props.theme.colors.primaryHighlight} !important;
      padding-left: 15px;
    }
    /* ::placeholder {
      font-size: 13px; 
    } */
  }
  // https://moderncss.dev/pure-css-custom-styled-radio-buttons/ //
  input[type=radio],
  input[type=checkbox] {
    --webkit-appearance: none;
    appearance: none;
    display: grid;
    place-content: center;
    float: left;
    margin: 0 6px 0 0;
    background: ${props => props.theme.colors.primaryHighFaded}; /* this is needed for iOS. Otherwise the background just becomes black. */
    font: inherit;
    /* color: currentColor; */
    width: 32px;
    height: 32px;
    /* border: 0.15em solid currentColor; */
    border-radius: 50%;
    /* display: inline-block; */
    /* border-radius: 100%; */
    /* vertical-align: text-bottom; */
    /* position: relative; */
    
    &::before {
      content: "";
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transform: scale(0);
      transition: transform 120ms ease-in-out;
      box-shadow: inset 1em 1em ${props => props.theme.colors.primaryContrast};
      /* Windows High Contrast Mode */
      background-color: CanvasText;
    }
    &:checked::before {
      transform: scale(1);
    }
    &:focus {
      outline: 1.5px solid ${props => props.theme.colors.primaryHighlight}; 
      outline-offset: -1px;
    }
  }
  &.input-radio-wrapper {
    float: left;
    width: auto;
    margin-bottom: 0;
    label {
      line-height: 32px;
      margin-right: 30px;
    }
  }
  &.input-checkbox-wrapper {
    span {
      margin-left: 42px;
      display: block;
    }
  }
  &.withErrors {
    input[type=text],
    input[type=password] {
      border: 1px solid ${props => props.theme.colors.warn};
      border-color: ${props => props.theme.colors.warn};
    }
    input[type=checkbox] {
      border: 1px solid  ${props => props.theme.colors.warn};
    }
  }
  input:disabled {
    opacity: 0.7;
  }
  @media (max-width: 767px) {
    &.half {
      width: auto;
      float: none;
      &:first-of-type {
        margin-right: 0;
        clear: none;
      }
    }
  }
`

export default StyledInput