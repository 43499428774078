// import Icon from '@mdi/react'
import { mdiClose as DeleteIcon} from '@mdi/js';
import { mdiPlaylistPlus as AddIcon} from '@mdi/js'
import { mdiEye as ViewIcon } from '@mdi/js'
import { mdiDownload as DownloadIcon } from '@mdi/js'
import StyledGrid from './StyledGrid'
import Button from '../Button'
import Image from '../Image'
import { Link } from "react-router-dom";
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DateTime } from "luxon"
import {UserContext} from '../../App'

type GridProps = {
  view: string,
  className? : string,
  assets: any,
  scrollToAsset?: any, // Asset -> triggers scrolling
  scrollToSeries?: any, // Series -> (only) if Asset not found
  scrollToTop?: boolean,
  truncateCaption : boolean,
  addWallSpacer : boolean,
  handleOpenLightboxModal?: any,
  handleRemoveFromLightbox?: any,
  handleDownloadHires?: any,
  handleDownloadHiresFromOrder?: any,
  orderId?: string | null,
  articleIds?: string[] | null,
  handleRemoveFromCart?: any,
  handlePrepareDetail?: any,
  handleSubmitSearch?: any,
  series?: any,
  variant : "default" | "lightbox" | "cart" | "downloads" | "detail",
  cartItems? : any,
  setAssetHasLoaded? : any,
}

const Grid = (props : GridProps) => {
  const { t } = useTranslation()
  
  useEffect(() => {
    if (props.scrollToAsset) {
      const currentElement = document.querySelector("#card-" + props.scrollToAsset.id)
      if (currentElement) {
        window.setTimeout(() => { currentElement.scrollIntoView({block: "center"})}, 75)
      } else if (props.scrollToSeries) {
        const currentSeries = document.querySelector("#grid-" + props.scrollToSeries.id)
        if (currentSeries) {
          window.setTimeout(() => { currentSeries.scrollIntoView({block: "center"})}, 75)
        }
      }
    } else if(props.scrollToTop) {
      window.setTimeout(() => { window.scrollTo(0,0)}, 2)
    }
  }, [props.scrollToAsset, props.scrollToSeries, props.scrollToTop]) 

  const relatedAssets = props.series ? props.series.assets.map((a:any) => a.asset) : props.assets
  const currentSeries = props.series && props.variant !== "lightbox" ? props.series : null

  return (
    <StyledGrid className={`${props.view} ${props.className} ${props.variant}`} id={`grid${props.series ? '-' + props.series.id : ''}`}>
      <UserContext.Consumer>
        {(user : any) => {
          return (
            <ul>
              {props.assets && props.assets.map((asset : any, i : number) => {
                if (asset) {
                  return (
                    <li className="card" id={`card-${asset.id}`} key={`asset-li-${i}`} > 
                      <div className="image-caption-toolbar">
                        <Link to={`/photo/${asset.id}`} onClick={props.variant === "detail" ? () => props.setAssetHasLoaded(false) : () => props.handlePrepareDetail(relatedAssets, currentSeries)}>
                          <Image key={`asset-${i}`} 
                            src={asset.associatedMedia[1].contentUrl} // preview or thumb
                            // className={`${asset.orientation === "vertical" ? "card__img-portait" : "card__img-landscape"}`}
                            className={`${parseInt(asset.height) > parseInt(asset.width) ? "card__img-portait" : "card__img-landscape"}`}
                            alt={asset.caption}/>
                        </Link>
                        
                        <div className={`card__caption ${props.truncateCaption ? 'card__caption_truncate' : ''}`}>
                          <div>
                            <div>{props.view === "grid" &&  asset.caption.length > 200 ? `${asset.caption.substring(0,200)}...` : asset.caption}</div>
                            {/* <div>{asset.copyright}</div> */}
                            <div>{`© ${asset.supplier?.name}`}</div>
                          </div>
                          <div className="card__toolbar">
                            <Button 
                              href={`/photo/${asset.id}`} 
                              onClick={props.variant === "detail" ? () => props.setAssetHasLoaded(false) : () => props.handlePrepareDetail(relatedAssets, currentSeries)}
                              text={props.view === "wall" ? "Detail" : null} 
                              icon={ViewIcon} 
                              iconSize={props.view === "wall" ? "small" : "large" } 
                              variant={props.view === "wall" ? null : "icon" } 
                              colorScheme={props.view === "wall" || props.variant === "lightbox" || props.variant === "cart" || props.variant === "downloads" ? "light" : null} 
                              />
                            { props.handleOpenLightboxModal && props.variant !== "lightbox" &&
                              <Button 
                                text={props.view === "wall" ? "Lightbox" : null} 
                                icon={AddIcon} 
                                iconSize={props.view === "wall" ? "small" : "large" } 
                                variant={props.view === "wall" ? null : "icon" } 
                                colorScheme={props.view === "wall" || props.variant === "cart" || props.variant === "downloads"  ? "light" : null} 
                                onClick={(e) => props.handleOpenLightboxModal(e, asset.id)}
                                />
                            }
                            { (props.handleDownloadHires && (user.customer?.directdownload === "1" || props.variant === "downloads")) &&
                              <Button 
                                text={props.view === "wall" ? "Download" : null} 
                                icon={DownloadIcon} 
                                iconSize={props.view === "wall" ? "small" : "large" } 
                                variant={props.view === "wall" ? null : "icon" } 
                                colorScheme={props.view === "wall" || props.variant === "lightbox" || props.variant === "cart" || props.variant === "downloads" ? "light" : null} 
                                onClick={() => props.handleDownloadHires(asset.id)} />
                            }
                            { (props.handleDownloadHiresFromOrder && props.orderId && props.articleIds && props.articleIds[i] && (props.variant === "cart" || props.variant === "downloads")) && // check for invoice already in CartOrder
                              // shown in CartOrder if "invoice"
                              <Button 
                                text={props.view === "wall" ? "Download" : null} 
                                icon={DownloadIcon} 
                                iconSize={props.view === "wall" ? "small" : "large" } 
                                variant={props.view === "wall" ? null : "icon" } 
                                colorScheme={props.view === "wall" || props.variant === "cart" || props.variant === "downloads" ? "light" : null} 
                                onClick={() => props.handleDownloadHiresFromOrder(props.orderId, props.articleIds && props.articleIds[i])} />
                            }
                            {props.handleRemoveFromLightbox && props.series &&
                              <Button 
                                icon={DeleteIcon} 
                                iconSize="large"
                                variant="icon"
                                colorScheme="light" 
                                onClick={(_e) => props.handleRemoveFromLightbox(props.series.id, asset.id)} 
                              />
                            }
                            {props.handleRemoveFromCart && 
                              <Button 
                                icon={DeleteIcon} 
                                iconSize="large"
                                variant="icon"
                                colorScheme="light" 
                                onClick={(_e) => props.handleRemoveFromCart(props.cartItems[i].id)} 
                              />
                            }
                          </div>
                        </div>
                        {props.variant !== "cart" && props.variant !== "downloads" &&
                          <div className="card__tags">
                            {asset.keywords && asset.keywords.length > 0 &&
                              <>
                                {asset.keywords.map((keyword : string) => { return (keyword !== "" &&
                                  props.handleSubmitSearch
                                    ? <Button onClick={() => props.handleSubmitSearch(keyword)} key={`tag-${keyword}`} text={keyword} />
                                    : <Button key={`tag-${keyword}`} text={keyword} interactive={false} />
                                )})}
                              </>
                            }
                          </div>
                        }
                      </div>

                      <ul className="card__list-detail">
                        {props.variant === "cart" 
                          ? <>
                              <li>
                                <h2>{asset.id}</h2>
                              </li>
                              <li>
                                <div>{t("detail.photographer")}</div>
                                <div><strong>{asset.supplier ? asset.supplier.name : ''}</strong></div>
                              </li>
                              <li>
                                <div>{t("detail.name")}</div>
                                <div>{props.cartItems[i].articleName}</div>
                              </li>
                              <br/>
                              <li>
                                <div>{t("account.cart_conditions")}</div>
                                <div style={{whiteSpace: "pre-wrap"}}>{props.cartItems[i].info}</div>
                              </li>
                              <br/>
                              <li>
                                <div>{t("account.cart_price")}</div>
                                <div>{`${props.cartItems[i].price} ${props.cartItems[i].currency} (${t("calculator.include_vat")})`}</div>
                              </li>
                            </>
                          : <>
                              { asset.headline &&
                                <li>
                                  <div>{t("detail.title")}</div>
                                  <div><strong>{asset.headline}</strong></div>
                                </li>
                              }
                              <li>
                                <div>{t("detail.name")}</div>
                                <div><strong>{asset.id} {asset.name ? `/ ${asset.name}` : ""}</strong></div>
                              </li>
                              <li>
                                <div>{t("detail.photographer")}</div>
                                <div><strong>{asset.supplier ? asset.supplier.name : ''}</strong></div>
                              </li>
                              <li>
                                <div>{t("detail.date")}</div>
                                <div>{DateTime.fromISO(asset.dateCreated).toLocaleString()}</div>
                              </li>
                              <li>
                                <div>{t("detail.image_size")}</div>
                                <div>{`${asset.width} x ${asset.height}px`}</div>
                              </li>
                              <li>
                                <div>{t("detail.licence_category")}</div>
                                <div>{t(`filter.${asset.license}`)}</div>
                              </li>
                              <li>
                                <div>{t("detail.property_release")}</div>
                                <div>{t(`detail.release.${asset.propertyReleased}`)}</div>
                              </li>
                              <li>
                                <div>{t("detail.model_release")}</div>
                                <div>{t(`detail.release.${asset.modelReleased}`)}</div>
                              </li>
                              {asset.contentLocation && asset.contentLocation.length > 0 && 
                                <li>
                                  <div>{t("detail.location")}</div>
                                  
                                  <div>{asset.contentLocation.map((l : any, i : number) => {return (
                                    <div key={`l-${i}`}>
                                      {(l.address?.addressLocality || l.address?.addressRegion || l.address?.addressCountry) && 
                                        <div>
                                          {`${l.address?.addressLocality && l.address.addressLocality + ", "}${l.address?.addressRegion && l.address.addressRegion + ", "}${l.address?.addressCountry && l.address.addressCountry}`}
                                        </div>
                                      }
                                      {l.geo?.longitude && l.geo?.latitude && 
                                        <div key={`m-${i}`}>
                                          <a target="blank" href={`https://maps.google.com/?q=${l.geo?.latitude},${l.geo?.longitude}`}>Google Maps</a>
                                        </div>
                                      }
                                    </div>
                                  )})}
                                  </div>
                                </li>
                              }
                              <li>
                                <div>{t("detail.copyright")}</div>
                                <div>{asset.copyright}</div>
                              </li>
                              <li>
                                <div>{t("detail.description")}</div>
                                <div>{asset.caption}</div>
                              </li>
                              {asset.specialInstructions && asset.specialInstructions.length > 0 &&
                                <li>
                                  <div>{t("detail.instructions")}</div>
                                  <div>{asset.specialInstructions}</div>
                                </li>
                              }
                            </> 
                        }
                      </ul>
                    </li>
                  )
                }
                return null
              })}
              {props.view === "wall" && props.addWallSpacer &&
                <li className="card"></li>
              }
            </ul>
          )
        }}      
      </UserContext.Consumer>
    </StyledGrid>
  )
}

Grid.defaultProps = {
  className: "",
  scrollToAsset: null,
  scrollToTop: false,
  truncateCaption : false,
  addWallSpacer : true,
  variant : "default",
  handlePrepareDetail: () => {}
}

export default Grid