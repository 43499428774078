import { useTranslation } from 'react-i18next'
import Icon from '@mdi/react'
import Loading from '../../components/Loading'
import Grid from '../../components/Grid'
import LayoutSidebar from '../../components/SidebarLayout'
import Button from '../../components/Button'
import Sidebar from '../SidebarSearch'
import { StyledFilterTags, StyledSort } from './StyledResults'
import { mdiBackspace as ClearIcon} from '@mdi/js'
import { DateTime } from "luxon"
import Paging from '../../components/Paging'
import GridViewToggle from '../../components/GridViewToggle'
import { useEffect, useState } from 'react'

type ResultsProps = {
  state: {
    loading: boolean,
    [somekey : string] : any 
  }
  [somekey : string] : any 
}

const Results = (props : ResultsProps) => {
  const { t, i18n } = useTranslation()

  const view = props.state.gridViewSearch

  const [photographerName, setPhotographerName] = useState("")
  const [categoryName, setCategoryName] = useState("")

  useEffect(() => {
    setPhotographerName(props.state.activeFilters.supplier_id && props.state.suppliers 
      ? props.state.suppliers.find((o : any) => o.id ===  parseInt(props.state.activeFilters.supplier_id, 10))?.name?.split(", ").reverse().join(" ") 
      : null )
  }, [props.state.activeFilters.supplier_id, props.state.suppliers, i18n.language])

  useEffect(() => {
    setCategoryName(props.state.activeFilters.category && props.state.categories 
      ? props.state.categories.find((o : any) => o.id ===  parseInt(props.state.activeFilters.category, 10))?.name
      : null )
  }, [props.state.activeFilters.category, props.state.categories, i18n.language])


  const activeFilters = Object.entries(props.state.activeFilters).map(([key, value] : any) => {
    // console.log([key, value])
    if (key === "creation_date_from" && value !== null && value !== "") {return [key, `${t('filter.timeframe_from')}: ${DateTime.fromISO(value).toFormat(t("filter.timeformatstring"))}`]}
    if (key === "creation_date_to" && value !== null && value !== "") {return [key, `${t('filter.timeframe_until')}: ${DateTime.fromISO(value).toFormat(t("filter.timeformatstring"))}`]}
    if (key === "supplier_id" && value !== null && value !== "") {return [key, photographerName]}
    if (key === "category" && value !== null && value !== "") {return [key, categoryName]}
    if (value !== null && value !== "") { return [key, t(`filter.${value}`)] }
    return null
  }).filter(f => f)

  console.log("render results")

  
  return (
    <LayoutSidebar 
      showMobileSidebar={props.state.showMobileSidebar}
      handleSetShowMobileSidebar={props.handleSetShowMobileSidebar}
      leftColumn= {
        <Sidebar
          state={props.state}
          handleSetShowMobileSidebar={props.handleSetShowMobileSidebar}
          handleChangeFilters={props.handleChangeFilters}
          handleClearFilters={props.handleClearFilters}
          dateFromInputRef={props.dateFromInputRef} 
          dateToInputRef={props.dateToInputRef} 
        />
      }
      rightColumn={
        <>   
          <StyledFilterTags> 
            <div className="searchText">{t("filter.search_for")} </div>
            { props.state.searchTerm && props.state.searchTerm.length > 0
              ? <Button text={`${props.state.searchTerm}`} interactive={false} colorScheme="grey"/>
              : <Button text={t("filter.all_photos")} interactive={false} colorScheme="white"/>
            }
            
            { activeFilters.map(filter => { 
              if (filter) {
                return (
                  <Button key={`${filter[1]}`} text={filter[1]} interactive={false} />
                )}
                return null
              } 
            )}

            { props.state.selectedPool && 
            <>
              <div className="searchText injection">in </div>
              <Button text={props.state.selectedPool.name} interactive={false} style={{borderRadius: "14px"}}/>
            </>
              
            }

            { activeFilters.length > 0 &&
              <div className="icon clear" onClick={() => props.handleClearFilters(Object.keys(props.state.activeFilters))}>
                {t("filter.delete")}
                <Icon path={ClearIcon} title="Clear" />
              </div>
            }
          </StyledFilterTags>
          {props.state.assets.length > 0 
            ? <>
                <StyledSort> 
                  <div className="count">{props.state.searchTotal} {t("filter.photos")}</div>
                  <Button text={t("filter.newest_first")} onClick={() => props.handleSort("-dateCreated")} selected={props.state.activeSort === "-dateCreated"}/>
                  <Button text={t("filter.oldest_first")} onClick={() => props.handleSort("dateCreated")} selected={props.state.activeSort === "dateCreated"}/>
                  <GridViewToggle
                    view={view}
                    handleChangeGridView={props.handleChangeGridView}
                    style={{float: "right"}}
                  />

                </StyledSort>
                
                <Grid 
                  view={view} 
                  assets={props.state.assets} 
                  scrollToAsset={props.state.currentAsset} 
                  scrollToTop={true} 
                  handleOpenLightboxModal={props.handleOpenLightboxModal}
                  handleDownloadHires={props.handleDownloadHires}
                  handlePrepareDetail={props.handlePrepareDetail}
                  handleSubmitSearch={props.handleSubmitSearch}
                  /> 
                <Paging 
                  total={props.state.searchTotal} 
                  handleSetPage={props.handleSetPage} 
                  itemsPerPage={props.state.itemsPerPage} 
                  currentPage={props.state.searchPage}
                  pageInputRef={props.pageInputRef}
                  />
              </>
            : !props.state.loading && 
                <div>
                  <h1 style={{margin: "80px auto", width: "40%"}}>{t('header.no_results')}</h1>
                </div>
          }
          <Loading loading={props.state.loading}/> 
        </>
      }
    />
  )
}

export default Results
