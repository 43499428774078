import Icon from '@mdi/react'
import { mdiClose as CloseIcon} from '@mdi/js';
import StyledMenu from './StyledMenu'
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

type MenuProps = {
  variant: "footer" | "menu",
  showMenu: boolean,
  userLoggedIn: boolean,
  closeHandler: () => void,
  handleLogOut: () => void,
}

const Menu = (props : MenuProps) => {
  const { t, i18n } = useTranslation()

  const logOutAndClose = () => {
    props.handleLogOut();
    props.closeHandler();
  }

  return (
    <StyledMenu className={`${props.variant} ${props.showMenu ? 'show' : 'hide' }`}>
      <div className="close-icon" onClick={props.closeHandler}>
        <Icon path={CloseIcon} title="Menu" />
      </div>
      <ul>
        <li>
          <ul>
            <h2>{t('menu.photos')}</h2>
            <li>
              <Link to="/" onClick={props.closeHandler}>{t('menu.start')}</Link>
            </li>
            <li>
              <Link to="/feeds" onClick={props.closeHandler}>{t('menu.new_photos')}</Link>
            </li>
            <li>
              <Link to="/stories" onClick={props.closeHandler}>{t('menu.new_stories')}</Link>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h2>{t('menu.pools')}</h2>
            <li>
              <Link to="/start/focus" onClick={props.closeHandler}>Agentur Focus</Link>
            </li>
            <li>
              <Link to="/start/magnum" onClick={props.closeHandler}>Magnum</Link>
            </li>
            <li>
              <Link to="/start/maps" onClick={props.closeHandler}>Maps</Link>
            </li>
            <li>
              <Link to="/start/ostkreuz" onClick={props.closeHandler}>Ostkreuz</Link>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h2>agenturfocus.pro</h2>
            <li>
              <a href={`${i18n.language ==="de" ? "https://agentur-focus.com/agfopro-impressum" : "https://agentur-focus.com/agfopro-imprint"}`} target="blank">{t('menu.imprint')}</a>
            </li>
            <li>
              <a href={`${i18n.language ==="de" ? "https://agentur-focus.com/agfopro-kontakt" : "https://agentur-focus.com/agfopro-contact"}`} target="blank">{t('menu.contact')}</a>
            </li>
            <li>
              <a href={`${i18n.language ==="de" ? "https://agentur-focus.com/agb" : "https://agentur-focus.com/terms-and-conditions"}`} target="blank">{t('menu.terms')}</a>
            </li>
            <li>
              <a href={`${i18n.language ==="de" ? "https://agentur-focus.com/agfopro-datenschutz" : "https://agentur-focus.com/agfopro-privacy"}`} target="blank">{t('menu.data_policy')}</a>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h2>{t('menu.my_account')}</h2>
            { props.userLoggedIn
              ? <>
                  <li>
                    <Link to="/my-account" onClick={props.closeHandler}>{t('menu.profile')}</Link>
                  </li>
                  <li>
                    <Link to="/my-account/orders" onClick={props.closeHandler}>{t('menu.orders')}</Link>
                  </li>
                  <li>
                    <Link to="/my-account/downloads" onClick={props.closeHandler}>{t('menu.downloads')}</Link>
                  </li>
                  <li>
                    <Link to="/my-account/lightboxes" onClick={props.closeHandler}>{t('menu.lightboxes')}</Link>
                  </li>
                  <li>
                    <Link to="/" onClick={logOutAndClose}>{t('menu.sign_out')}</Link>
                  </li>
                </> 
              : <>
                  <li>
                    <Link to="/login" onClick={props.closeHandler}>{t('menu.login')}</Link>
                  </li>
                  <li>
                    <Link to="/register" onClick={props.closeHandler}>{t('menu.register')}</Link>
                  </li>
                </>
            }
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          <ul>
            <h2>Agentur Focus</h2>
            <li>
              <Link to="/feed/focus" onClick={props.closeHandler}>{t('menu.new_photos')}</Link>
            </li>
            <li>
              <Link to="/stories/focus" onClick={props.closeHandler}>{t('menu.new_stories')}</Link>
            </li>
            <li>
              <a href="https://agentur-focus.com/fotografinnen" onClick={props.closeHandler}>{t('menu.photographers')}</a>
            </li>
            <li>
              <a href="https://agentur-focus.com" target="blank" onClick={props.closeHandler}>{t('menu.website')}</a>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h2>MAGNUM Photos</h2>
            <li>
              <Link to="/feed/magnum" onClick={props.closeHandler}>{t('menu.new_photos')}</Link>
            </li>
            <li>
              <Link to="/stories/magnum" onClick={props.closeHandler}>{t('menu.new_stories')}</Link>
            </li>
            <li>
              <a href="https://pro.magnumphotos.com" target="blank" onClick={props.closeHandler}>{t('menu.website')}</a>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h2>MAPS</h2>
            <li>
              <Link to="/feed/maps" onClick={props.closeHandler}>{t('menu.new_photos')}</Link>
            </li>
            <li>
              <Link to="/stories/maps" onClick={props.closeHandler}>{t('menu.new_stories')}</Link>
            </li>
            <li>
              <a href="https://www.mapsimages.com" target="blank" onClick={props.closeHandler}>{t('menu.website')}</a>
            </li>
          </ul>
        </li>
        <li>
          <ul>
            <h2>Ostkreuz</h2>
            <li>
              <Link to="/stories/ostkreuz" onClick={props.closeHandler}>{t('menu.new_stories')}</Link>
            </li>
            <li>
              <a href="https://www.ostkreuz.de" target="blank" onClick={props.closeHandler}>{t('menu.website')}</a>
            </li>
          </ul>
        </li>
      </ul>
      <div className="contact">
        <h2>{t('menu.contact')}</h2>
        <div>{t('menu.company_name')}</div>
        <div>{t('menu.company_street')}</div>
        <div>{t('menu.company_city')}</div>
        <div>{t('menu.company_phone')}</div>
        <div><a href="mailto:focus@agentur-focus.de">{t('menu.write_email')}</a></div>
        <br />
        <div>&copy; {DateTime.now().year} {t('menu.company_name')} UG</div>
      </div>
    </StyledMenu>
  ) 
}

Menu.defaultProps = {
  closeHandler : () => {},
  variant: "menu",
  showMenu: true,
}

export default Menu