import StyledSectionHeader from './StyledSectionHeader'

type SectionHeaderProps = {
  title: string,
  byline?: string 
  onClick?: any
  style?: any
  variant?: string
}

const SectionHeader = (props : SectionHeaderProps) => {
  // console.log(props.maxWidth)
  return (
    <StyledSectionHeader className={props.variant} onClick={props.onClick} style={props.style}>
      <div>
        <h1>{props.title}</h1>
        <h2>{props.byline}</h2>
      </div>
    </StyledSectionHeader>
  )
}

export default SectionHeader