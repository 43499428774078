import styled from '@emotion/styled'

const StyledTable = styled('div')`
  display: table;
  width: 100%;
  /* padding-top: 10px; */
  overflow: hidden;
  font-size: 13px;
  line-height: 1.4;
  .table-header-group {
    display: table-header-group;
    color: #757575;
  }
  .table-row {
    display: table-row;
    padding: 35px 4%; 
  }
  .table-header,
  .table-cell {
    display: table-cell;
    border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
  }
  .table-header {
    &:first-of-type {
      padding: 10px 0 20px 4%; 
    }
    &:last-of-type {
      padding: 10px 4% 20px 0; 
    }
  }
  .table-cell {
    &:first-of-type {
      padding: 35px 0 35px 4%; 
    }
    &:last-of-type {
      padding: 35px 4% 35px 0; 
    }
  }
  @media (max-width: 767px) {
    .table-cell {
      vertical-align: middle;
      &:first-of-type {
        padding: 20px 0 20px 4%; 
      }
      &:last-of-type {
        padding: 20px 4% 20px 0; 
      }
    }
    .hide-mobile {
      display: none;
    }
    button {
      white-space: unset;
      height: auto !important;
      line-height: 1.25;
      text-align: center;
      padding-top: 3px;
      padding-bottom: 5px;
    }
  }
`

export default StyledTable
