import styled from '@emotion/styled'

const StickyWrapper = styled('div')`
  position: sticky;
  top: 0;
  height: 100vh;
`

const StyledOverlay = styled('div')` // default for whole column
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  /* min-height: 100vh; */
  height: 100%;
  transition: opacity 0.3s ease-out 0.1s;
  z-index: 1;
  &.light {
    background-color: ${props => props.theme.colors.secondary};
  }
  &.dark {
    background-color: ${props => props.theme.colors.primary};
  }
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  &.show {
    opacity: 0.8;
    pointer-events: all;
    transition: opacity 0s ease-out;
  }
  &.fixed {
    height: 100vh;
    position: fixed;
  }
  &.exclude-header {
    height: calc(100vh - 80px);
    ${StickyWrapper} {
      top: 80px;
      height: calc(100vh - 80px);
    }
  }
  &.section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: auto;
    max-height: 100vh;
    & > div { // StickyWrapper
      height: 100%;
    }
  }

  @media only screen and (max-width:991px) {
    position: fixed;
  }
`



export { StyledOverlay, StickyWrapper };
