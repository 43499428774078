import { Link, useRouteMatch } from 'react-router-dom';
import StyledButton from './StyledButton';

type ButtonProps = {
  href? : string,
  className? : string,
  text? : string | null,
  icon? : string | null,
  iconSize? : "large" | "small",
  alignIcon? : "right" | "left",
  variant? : "icon" | null,
  interactive? : boolean,
  selected?: boolean,
  disabled?: boolean,
  float? : "right" | "left" | "center" | "clear",
  size? : "small" | "large",
  colorScheme?: "default" | "light" | "grey" | "white" | null,
  children? : any,
  style?: {},
  type : "submit" | "button",
  onClick?: (args: any) => void,
}



const Button = (props : ButtonProps) => {

  // highlight button if matches route
  const match = useRouteMatch({path: props.href})
  if (props.href) { 
    if (match && match.isExact) { // props.href.includes(match.path) for /:id - promlem: makes button non-interactive and there's no back/close on invoice and offer 
      props = {...props, selected: true}
    }
  }

  const innerbutton  = <StyledButton 
      type={props.type}
      onClick={props.onClick}
      disabled={props.disabled}
      className={`${props.className} ${props.selected ? 'selected ' : ''} ${props.interactive ? 'button__interactive ' : ''} 
        ${props.iconSize === "large" ? "button__icon-large" : "" } 
        ${props.alignIcon === "right" ? "button__icon-right" : "" } 
        ${props.variant === "icon" ? "button__icon-only" : "" } 
        ${props.colorScheme === "light" ? "button__light" : props.colorScheme === "grey" ? "button__grey" : props.colorScheme === "white" ? "button__white" : '' } 
        ${props.float === "right" ? "button__float-right" : props.float ==="center" ? "button__float-center" : '' }
        ${props.float === "clear" ? "button__clear" : '' }
        ${props.size === "large" ? "button__large" : "" }`} 
      style={props.style}
      >
      {props.children}

      { props.hasOwnProperty('icon') && props.icon &&
        <svg viewBox="0 0 24 24" >
          <path d={props.icon}></path>
        </svg>
      }
      <span>{props.text}</span>
    </StyledButton>
    
  
  return (
    <>
      {/* {innerbutton} */}
      {props.href 
        ? <Link to={props.href} style={{textDecoration: "none"}}>{innerbutton}</Link>
        : <>{innerbutton}</>
      }
    </>
  )
} 

Button.defaultProps = {
  className: "",
  type: "button",
  interactive: true,
  iconSize: "small",
  alignIcon: "left",
  float : "left",
  size : "small",
  colorScheme: "default",
  selected: false
}

export default Button