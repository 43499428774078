import styled from '@emotion/styled'

const StyledTimeSelect = styled('div')`
  font-size: 13px;  
  input {
    height: 28px;
    border: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    width: 100px;
    margin-right: 6px;
    margin-bottom: 2px;
    padding: 0 5px;
    &.withErrors {
      border-color: ${props => props.theme.colors.warn};
    }
    ::placeholder {
      font-size: 13px; 
    }
  }
  div.error {
    color: ${props => props.theme.colors.warn};
    margin-bottom: 5px;
  }
`

export default StyledTimeSelect