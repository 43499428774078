import { useTranslation } from 'react-i18next'
import SectionHeader from '../../components/SectionHeader'
import Series from '../../components/Series'
import StyledStories from './StyledStories'
import { useEffect, useState } from 'react'
import Loading from '../../components/Loading'
import pools from '../../config/pools'
// import Paging from '../../components/Paging'

const Stories = (props : any) => {
  const { t, i18n } = useTranslation()

  // const itemsPerPage = 16
  // const pageInputRef = useRef<HTMLInputElement>(null)
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => { // language change
    if (language !== i18n.language) {
      const getStories = props.getStories 
      getStories(props.state.selectedPool, props.state.storiesPage)
      setLanguage(i18n.language)
    }
  }, [i18n.language, language, props.getStories, props.state.selectedPool, props.state.storiesPage])

  useEffect(() => { // load
    // console.log ("----stories effect")
    // console.log (props.state.loading)
    // console.log (props.match.params.pool)
    // console.log (props.state.selectedPool?.slug)
    // console.log (!props.state.loading && (props.match.params.pool !== props.state.selectedPool?.slug ))
    if (!props.state.loading && (props.match.params.pool !== props.state.selectedPool?.slug )) { // || props.match.params.pageNumber !== props.state.feedPage
      console.log('select pool from stories')
      const handleSelectPool = props.handleSelectPool
      handleSelectPool(pools.find((p:Pool) => {return (p.slug === props.match.params.pool)}), props.match.params.pageNumber)
    }
  }, [props.state.loading, props.match.params.pool, props.match.params.pageNumber,  props.state.selectedPool, props.handleSelectPool]) // props.state.feedPage

  // const handleSetPage = (action : "set" | "increment" , value : number) => {
  //   // console.log(action, value)
  //   let newPage = 1
  //   if (action === "set") {
  //     newPage = value
  //     pageInputRef.current?.blur()
  //   } else if (action === "increment") {
  //     newPage = parseInt(props.state.feedPage) + value
  //   }
  //   console.log(newPage)
  //   props.getStories(props.state.selectedPool, ""+newPage)
  // }

  return (
    <StyledStories>
      <section className={`${props.state.loading ? 'loading' : ''}`}>
        <SectionHeader title={`${t('stories.new_stories')} ${props.state.selectedPool ? `— ${props.state.selectedPool.name}` : '' }`} byline=""/>
        { props.state.stories && props.state.stories.filter((s:any) => {return s.assets && s.assets.length > 0}).map((s : any, i : number) => {
          return (
            // <>
              <Series
                key={`story-${s.id}-${i}`}
                series={s}
                handleOpenLightboxModal={props.handleOpenLightboxModal}
                handleDownloadHires={props.handleDownloadHires}
                handlePrepareDetail={props.handlePrepareDetail}
                noOfPhotos={4}
                preview={true}
                scrollToTop={true}
                scrollToAsset={props.state.currentAsset}
                scrollToSeries={props.state.currentSeries}
              />
              /* <Paging 
                total={props.state.feed.total} 
                handleSetPage={handleSetPage} 
                itemsPerPage={itemsPerPage} 
                currentPage={parseInt(props.state.feedPage)}
                pageInputRef={pageInputRef}
              /> */
            // </>
          )})
        }
        
        <Loading loading={props.state.loading} variant="section"/>
      </section>
    </StyledStories>

  )
}

export default Stories