import styled from '@emotion/styled'

const InputWrapper = styled('div')`
  max-width: 520px;
  margin: 0 auto;
  clear: both;
  overflow: hidden;
`

const StyledUserForm = styled('form')` // maybe spin off
  overflow: hidden;
  padding-bottom: 150px;
  &.registerform {
    padding-top: 20px;
  }
  &.profileform {
    ${InputWrapper} {
      margin-left: 4%;
      margin-right: 4%;
    }
  }
  &.withErrors {
    input[type=text],
    input[type=password] {
      border: 1px inset ${props => props.theme.colors.warn};
      border-color: ${props => props.theme.colors.warn};
    }
  }
  section {
    padding-top: 50px;
    padding-bottom: 50px;
    overflow: hidden;
    border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
    &:last-of-type {
      border-bottom: 0;
    }
  }
`



export { StyledUserForm, InputWrapper }