import FormError from '../FormError'
import StyledSelect from './StyledSelect'

type SelectProps = {
  name : string,
  id: string,
  label? : string,
  required : boolean,
  selected : string,
  options: Array<{value : string, text: string}>, 
  onChange: any,
  className?: string,
  error?: string | null,
  variant?: "half" | "",
  style?: {[somekey : string] : string },
}

const Select = (props : SelectProps) => {

  return (
    <StyledSelect className={`${props.className} ${props.variant} ${props.error ? 'withErrors' : ''}`} >
      {props.label &&
        <label htmlFor={props.id}>{props.label}</label>
      }
      <select 
        name={props.name} 
        id={props.id} 
        onChange={props.onChange}
        required={props.required}
        value={props.selected}
        >
        {props.options.map((o, i) => {return(<option key={`option-${i}`} value={o.value}>{o.text}</option>)})}
      </select>
      <br/>
      {props.error &&
        <FormError text={props.error} />
      }
    </StyledSelect>
  )
}

Select.defaultProps = {
  options: [{value: "", text: "Options missing!"}],
  required: false,
  selected: "",
  variant: "",
  onChange: () => {}
}

export default Select