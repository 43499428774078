import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from '../../components/Loading'
import Grid from '../../components/Grid'
import StyledFeed from './StyledFeed'
// import { mdiBackspace as ClearIcon} from '@mdi/js'
// import { mdiViewModule as GridIcon} from '@mdi/js'
// import { mdiViewList as ListIcon} from '@mdi/js'
// import { mdiViewQuilt as WallIcon} from '@mdi/js'
// import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import Paging from '../../components/Paging'
import SectionHeader from '../../components/SectionHeader'
import pools from '../../config/pools'


type FeedProps = {
  state: {
    loading: boolean,
    [somekey : string] : any 
  }
  [somekey : string] : any 
}

const FeedPool = (props : FeedProps) => {
  const { t, i18n } = useTranslation()

  const itemsPerPage = 16
  const pageInputRef = useRef<HTMLInputElement>(null)
  const [language, setLanguage] = useState(i18n.language)

  useEffect(() => { // language change
    if (language !== i18n.language) {
      const getFeed = props.getFeed 
      getFeed(props.state.selectedPool, props.state.feedPage)
      setLanguage(i18n.language)
    }
  }, [i18n.language, language, props.getFeed, props.state.selectedPool, props.state.feedPage])

  useEffect(() => { // load
    if (!props.state.loading && (props.match.params.pool !== props.state.selectedPool?.slug || props.match.params.pageNumber !== props.state.feedPage)) { 
      const handleSelectPool = props.handleSelectPool
      handleSelectPool(pools.find((p:Pool) => {return (p.slug === props.match.params.pool)}), props.match.params.pageNumber)
    }
  }, [props.state.loading, props.match.params.pool, props.match.params.pageNumber, props.state.feedPage, props.state.selectedPool, props.handleSelectPool])

  const handleSetPage = (action : "set" | "increment" , value : number) => {
    // console.log(action, value)
    let newPage = 1
    if (action === "set") {
      newPage = value
      pageInputRef.current?.blur()
    } else if (action === "increment") {
      newPage = parseInt(props.state.feedPage) + value
    }
    console.log(newPage)
    props.getFeed(props.state.selectedPool, ""+newPage)
  }

  // console.log('render')

  return (
    <StyledFeed>
      {props.state.feed && // selectedPoolFeed
        <>
          <SectionHeader title={`${t('feed.title')} ${props.state.selectedPool ? `– ${props.state.selectedPool.name}` : '' }`} byline={""} style={{marginBottom: "50px"}}/>

          {/* Magnum image reload fix */}
          {props.state.selectedPool?.slug === "magnum" && props.state.feed.data && props.state.feed.data[0] && props.state.feed.data[0]?.associatedMedia[0] &&
            <img alt={props.state.feed.data[0]?.caption} style={{width: "0", height: "0", position: "absolute"}} className="magnum-test" src={props.state.feed.data[0]?.associatedMedia[0]?.contentUrl} onError={() => {props.getFeed(props.state.selectedPool)}} />
          }
          
          <Grid 
            view="grid" 
            assets={props.state.feed.data} 
            scrollToAsset={props.state.currentAsset} 
            scrollToTop={true} 
            handleOpenLightboxModal={props.handleOpenLightboxModal}
            handleDownloadHires={props.handleDownloadHires}
            handlePrepareDetail={props.handlePrepareDetail}
            /> 
          <Paging 
            total={props.state.feed.total} 
            handleSetPage={handleSetPage} 
            itemsPerPage={itemsPerPage} 
            currentPage={parseInt(props.state.feedPage)}
            pageInputRef={pageInputRef}
          />
        </>
      }
      
      <Loading loading={props.state.loading}/> 
    </StyledFeed>
                
  )
}

export default FeedPool
