import { useEffect, useState } from 'react'
import { StyledUserForm, InputWrapper } from './StyledUserForm'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { Trans, useTranslation } from 'react-i18next'
import Input from '../../components/Input'
import Select from '../../components/Select'
import Button from '../../components/Button'
import { apiv1 } from '../../api'
import FormError from '../FormError'
// import industries from '../../config/industries'

type UserFormProps = {
  variant : "register" | "profile",
  state : any,
  handleCreateUser? : any,
  handleUpdateUser? : any,
}

const UserForm = (props : UserFormProps) => {
  const { t, i18n } = useTranslation()

  const [countries, setCountries] = useState<any[]>([])
  // const [userWasUpdated, setUserWasUpdated] = useState(false)

  const [user, setUser] = useState({ // flat map 
    salutation: props.state.user.gender || "",
    firstName: props.state.user.givenName || "", // and props.state.user.address[0].givenName!
    lastName: props.state.user.familyName || "", // and props.state.user.address[0].familyName!
    email: props.state.user.email || "",
    password: props.state.user.password || "",
    repeatPassword: props.state.user.repeatPassword || "",
    phone: (props.state.user.address && props.state.user.address[0].telephone) || "",
    language: props.state.user.language || "de",
    company: props.state.user.company || "", // and props.state.user.address[0].company!
    industry: (props.state.user.industry && props.state.user.industry.id) || "",
    companyEmail: (props.state.user.address && props.state.user.address.find((a:any)=>{return(a.contactType === "billing")})?.email) || "",
    companyWebsite: props.state.user.url || "",
    companyVatId: props.state.user.taxNumber || "",
    street: (props.state.user.address && props.state.user.address[0].streetAddress) || "",
    zip: (props.state.user.address && props.state.user.address[0].postalCode) || "",
    city: (props.state.user.address && props.state.user.address[0].addressLocality) || "",
    country: (props.state.user.address && props.state.user.address[0].addressCountry) || "",
    hiresDownload: props.state.user.hiresDownload || false,
    termsAccepted: props.state.user.termsAccepted || false,
    newsletterSubscribed: props.state.user.newsletterSubscribed || false,
  } as Record<string, string | boolean>)

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    repeatPassword: "",
    phone: "",
    company: "",
    companyEmail: "",
    street: "",
    zip: "",
    city: "",
    country: "",
    termsAccepted: "",
  } as Record<string, string>)

  // useEffect(() => {
  //   if (userWasUpdated) {
  //     setUser({
  //       salutation: props.state.user.gender || "",
  //       firstName: props.state.user.givenName || "", // and props.state.user.address[0].givenName!
  //       lastName: props.state.user.familyName || "", // and props.state.user.address[0].familyName!
  //       email: props.state.user.email || "",
  //       password: props.state.user.password || "",
  //       repeatPassword: props.state.user.repeatPassword || "",
  //       phone: (props.state.user.address && props.state.user.address[0].telephone) || "",
  //       language: props.state.user.language || "de",
  //       company: props.state.user.company || "", // and props.state.user.address[0].company!
  //       industry: (props.state.user.industry && props.state.user.industry.id) || "",
  //       companyEmail: (props.state.user.address && props.state.user.address.find((a:any)=>{return(a.contactType === "billing")})?.email) || "",
  //       companyWebsite: props.state.user.url || "",
  //       companyVatId: props.state.user.taxNumber || "",
  //       street: (props.state.user.address && props.state.user.address[0].streetAddress) || "",
  //       zip: (props.state.user.address && props.state.user.address[0].postalCode) || "",
  //       city: (props.state.user.address && props.state.user.address[0].addressLocality) || "",
  //       country: (props.state.user.address && props.state.user.address[0].addressCountry) || "",
  //       hiresDownload: props.state.user.hiresDownload || false,
  //       termsAccepted: props.state.user.termsAccepted || false,
  //     })
  //     setUserWasUpdated(false)
  //   }
  // }, [userWasUpdated, props.state.user])

  useEffect(() => {
    apiv1.get(`countries`, {
      headers: {'Accept-Language': i18n.language},
    })
    .then((response) => {
      const data = response.data as any[]
      setCountries(data)
    })
    .catch((e) => { console.log(e) })
  }, [i18n.language])

  const changeData = (event:  React.ChangeEvent<HTMLInputElement> ) => { 
    // optional TODO: clear errors on change
    setUser({...user, [event.target.name]: event.target.value})
  }

  const changeDataBool = (event: React.ChangeEvent<HTMLInputElement> ) => { 
    // optional TODO: clear errors on change
    // console.log((event.target as HTMLInputElement).name)
    // console.log((event.target as HTMLInputElement).value)
    setUser({...user, [(event.target as HTMLInputElement).name]: (event.target as HTMLInputElement).value === "0" ? false : true})
  }

  const verifyUserFormAndSubmit = ( event : React.FormEvent<HTMLFormElement>, action : "create" | "update") => {
    event.preventDefault()
    
    const requiredPresent = ["firstName", "lastName", "email", ...(props.variant === "register" ? ["password", "repeatPassword"] : []), "phone", "company", "street", "zip", "city", "country"] 
    const requiredTrue = props.variant === "register" ? ["termsAccepted"] : []

    let errorObj = {} as Record<string, string>
    requiredPresent.forEach((f : keyof typeof user )=> {
      if ((user[f] as string).trim() === "") {
        errorObj[f] = t("errors.is_required")
      } else {
        errorObj[f] = ""
      }
    })
    requiredTrue.forEach((f : keyof typeof user )=> {
      if (!user[f]) {
        errorObj[f] = t("errors.is_required_accepted")
      } else {
        errorObj[f] = ""
      }
    })
    if (props.variant === "register") {
      if ((user.password as string).length < 8) {
        console.log('too short')
        errorObj["password"] = t("errors.password_too_short")
      } else {
        errorObj["password"] = ""
      }
      if (user.password !== user.repeatPassword) {
        errorObj["password"] = errorObj["repeatPassword"] = t("errors.passwords_dont_match")
      } else {
        errorObj["password"] = errorObj["repeatPassword"] = ""
      }
    }
    
    // TODO: verifiy email, what else? 

    setErrors({...errors, ...errorObj})

    const errorTuples : [string, string][] = Object.entries(errorObj)
    const activeErrorTuples = errorTuples.filter((et : [string, string] ) => {return(et[1] !== "")})
    if (activeErrorTuples.length > 0) {
      window.setTimeout(()=> {
        const inputWithErrors = document.querySelector('div.withErrors input')
        if (inputWithErrors) {
          inputWithErrors.scrollIntoView({block: "center"})
        }
      }, 50)
    } else {
      console.log(user)
      if (action === "update") {
        props.handleUpdateUser(user)
        // setUserWasUpdated(false)
      } else if (action === "create") {
        props.handleCreateUser(user)
      }
    }
  }

  const termslabel = <Trans i18nKey="register.accept_terms_text">
    Ich akzeptiere hiermit die <a href={`${i18n.language === "de" ? "https://agentur-focus.com/agb" : "https://agentur-focus.com/terms-and-conditions"}`} target="blank">Allgemeinen Geschäftsbedingungen</a> der Agentur Focus — Die Fotograf*innen UG. *
  </Trans>

  return (
    <StyledUserForm 
      className={`${props.variant === 'profile' ? 'profileform': 'registerform'} ${props.state.loginError ? 'withErrors' : null}`} 
      onSubmit={(e) => verifyUserFormAndSubmit(e, props.variant === 'profile' ? 'update' : 'create')}
      >
      <section>
        <InputWrapper>
          <h2>{t('register.personal_data')}</h2>
          <Input id="salutation-ms" type="radio" value="f" name="salutation" label={t('register.ms')} checked={user.salutation === "f"} onChange={changeData}/> 
          <Input id="salutation-mr" type="radio" value="m" name="salutation" label={t('register.mr')} checked={user.salutation === "m"} onChange={changeData} />
        </InputWrapper>
        <InputWrapper>
          <Input error={errors.firstName} name="firstName" label={t("register.firstname")} onChange={changeData} variant="half" value={user.firstName as string}/>
          <Input error={errors.lastName} name="lastName" label={t("register.lastname")} onChange={changeData} variant="half" value={user.lastName as string}/>
        </InputWrapper>
        <InputWrapper>
          <Input disabled={props.variant === "profile"} error={errors.email} name="email" label={t("register.email")} onChange={changeData} value={user.email as string}/>
          {props.variant === "register" &&
            <>
              <Input type="password" error={errors.password} name="password" label={t("register.password")} onChange={changeData} value={user.password as string}/>
              <Input type="password" error={errors.repeatPassword} name="repeatPassword" label={t("register.repeat_password")} onChange={changeData}  value={user.repeatPassword as string}/>
            </>
          }
          <Input error={errors.phone} name="phone" label={t("register.phone")} onChange={changeData}  value={user.phone as string}/>
        </InputWrapper>
        <InputWrapper>
          <p>{t('register.language')}</p>
          <Input id="language-de" type="radio" value="de" name="language" label={t('register.german')} checked={user.language === "de"} onChange={changeData }/> 
          <Input id="language-en" type="radio" value="en" name="language" label={t('register.english')} checked={user.language === "en"} onChange={changeData } />
        </InputWrapper>
      </section>
      <section>
        <InputWrapper>
          <h2>{t('register.company_data')}</h2>
          <Input error={errors.company} name="company" label={t("register.company")} onChange={changeData} value={user.company as string}/>
        </InputWrapper>
        <InputWrapper>
          {/* <Select
            id={`industry-select`}
            name="industry"
            className="grey"
            label={t("register.industry")}
            options={[{value: "", text: t('calculator.please_select')}, ...industries.map((industry : any) => {return { value: industry.id, text: industry.name[i18n.language] }})]}
            onChange={changeData}
            selected={user.industry as string}
            error={errors.industry}
            variant="half"
          /> */}
          {/* <Input error={null} name="industry" label={t("register.department")} onChange={changeData} variant="half" value={user.industry as string} disabled={true}/> */}
          <Input error={null} name="companyEmail" label={t("register.company_email")} onChange={changeData} value={user.companyEmail as string}/> {/* variant="half" */}
        </InputWrapper>
        <InputWrapper>
          <Input error={null} name="companyWebsite" label={t("register.website")} onChange={changeData} variant="half" value={user.companyWebsite as string}/>
          <Input error={null} name="companyVatId" label={t("register.vat_id")} onChange={changeData} variant="half" value={user.companyVatId as string}/>
        </InputWrapper>
        {props.variant === "register" &&
          <InputWrapper>
            <p>{t('register.hires_download')}</p>
            <Input type="checkbox" value={user.hiresDownload ? "0" : "1"} name="hiresDownload" label={t('register.hires_download_text')} checked={user.hiresDownload as boolean} onChange={changeDataBool }/> 
          </InputWrapper>
        }
      </section>
      <section>
        <InputWrapper>
          <h2>{t('register.address')}</h2>
          <Input error={errors.street} name="street" label={t("register.street")} onChange={changeData} value={user.street as string}/>
        </InputWrapper>
        <InputWrapper>
          <Input error={errors.zip} name="zip" label={t("register.zip")} onChange={changeData} variant="half" value={user.zip as string}/>
          <Input error={errors.city} name="city" label={t("register.city")} onChange={changeData} variant="half" value={user.city as string}/>
        </InputWrapper>
        <InputWrapper>
          <Select
            id={`country-select`}
            name="country"
            className="grey"
            label={t("register.country")}
            options={[{value: "", text: t('calculator.please_select')}, ...countries.map((c : any) => {return { value: c.id, text: c.name }})]}
            onChange={changeData}
            selected={user.country as string}
            error={errors.country}
          />
        </InputWrapper>
      </section>
      <section>
        {props.variant === "register" 
          ? <>
              <InputWrapper>
                <Input error={errors.termsAccepted} type="checkbox" value={user.termsAccepted ? "0" : "1"} name="termsAccepted" label={termslabel} checked={user.termsAccepted as boolean} onChange={changeDataBool }/> 
                <Input error={null} type="checkbox" value={user.newsletterSubscribed ? "0" : "1"} name="newsletterSubscribed" label={t('register.subscribe_newsletter')} checked={user.newsletterSubscribed as boolean} onChange={changeDataBool }/> 
                
                <Button type="submit" text={t("register.register_now")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{width: "100%", marginTop: "45px"}}/>
              </InputWrapper>
              {props.state.errors.registrationError &&
                <InputWrapper>
                  <FormError text={t(props.state.errors.registrationError)} />
                </InputWrapper>
              }
            </>
          : <InputWrapper>
              <Button type="submit" text={t("account.save_changes")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{width: "100%", marginTop: "45px"}}/>
            </InputWrapper>
        }
      </section>
    </StyledUserForm>
  )
}

UserForm.defaultProps = {
  handleCreateUser : () => {},
  handleUpdateUser : () => {},
}

export default UserForm
