import { useTranslation } from 'react-i18next';
import Button from '../Button'
import StyledFilterBar from './StyledFilterBar';

interface FilterBarProps {
  facet : keyof Facets,
  facetArray : any, // not Object but sorted tuple!!
  facetsByIdObject?: any,
  activeFilters: any, 
  [somekey : string] : any 
}

const FilterBar = (props : FilterBarProps) => {
  const { t } = useTranslation()

  // translates the key of the search facet into search param
  // activeFilters are also search params, not facet keys
  const facet2param  = {
    photographers: 'supplier_id',
    categories: 'category',
  } as Facets

  const filterKey = facet2param[props.facet] as keyof Facets

  const isAllSelected = () => {
    if (!props.activeFilters.hasOwnProperty(filterKey) || 
      props.activeFilters[filterKey] === null ||
      props.activeFilters[filterKey].length === 0 ||
      props.activeFilters[filterKey].length === "") {
        return "selected"
      }
    return ""
  }

  const isSelected = (value : string) : string => {
    if (props.activeFilters.hasOwnProperty(filterKey) && 
      props.activeFilters[filterKey] && 
      props.activeFilters[filterKey] === value) {
      return "selected"
    }
    return ""
  }

  return (
    <>
      {props.facetsByIdObject && 
        <StyledFilterBar>
          <p>{props.title}</p>
          <Button className={isAllSelected()} float="clear" onClick={() => props.handleClearFilters([facet2param[props.facet]])}>{t("filter.all")}</Button>
          {props.facetArray && props.facetArray.length > 0 // live facets
            ? <>
                {props.facetArray.map((t: any, i: number) => { // [id : string, count : number]
                  const entry = props.facetsByIdObject.find((o : any) => o.id ===  parseInt(t[0], 10)) // object id is number
                  // if (!entry) {console.log(t[0] + ": " + t[1])}
                  const label = entry ? `${entry.name?.split(", ").reverse().join(" ")} (${t[1]})` : `${t[0]} (${t[1]})`
                  if (entry) {
                    return(
                      <Button key={`facet-${t.id}-${i}`} className={isSelected(t[0])} float="clear" onClick={() => props.handleChangeFilters({
                        [facet2param[props.facet] as string]: t[0]
                      })
                      }>
                        {label} 
                        {/* {props.facet === "photographers" &&
                          <small>{" ID " + t[0]}</small>
                          } */}
                      </Button>
                    )
                  } else {
                    return null
                  }
                  
                })}
              </>
            : <>
                { props.activeFilters && Object.keys(props.activeFilters).length > 0  && Object.keys(props.activeFilters).includes(filterKey) && props.activeFilters[filterKey] !== null && // active filter but no facets
                  <>
                    <Button className={"selected"} float="clear">
                      { props.facetsByIdObject.find((o : any) => o.id ===  parseInt(props.activeFilters[filterKey], 10))?.name?.split(", ").reverse().join(" ")} 
                    </Button>
                  </>
                }
              </>               
          }
        </StyledFilterBar>
      }
    </>
  )
}

FilterBar.defaultProps = {
  // facet: "",
}

export default FilterBar