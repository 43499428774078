import  React, { useState, useEffect } from 'react';
// import axios from 'axios'
import { StyledSearchForm, Wrapper, RoundButton } from './StyledSearchForm';
import { Link, useLocation } from 'react-router-dom'
import { useTranslation, Trans } from 'react-i18next'
import { apiv1 } from '../../api'
// import { useInputChange } from './useInputChange'
import Icon from '@mdi/react'
import { mdiMagnify as SearchIcon } from '@mdi/js'
import { mdiBackspace as ClearIcon} from '@mdi/js'
import { mdiMenuDown as CaretDownIcon} from '@mdi/js';
import pools from '../../config/pools'


const Search = (props : any) => {
  // console.log('redraw search w/ ' + props.state.searchTerm)
  
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const [inputHasChanged, setInputHasChanged] = useState(false)
  const [searchValue, setSearchValue] = useState(props.state.searchTerm) 
  const [suggestions, setSuggestions] = useState<Suggestions>([])
  const [showSuggestions, setShowSuggestions] = useState(false)

  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState<number | null>(null)
  // const [input, handleInputChange] = useInputChange()

  useEffect(() => {
    // set searchValue for direct links
    // TODO: does not work with Keyword links if input has changed before...
    if (!inputHasChanged && props.state.searchTerm !== searchValue ) { // && searchValue === ""
      setSearchValue(props.state.searchTerm)
      setInputHasChanged(true)
    }
  }, [props.state.searchTerm, searchValue, inputHasChanged]);

  useEffect(() => {
    // if searchTerm is cleared through home link, clear searchvalue
    if (props.state.searchTerm === "") {
      setSearchValue("")
    }
  }, [props.state.searchTerm]);

  useEffect(() => {
    // reload suggestions for language change
    apiv1.get(`suggestions`, {
      params: {
        keyword: searchValue
      },
      headers: {'Accept-Language': i18n.language},
    })
    .then((response) => {
      const data = response.data as Suggestions
      setSuggestions(data)
    })
    .catch((error) => {console.log(error)})
  }, [searchValue, i18n.language]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setSearchValue(value)
    setInputHasChanged(true)
    setSelectedSuggestionIndex(null)
  }

  const handleClear = (event : React.SyntheticEvent) => {
    if (location.pathname === "/search") { // submit empty search if on search page
      props.handleSubmitSearch("", event)
    } else {
      props.handleClearSearchTerm()
    }
    setSearchValue("")
    setInputHasChanged(true)
    setSuggestions([])
    setSelectedSuggestionIndex(null)
    props.searchInputRef.current?.focus()
  }

  const handleSuggestionClick = (suggestion : string, event : React.SyntheticEvent) => {
    props.handleSubmitSearch(suggestion, event)
    setSearchValue(suggestion)
    setInputHasChanged(true)
    setShowSuggestions(false)
    setSelectedSuggestionIndex(null)
  }

  const handleKeyDown = (event : React.KeyboardEvent<HTMLInputElement> ) => {
    if (suggestions.length > 0) {
      if (selectedSuggestionIndex === null) {
        if (event.code ==='ArrowDown') {
          setSelectedSuggestionIndex(0)
          setSearchValue(suggestions[0])
          setInputHasChanged(true)
        }
      } else if (selectedSuggestionIndex < suggestions.length-1 && event.code ==='ArrowDown') {
        setSelectedSuggestionIndex(selectedSuggestionIndex + 1)
        setSearchValue(suggestions[selectedSuggestionIndex + 1])
        setInputHasChanged(true)
      } else if (selectedSuggestionIndex >= 1 && event.code ==='ArrowUp') {
        setSelectedSuggestionIndex(selectedSuggestionIndex - 1)
        setSearchValue(suggestions[selectedSuggestionIndex - 1])
        setInputHasChanged(true)
      }
    }
  }

  const handleTriggerSearch = (event : React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLDivElement> ) => {
    console.log("trigger search")
    event.preventDefault()
    props.searchInputRef.current?.blur()
    // setShowSuggestions(false)
    // setSuggestions([])
    setSelectedSuggestionIndex(null)
    props.setShowSearch(false)
    props.handleSubmitSearch(searchValue)
  }

  const handlePoolSelectorDisplay = (event : React.MouseEvent<HTMLDivElement> ) => {
    event.preventDefault()
    event.stopPropagation()
    props.handleSetShowMobilePoolSelector(!props.state.showMobilePoolSelector)
  }

  return (
    <StyledSearchForm className={`${props.variant} ${props.showSearchMobile ? "show-mobile" : ""}`} onSubmit={handleTriggerSearch}> 
      {/* {console.log('searchValue: ' + searchValue)} */}
      {/* {console.log('selectedSuggestionIndex: ' + selectedSuggestionIndex)} */}

      <h1>{t('header.headline')}<br/>
      <span className="sub-headline">{t('header.headline2')}</span></h1>

      <p className="title-subpage-mobile">{t("header.search_for")}</p>

      <Wrapper className="searchbar-wrapper">
        {suggestions && suggestions.length > 0 
          ? ( <ul style={{display: showSuggestions ? 'block' : 'none'}}>
                { suggestions.map((s, i)  => { return (
                  <li 
                    key={`suggestion-${i}`} 
                    className={i === selectedSuggestionIndex ? 'selected' : ''} 
                    onMouseDown={(e) => handleSuggestionClick(s, e)}>
                      {s}
                  </li>
                )}) }
              </ul> )
          : (null)}
        <Wrapper className="input-wrapper">
          <input 
            type="text" 
            name="search" 
            value={searchValue} 
            onChange={handleInputChange} 
            onKeyDown={handleKeyDown} 
            onFocus={() => setShowSuggestions(true)}
            onBlur={() => setShowSuggestions(false)}
            ref={props.searchInputRef}
            placeholder={t("header.search_placeholder")}
            />
          
          <div className="icon search">
            <Icon path={SearchIcon} />
          </div>


          { searchValue && searchValue.length > 0 &&
            <div className="icon clear" onClick={handleClear}>
              <Icon path={ClearIcon}
                title={t('filter.delete')}
                />
            </div>
          }
        </Wrapper>
        
        <Wrapper className="pools-wrapper">
          <div className={`pool-selector-mobile`} onClick={handlePoolSelectorDisplay}>
            <span>{props.state.selectedPool ? props.state.selectedPool.name : t("header.all_pools")}</span>
            <Icon path={CaretDownIcon} color="#ffffff"/>
          </div>

          <div className={`pools ${props.state.showMobilePoolSelector ? "show" : ""}`}>
            <RoundButton 
              className={`${props.variant}${props.state.selectedPool === null ? ' selected' : ''}`}
              onClick={() => {props.handleSelectPool(null); props.setShowSearch(false);}}
              >
              <span>{t('header.search_all')}</span>
            </RoundButton>
            {pools.map((p,i) => { return (
              <RoundButton 
                key={`pool-${i}`}
                className={`${props.variant}${props.state.selectedPool && props.state.selectedPool.id === p.id ? ' selected' : ''}`}
                onClick={() => {props.handleSelectPool(p); props.setShowSearch(false);}}
                >
                <span>{p.name}</span>
              </RoundButton>
            )})}
          </div>
        </Wrapper>

        <div className="search-submit-mobile" onClick={handleTriggerSearch}>
          <Icon path={SearchIcon} color="#ffffff"/>
        </div>

      </Wrapper>
      
      <p> 
        <Trans i18nKey="header.tip">
          Home<br/>tip<Link to="/login">login</Link>or<Link to="/register">register</Link>text
        </Trans>
      </p>  
      
    </StyledSearchForm>
 	)
}

export default Search