import { useState, useEffect } from 'react'
import { useTheme } from '@emotion/react'
import {Redirect, Route, Switch } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { DateTime } from "luxon"
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { SpaceWrapper, StyledMyAccount } from './StyledMyAccount'
import Loading from '../../components/Loading'
import LayoutSidebar  from '../../components/SidebarLayout'
import SidebarUser  from '../SidebarUser'
import { InputWrapper } from '../Authentication/StyledLogin'

import UserForm from '../../components/UserForm'
import Input from '../../components/Input'
import Button from '../../components/Button'
import ScrollToTop from '../../components/ScrollToTop'
import Series from '../../components/Series'
import Table from '../../components/Table'
import CartOrder from '../../components/CartOrder'
import FormError from '../../components/FormError'
import Lightbox from '../Lightbox'
import Grid from '../../components/Grid'
import { Helmet } from 'react-helmet-async'
// import SectionHeader from '../../components/SectionHeader'
// import Grid from '../../components/Grid'


const ChangePasswordForm = (props : any)=> {
  const { t } = useTranslation()

  const [passwords, setPasswords] = useState<{password : string, repeatPassword: string}>({password : "", repeatPassword : ""})
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(null)

  const changePassword = ( event : React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({...passwords, [event.currentTarget.name] : event.currentTarget.value })
  }

  const submitChangePasswordForm = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (passwords.password !== passwords.repeatPassword) {
      setResetPasswordError("errors.passwords_dont_match")
    } else if (passwords.password.length < 8) {
      setResetPasswordError("errors.password_too_short")
    } else {
      setResetPasswordError(null) 
      props.handleChangePassword(passwords.password)
      setPasswords({password : "", repeatPassword : ""})
    }
  }

  return (
    <form className={`changePasswordForm ${resetPasswordError ? 'withErrors' : null}`} onSubmit={submitChangePasswordForm}>
      <section>
        <InputWrapper>
          <Input type="password" value={passwords.password} error={null} name="password" label={t("account.new_password")} onChange={changePassword}/>
          <Input type="password" value={passwords.repeatPassword} error={resetPasswordError !== null ? t(`${resetPasswordError}`) : null} name="repeatPassword" label={t("account.repeat_new_password")} onChange={changePassword}/>
        </InputWrapper>
        <InputWrapper>
          <Button type="submit" text={t("account.save_password")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{width: "100%", marginTop: "45px", marginBottom: "100px"}}/> {/* disabled={resetPasswordError !== null} */}
        </InputWrapper>
      </section>
    </form>
  )
}



const MyAccount = (props : any) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const requests = props.state.orders.filter((o : any) => {return o.type === "proposal" && o.paymentStatus === "requested"})
  const offers = props.state.orders.filter((o : any) => {return o.type === "proposal" && o.paymentStatus === "none"})
  const invoices = props.state.orders.filter((o : any) => {return o.type === "final"})

  useEffect(() => {
    window.setTimeout(() => { window.scrollTo(0,0)}, 2)
  }, []) 
  
  return (
    <StyledMyAccount>
      <Helmet><title>{`AgFoPro — ${t('menu.my_account')}`}</title></Helmet>
      <LayoutSidebar 
        colorScheme="dark"
        showMobileSidebar={props.state.showMobileSidebar}
        handleSetShowMobileSidebar={props.handleSetShowMobileSidebar}
        leftColumn={
          <SidebarUser 
            state={props.state}
            handleSetShowMobileSidebar={props.handleSetShowMobileSidebar}
            handleLogOut={props.handleLogOut}
          />
        }
        rightColumn={
          <ScrollToTop>
            <Switch>
              <Route exact path={"/my-account"}>
                <Redirect to="/my-account/profile" />
              </Route>

              <Route path={"/my-account/profile"}>
                <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.profile_title')}`}</title></Helmet>
                <section>
                  <SpaceWrapper>
                    <h1>{t('account.profile_title')}</h1>
                  </SpaceWrapper>
                </section>
                <UserForm
                  variant="profile"
                  state={props.state}
                  // handleCreateUser={props.handleCreateUser} // not necessary here
                  handleUpdateUser={props.handleUpdateUser}
                />
              </Route>

              <Route path={"/my-account/change-password"}>
                <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.change_password_title')}`}</title></Helmet>
                <section>
                  <SpaceWrapper>
                    <h1>{t('account.change_password_title')}</h1>
                  </SpaceWrapper>
                </section>
                <ChangePasswordForm
                  handleChangePassword={props.handleChangePassword}
                />
              </Route>

              <Route exact path={"/my-account/orders"}>
                <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.offers_title')}`}</title></Helmet>
                <section>
                  <SpaceWrapper>
                    <h1>{t('account.offers_title')}</h1>
                    <Button 
                      colorScheme="grey" 
                      style={{'border': 'none'}}
                      float="right"
                      onClick={props.handleRefreshUser}
                      >
                      {t("account.refresh")}
                    </Button>
                  </SpaceWrapper>
                </section>
                {requests.length > 0 &&
                  <>
                    <SpaceWrapper>
                      <h2 style={{margin: "60px 0 20px"}}>{t("account.my_requests")}</h2>
                    </SpaceWrapper>
                    <Table
                      headers={[
                        t("account.offers_date"), 
                        t("account.offers_activity"), 
                        // "pType",
                        // "pStatus",
                        t("account.offers_photo_count"), 
                        t("account.offers_total"),
                        t("account.offers_number"), 
                        t("account.offers_action")]}
                      rowData={requests.map((o : any) => {
                        const activity = o.type === "proposal" 
                          ? (o.paymentStatus === "requested"
                            ? t("account.offers_request_activity")
                            : t("account.offers_offer_activity"))
                          : (o.type === "final" && o.paymentType === "invoice"
                            ? t("account.offers_invoice_activity")
                            : "")

                        return [
                          `${DateTime.fromISO(o.modifiedOn).toFormat(t('account.offers_timeformat')) }`,
                          activity,
                          // o.type,
                          // o.paymentType,
                          // o.paymentStatus,
                          o.articles ? o.articles.length : 'none',
                          `${o.totalPrice} ${o.currency}`,
                          o.id,
                          t("account.offers_standby")
                        ]
                      })}
                      rowStyles={[
                        {width: "19%"}, 
                        {width: "23%", color: theme.colors.warn}, 
                        {width: "7%"}, 
                        {width: "17%"},
                        {width: "11%"},
                        {width: "23%", color: theme.colors.warn} 
                      ]}
                      hideColsMobile={[1,2]}
                    />
                  </>
                }
                
                {offers.length > 0 &&
                  <>
                    <SpaceWrapper>
                      <h2 style={{margin: "60px 0 20px"}}>{t("account.my_offers")}</h2>
                    </SpaceWrapper>
                    
                    <Table
                      headers={[
                        t("account.offers_date"), 
                        t("account.offers_activity"), 
                        // "pType",
                        // "pStatus",
                        t("account.offers_photo_count"), 
                        t("account.offers_total"),
                        t("account.offers_number"), 
                        t("account.offers_action")]}
                      rowData={offers.map((o : any) => {
                        const activity = o.type === "proposal" 
                          ? (o.paymentStatus === "requested"
                            ? t("account.offers_request_activity")
                            : t("account.offers_offer_activity"))
                          : (o.type === "final" && o.paymentType === "invoice"
                            ? t("account.offers_invoice_activity")
                            : "")
                        return [
                          `${DateTime.fromISO(o.modifiedOn).toFormat(t('account.offers_timeformat')) }`,
                          activity,
                          // o.type,
                          // o.paymentType,
                          // o.paymentStatus,
                          o.articles ? o.articles.length : 'none',
                          `${o.totalPrice} ${o.currency}`,
                          o.id,
                          <Button key={`b1-${o.id}`} href={`/my-account/orders/${o.id}`} text={t("account.confirm_order")} float="center" style={{color: theme.colors.primary, backgroundColor: theme.colors.confirm, border: "0", margin: "-5px 0", position: "relative", height: "26px"}}/>
                        ]
                      })}
                      rowStyles={[
                        {width: "19%"}, 
                        {width: "23%", color: theme.colors.confirm}, 
                        {width: "7%"}, 
                        {width: "17%"},
                        {width: "11%"}, 
                        {width: "23%", color: theme.colors.warn, position: "relative", textDecoration: "none !important"} 
                      ]}
                      hideColsMobile={[1,2]}
                    />
                  </>
                }
               
                {invoices.length > 0 &&
                  <>
                    <SpaceWrapper>
                      <h2 style={{margin: "60px 0 20px"}}>{t("account.my_invoices")}</h2>
                    </SpaceWrapper>
                    <Table
                      headers={[
                        t("account.offers_date"), 
                        t("account.offers_activity"), 
                        // "pType",
                        // "pStatus",
                        t("account.offers_photo_count"), 
                        t("account.offers_total"),
                        t("account.offers_number"), 
                        t("account.offers_action")]}
                      rowData={invoices.map((o : any) => {
                        const activity = o.type === "proposal" 
                          ? (o.paymentStatus === "requested"
                            ? t("account.offers_request_activity")
                            : t("account.offers_offer_activity"))
                          : (o.type === "final" && o.paymentType === "invoice"
                            ? t("account.offers_invoice_activity")
                            : "")
                        return [
                          `${DateTime.fromISO(o.modifiedOn).toFormat(t('account.offers_timeformat')) }`,
                          activity,
                          // o.type,
                          // o.paymentType,
                          // o.paymentStatus,
                          o.articles ? o.articles.length : 'none',
                          `${o.totalPrice} ${o.currency}`,
                          o.id,
                          <Button key={`b2-${o.id}`} href={`/my-account/orders/${o.id}`} text={t("account.invoice_download")} float="center" colorScheme="white" style={{border: "0", margin: "-5px 0", position: "relative", height: "26px"}}/>
                        ]
                      })}
                      rowStyles={[
                        {width: "19%"}, 
                        {width: "23%"}, 
                        {width: "7%"}, 
                        {width: "17%"},
                        {width: "11%"},
                        {width: "23%"} 
                      ]}
                      hideColsMobile={[1,2]}
                    />
                  </>
                }
              </Route>

              <Route path={"/my-account/orders/:id"}  children={({ match }) => ( 
                <>
                  <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.offers_title')}`}</title></Helmet>
                  <section>
                    <SpaceWrapper>
                      <h1>{t('account.offers_title')} / {props.state.orders.find((o : any) => match && o.id === parseInt(match.params.id))?.type ==="final" ? t("account.invoice"): t("account.offer")} #{match ? match.params.id : ""}</h1>
                    </SpaceWrapper>
                    {/* Could be separate function to avoid multiple filters  */}
                    {props.state.orders.find((o : any) => match && o.id === parseInt(match.params.id))
                      ? <CartOrder 
                          variant={props.state.orders.find((o : any) => match && o.id === parseInt(match.params.id))?.type ==="final" ? "invoice" : "offer"}
                          order={props.state.orders.find((o : any) => match && o.id === parseInt(match.params.id))}
                          error={props.state.errors.paymentError}
                          handleConfirmOffer={props.handleConfirmOffer}
                          handleDownloadHiresFromOrder={props.handleDownloadHiresFromOrder}
                          handleDownloadInvoicePdf={props.handleDownloadInvoicePdf}
                          handleDownloadOrderZip={props.handleDownloadOrderZip}
                          handlePrepareDetail={props.handlePrepareDetail}
                        />
                      : <SpaceWrapper>
                          <br/><FormError text={t('account.order_not_found')}/>
                        </SpaceWrapper>
                    }
                  </section>
                </>
              )} />

              <Route path={"/my-account/downloads"}>
                <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.downloads_title')}`}</title></Helmet>
                <section>
                  <SpaceWrapper>
                    <h1>{t('account.downloads_title')}</h1>
                    <Button 
                      colorScheme="grey" 
                      style={{'border': 'none'}}
                      float="right"
                      onClick={props.handleRefreshUser}
                      >
                      {t("account.refresh")}
                    </Button>
                  </SpaceWrapper>
                </section>
                {props.state.orders.filter((o:any) => {return o.type ==="final"}).map((o:any) => {
                  return (
                    <div key={`order-${o.id}`}>
                      <SpaceWrapper>
                        <h3>{DateTime.fromISO(o.dateInvoice).toFormat(t('account.offers_timeformat')) }</h3>
                      </SpaceWrapper>
                      <Grid 
                        view="list"
                        assets={o.articles?.map((art:any) => {return art.asset})}
                        variant="downloads"
                        handleOpenLightboxModal={props.handleOpenLightboxModal}
                        handleDownloadHiresFromOrder={props.handleDownloadHiresFromOrder}
                        orderId={o.id}
                        articleIds={o.articles?.map((art:any) => {return art.id})}
                        handlePrepareDetail={props.handlePrepareDetail}
                      />
                    </div>
                  )
                })}
              </Route>

              <Route path={"/my-account/cart"}>
                <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.cart_title')}`}</title></Helmet>
                <section>
                  <SpaceWrapper>
                    <h1><span>{t('account.cart_title')}</span></h1>
                  </SpaceWrapper>
                </section>
                {props.state.carts && props.state.carts[0] && props.state.carts[0].articles && props.state.carts[0].articles.length 
                  ? <CartOrder 
                      variant="cart"
                      order={props.state.carts[0]}
                      handleOpenLightboxModal={props.handleOpenLightboxModal}
                      // handleDownloadHires={props.handleDownloadHires}
                      handleRemoveFromCart={props.handleRemoveFromCart}
                      handleSubmitOrder={props.handleSubmitOrder}
                      handlePrepareDetail={props.handlePrepareDetail}
                    />
                  : <SpaceWrapper>
                      <p><br/><span>{t('account.cart_empty')}</span></p>
                    </SpaceWrapper>
                }
              </Route>


              <Route exact path={"/my-account/lightboxes"}>
                <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.lightboxes_title')}`}</title></Helmet>
                <section>
                  <SpaceWrapper>
                    <h1><span>{t('account.lightboxes_title')}</span></h1>
                  </SpaceWrapper>
                </section>
                {props.state.lightboxes && props.state.lightboxes.length 
                  ? props.state.lightboxes.map((lb : any) => {
                      return (
                        <Series
                          key={`preview-${lb.id}`}
                          variant="lightbox"
                          series={lb}
                          handleOpenLightboxModal={props.handleOpenLightboxModal}
                          handleDownloadHires={props.handleDownloadHires}
                          handlePrepareDetail={props.handlePrepareDetail}
                          noOfPhotos={4}
                          preview={true}
                        />
                      )
                    })
                  : <SpaceWrapper>
                      <p><span>{t('lightboxes.no_lightboxes')}</span></p>
                    </SpaceWrapper>
                }
                
              </Route>
              <Route path="/my-account/lightboxes/:id" children={({ match }) => ( 
                <>
                  <Helmet><title>{`AgFoPro — ${t('menu.my_account')} — ${t('account.lightboxes_title')}`}</title></Helmet>
                  <section>
                    <SpaceWrapper>
                      <h1><span>{t('account.lightboxes_title')}</span></h1>
                    </SpaceWrapper>
                  </section>
                  <Lightbox 
                    lightbox={props.state.lightboxes.find((lb : any) => match && lb.id === parseInt(match.params.id))} 
                    handleDeleteLightbox={props.handleDeleteLightbox}
                    handleRemoveFromLightbox={props.handleRemoveFromLightbox}
                    handleDownloadHires={props.handleDownloadHires}
                    handlePrepareDetail={props.handlePrepareDetail}
                    userToken={props.state.userToken}
                  />
                  
                </>
              )} />
            </Switch>
            <Loading loading={props.state.loading} colorScheme="dark"/> 
          </ScrollToTop>
        }
      />
    </StyledMyAccount>
  
  )
}

export default MyAccount