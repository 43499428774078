const industries : Industry[] = [
  {
    id: "1",
    name: {
      de: "Ausstellung",
      en: "Exhibition",
    }
  },
  {
    id: "2",
    name: {
      de: "Bildredakteure, freie",
      en: "Image editors, free",
    }
  },
  {
    id: "3",
    name: {
      de: "Buchverlag",
      en: "Book publishing",
    }
  },
  {
    id: "4",
    name: {
      de: "Corporate",
      en: "Corporate",
    }
  },
  {
    id: "5",
    name: {
      de: "Designer",
      en: "Designer",
    }
  },
  {
    id: "15",
    name: {
      de: "Events",
      en: "Events",
    }
  },
  {
    id: "6",
    name: {
      de: "Fernsehen",
      en: "TV",
    }
  },
  {
    id: "7",
    name: {
      de: "Film",
      en: "Movie",
    }
  },
  {
    id: "24",
    name: {
      de: "Fotografen",
      en: "",
    }
  },
  {
    id: "8",
    name: {
      de: "Internet",
      en: "Internet",
    }
  },
  {
    id: "9",
    name: {
      de: "Kalender",
      en: "Calendar",
    }
  },
  {
    id: "10",
    name: {
      de: "Kundenmagazine",
      en: "Customer magazines",
    }
  },
  {
    id: "21",
    name: {
      de: "Musik",
      en: "Music",
    }
  },
  {
    id: "12",
    name: {
      de: "NGO",
      en: "NGO",
    }
  },
  {
    id: "16",
    name: {
      de: "Online",
      en: "Online",
    }
  },
  {
    id: "13",
    name: {
      de: "Partneragenturen",
      en: "Partner agencies",
    }
  },
  {
    id: "14",
    name: {
      de: "Presse",
      en: "Press",
    }
  },
  {
    id: "11",
    name: {
      de: "Public Information",
      en: "Public Information",
    }
  },
  {
    id: "20",
    name: {
      de: "Reisekatalog",
      en: "Travel catalog",
    }
  },
  {
    id: "26",
    name: {
      de: "Schulbuchverlag",
      en: "Schoolbook publishing",
    }
  },
  {
    id: "22",
    name: {
      de: "Stiftung",
      en: "Foundation",
    }
  },
  {
    id: "27",
    name: {
      de: "Theater",
      en: "Theatre",
    }
  },
  {
    id: "28",
    name: {
      de: "Universitäten",
      en: "Universities",
    }
  },
  {
    id: "23",
    name: {
      de: "Werbung",
      en: "Advertising",
    }
  },
]

export default industries


