import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../Button'
import StyledPaging from './StyledPaging'
import { mdiArrowLeft as BackIcon} from '@mdi/js'
import { mdiArrowRight as ForwardIcon} from '@mdi/js'

type PagingProps = {
  total: number,
  itemsPerPage: number,
  currentPage: number,
  handleSetPage: (action : "set" | "increment", value: number) => void
  pageInputRef: React.RefObject<HTMLInputElement>;
}

const Paging = (props : PagingProps) => {
  // console.log(props)
  const { t } = useTranslation()

  const [pageNumber, setPageNumber] = useState<string | number>(props.currentPage)
  const [pageInputHasError, setPageInputHasError] = useState(false)

  useEffect(() => {
    setPageNumber(props.currentPage)
  }, [props.currentPage])

  const totalPages = Math.ceil( props.total / props.itemsPerPage )

  const handleChangePageNumber = (event : React.ChangeEvent<HTMLInputElement>) => {
    setPageNumber(event.target.value)
  } 

  const submitPageInput = (event : React.FormEvent<HTMLFormElement> ) => {
    event.preventDefault()
    const pageAsNumber = Math.floor(Number(pageNumber))

    if (!isNaN(pageAsNumber) && pageAsNumber > 0 && pageAsNumber <= totalPages ) {
      setPageInputHasError(false)
      props.handleSetPage('set', pageAsNumber)
    } else {
      setPageInputHasError(true)
    }
  }

  const handleButtonClick = (increment : number) => {
    const pageAsNumber = Math.floor(Number(pageNumber))
    if (props.currentPage > 0 && increment === -1) {
      setPageNumber(pageAsNumber - 1)
      props.handleSetPage("increment", -1)
    } else if (props.currentPage < totalPages && increment === 1) {
      setPageNumber(pageAsNumber + 1)
      props.handleSetPage("increment", 1)
    }
    setPageInputHasError(false)
  }

  return (
    <>
      {totalPages > 1
        ? <StyledPaging>
            <div>
              <Button text={t("paging.previous")} icon={BackIcon} float="left" alignIcon="left" disabled={props.currentPage === 1} onClick={() => handleButtonClick(-1)}/>
              <form onSubmit={submitPageInput}>
              <input 
                className={pageInputHasError ? 'withErrors' : ''} 
                type="text" name="pageNumber" 
                onChange={handleChangePageNumber}
                value={`${pageNumber}`}
                ref={props.pageInputRef}
                />
              </form>
              <span>{`${t("paging.of")} ${totalPages}`}</span>
              <Button text={t("paging.next")} icon={ForwardIcon} float="left" alignIcon="right" disabled={props.currentPage === totalPages} onClick={() => handleButtonClick(1)}/>
            </div>
          </StyledPaging>
        : null
      }
    </>
    
  )
}

export default Paging