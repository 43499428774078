import styled from '@emotion/styled'

const StyledMenu = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity 0.1s ease-out;
  z-index: 50;
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  padding: 0 4% 80px 4%;
  overflow: auto;
  &.hide {
    opacity: 0;
    pointer-events: none;
  }
  &.show {
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.1s ease-out;
  }
  &.footer{
    position: relative;
    height: auto;
    z-index: unset;
    .close-icon {
      display: none;
    }
    & > ul { // first level
      margin-top: 120px;
    }
  }
  .close-icon {
    /* float: right; */
    position: absolute;
    top: 22px;
    right: 4%;
    cursor: pointer;
    svg {
      width: 36px !important;
      height: 36px !important;
      path {
        fill: ${props => props.theme.colors.white} !important;
      }
    } 
  }
  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
  }
  ul, li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  & > ul { // first level
    margin-top: 177px;
    display: flex;
    flex-wrap: wrap;
    & > li {
      width: 25%;
      & > ul li {
        padding-right: 15px;
      }
    }
  }
  ul + ul {
    margin-top: 80px;
  }
  h2 {
    font-size: 14px;
    font-family: 'SoehneBuch';
  }
  li {
    font-size: 24px;
    line-height: 1.63;
    flex-grow: 1;
  }
  .contact {
    margin-top: 80px;
    line-height: 1.63;
    a {
      text-decoration: underline;
    }
  }
  @media (max-width: 767px) {
    /* position: absolute; */
    &.footer{
      & > ul { // first level
        margin-top: 50px;
      }
    }
    .close-icon {
      position: fixed;
      top: 18px;
      margin-left: 20px;
      svg {
        width: 24px !important;
        height: 24px !important;
      }
    }
    & > ul {
      margin-top: 50px;
      & > li {
        width: 100%;
      }
    }
    ul + ul {
      margin-top: 0 !important;
    }
    ul ul {
      margin-top: 30px;
    }
    li {
      font-size: 20px;
      width: 100%;
    }
  }
`

export default StyledMenu