import { useTranslation } from 'react-i18next'
import StyledLighboxBar from './StyledLighboxBar'
import { mdiViewDashboard as LightboxIcon} from '@mdi/js';
import Button from '../Button';

interface StyledLighboxBarProps {
  state: any,
}

const LighboxBar = (props : StyledLighboxBarProps) => {
  const { t } = useTranslation()
  return (
    <StyledLighboxBar>
      <Button 
        text={`${t('lightboxes.lightboxes')}:`} 
        icon={LightboxIcon}
        colorScheme="light" 
        style={{'border': 'none','marginRight': '15px','paddingLeft': '0'}} 
        interactive={false}
      />
      { 
        props.state.lightboxes && 
        props.state.lightboxes.map( (lb : any, i : number) => {
          return (
            <Button 
              href={`/my-account/lightboxes/${lb.id}`} 
              key={`lightbox-${i}`} 
              colorScheme="grey" 
              style={{'border': 'none','marginRight': '20px'}}
              >
              {/* {`${lb.name} (${lb.assetCount})`} */}
              <>
                <span style={{maxWidth: "150px", display: "inline-block",whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginRight: "5px", marginTop: "1px"}}>
                  {lb.name}
                </span><span style={{display: "inline-block", float: "right", marginTop: "1px"}}>{`(${lb.assetCount})`}</span>
              </>
            </Button>
          )
        })
      } 
      {/* <Button 
        text={t('lightboxes.add_lightbox')} 
        colorScheme="light" 
        style={{'border': 'none','marginRight': '15px'}} 
      /> */}
    </StyledLighboxBar>
  )
}

export default LighboxBar