import StyledTable from './StyledTable'

type TableProps = {
  headers? : string[],
  rowData : any,
  rowStyles? : any,
  hideColsMobile? : number[],
}

const Table = (props : TableProps) => {
  return (
    <StyledTable>
      { props.headers &&
        <div className="table-header-group">
          { props.headers.map((h : string, i : number) => {
            return (
              <div key={`th-${i}`} className={`table-header ${props.hideColsMobile && props.hideColsMobile.includes(i) ? "hide-mobile" : ""}`}>{h}</div>
            )
          })}
        </div>
      }
      { props.rowData && props.rowData.map((rd : any, i : number) => {
        return (
          <div key={`tr-${i}`} className="table-row">
            { rd.map((c : any, ii : number) => {
              return (
                <div key={`td-${ii}`} className={`table-cell ${props.hideColsMobile && props.hideColsMobile.includes(ii) ? "hide-mobile" : ""}`} style={props.rowStyles && props.rowStyles.length-1 >= ii ? props.rowStyles[ii] : {}}>{c}</div>
              )
            })}
          </div>
        )
      })}
    </StyledTable>
  )
}

export default Table