import styled from '@emotion/styled'

const StyledCartOrder = styled('div')`
  .cart-total {
    font-size: 13px;
    width: calc(64% - 30px);
    float: right;
    margin-right: 4%;
    ul, li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    li {
      height: 40px;
      line-height: 36px;
      &:not(:last-of-type) {
        border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
      }
      div:first-of-type {
        width: 160px;
        float: left;
      }
      div:last-of-type {
        width: calc(100% - 160px);
        float: left;
      }
    }
  }
  .cart-instructions {
    font-size: 13px;
    width: calc(29% - 30px);
    float: left;
    margin-right: 30px;
    margin-top: 50px;
    padding-bottom: 150px;
    p {
      margin-bottom: 25px;
    }
    a {
      color: ${props => props.theme.colors.secondary};
    }
  }
  @media (max-width: 767px) {
    .cart-total {
      width: 100%;
      float: none;
      padding: 0 20px;
    }
    .cart-instructions {
      width: 100%;
      float: none;
      margin-top: 30px;
      padding-bottom: 30px;
      & ~ button {
        width: 100% !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
    }
  }
`

export default StyledCartOrder