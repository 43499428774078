import { useEffect, useRef, useState } from 'react'
import { EqualColumnLayout, InputWrapper } from './StyledLogin'
import { mdiArrowRight as ArrowRightIcon } from '@mdi/js'
import { ReactComponent as LogoLogin } from './agfopro-w-beta.svg'
import { Link, Route, Switch, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import UserForm from '../../components/UserForm'
import FormError from '../../components/FormError'
import Input from '../../components/Input'
import Button from '../../components/Button'
import Loading from '../../components/Loading'
import Image0 from "./images/rafael-heygster_agfo_01.jpg"
import Image1 from "./images/armin_smailovic_agfo_01.jpg"
import Image2 from "./images/axel_martens_agfo_01.jpg"
import Image3 from "./images/daniel_mueller_agfo_01.jpg"
import Image4 from "./images/Jana_Rothe_agfo_01.jpg"
import Image5 from "./images/julius-schrank-agfo-01.jpg"
import Image6 from "./images/lene-muench-agfo-01.jpg"
import Image7 from "./images/lukas-wahl-agfo-01.jpg"
import Image8 from "./images/martin_langer_agfo_01.jpg"

const LogoLink = () => {
  return ( <Link to="/"><LogoLogin/></Link>)
}

const BackgroundImage = () => { 

  const location = useLocation()

  const [imageLoaded, setImageLoaded] = useState(false) 
  const img = new Image()
  const images= [Image0, Image1, Image2, Image3, Image4, Image5, Image6, Image7, Image8 ]
  const [imageIndex, setImageIndex] = useState(Math.floor(Math.random() * images.length))
  img.src = images[imageIndex]

  useEffect(() => {
    setImageIndex(Math.floor(Math.random() * images.length))
  }, [location, images.length])

  img.onload = () => {
    setImageLoaded(true)
  }
  return (
    <div className={imageLoaded ? "loaded" : ""} style={{backgroundImage: `url(${img.src})`}}/>
  )
}

const Login = (props : any) => {
  const { t } = useTranslation()
  const usernameInputEl = useRef<HTMLInputElement>(null)
  const passwordInputEl = useRef<HTMLInputElement>(null)
  const [usernameError, setUsernameError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)

  const validateInput = ( event : React.FormEvent<HTMLFormElement>) => { 
    event.preventDefault()
    const username = usernameInputEl.current?.value.trim()
    const password = passwordInputEl.current?.value.trim()

    if (username && username.length > 0 && password && password.length > 0) {
      setUsernameError(null)
      setPasswordError(null)
      props.handleSubmitLogin(username, password)
    } else {
      if (!username || username.length === 0){
        setUsernameError("errors.username_not_valid")
      } else {
        setUsernameError(null)
      }
      if (!password || password.length === 0){
        setPasswordError("errors.password_not_valid")
      } else {
        setPasswordError(null)
      }
    }
  }

  return (
    <>
      <form className={`loginForm ${props.state.loginError ? 'withErrors' : null}`} onSubmit={(e) => validateInput(e)}>
        <InputWrapper>
          <h1>{t('login.title')}</h1>
          <Input error={usernameError ? t(`${usernameError}`) : null} inputRef={usernameInputEl} name="username" label={t("login.username_email_address")} />
          <Input error={passwordError ? t(`${passwordError}`) : null} inputRef={passwordInputEl} name="password" label={t("login.password")} type="password"/>
          {props.state.errors.loginError &&
            <FormError text={t(props.state.errors.loginError)} />
          }
          <Link to="/reset-password">{t("login.forgot_password")}</Link>
          <Button type="submit" text={t("login.sign_in")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{marginTop: "60px"}}/>
        </InputWrapper>
      </form>
      <InputWrapper>
        <h1>{t("login.new_here")}</h1>
        <Button href="/register" text={t("login.register_now")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{width: "100%", marginTop: "15px"}}/>
      </InputWrapper>
    </>
  )
}

const ResetPassword =(props : any) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState("")
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(null)
  const [requestSent, setRequestSent] = useState(false)

  const changeEmail = ( event : React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event.currentTarget.name)
    setEmail(event.currentTarget.value)
  }

  const submitResetPasswordForm = ( event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const regex = new RegExp(/^\S+@\S+\.\S+$/);
    if (email && email.length > 0 && regex.test(email)) {
      setRequestSent(true)
      setResetPasswordError(null)
      props.handleForgotPassword(email)
    } else if (!email || email.length === 0){
      setResetPasswordError("errors.is_required")
    } else if (!regex.test(email)){
      setResetPasswordError("errors.email_not_valid")
    } 
  }

  return (
    <form className={`resetPasswordForm ${resetPasswordError ? 'withErrors' : null}`} onSubmit={submitResetPasswordForm}>
      <div>
        <InputWrapper>
          <h1>{t("reset_password.title")}</h1>
          { requestSent
            ? <h2 style={{marginTop: "50px", marginBottom: "40px"}}>{t("reset_password.request_sent")}</h2>
            : <>
                <Input error={resetPasswordError ? t(resetPasswordError) : null} value={email} name="username" label={t("reset_password.username_email_address")} onChange={changeEmail}/>
                <Button type="submit" text={t("reset_password.submit")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{marginTop: "40px"}}/>
              </>
          }
        </InputWrapper>
      </div>
    </form>
  )
}

const VerifyPassword =(props : any) => {
  const { t } = useTranslation()

  // const [email, setEmail] = useState<string>(props.state.user?.email as string | "")
  const [passwords, setPasswords] = useState<{password : string, repeatPassword: string}>({password : "", repeatPassword : ""})
  // const [emailError, setEmailError] = useState<string | null>(null)
  const [resetPasswordError, setResetPasswordError] = useState<string | null>(null)
  const [passwordChanged, setPasswordChanged] = useState(false)

  // const changeEmail = ( event : React.ChangeEvent<HTMLInputElement>) => {
  //   setEmail(event.currentTarget.value)
  // }

  const changePassword = ( event : React.ChangeEvent<HTMLInputElement>) => {
    setPasswords({...passwords, [event.currentTarget.name] : event.currentTarget.value })
  }

  const submitVerifyPasswordForm = (event : React.FormEvent<HTMLFormElement>) => {
    // setEmailError(null) 
    setResetPasswordError(null) 
    event.preventDefault()
    // const regex = new RegExp(/^\S+@\S+\.\S+$/);
    // if (!email || email.length === 0 || !regex.test(email)){
    //   setEmailError("errors.email_not_valid")
    // } else 
    if (passwords.password !== passwords.repeatPassword) {
      setResetPasswordError("errors.passwords_dont_match")
    } else if (passwords.password.length < 8) {
      setResetPasswordError("errors.password_too_short")
    } else {
      setPasswordChanged(true)
      setResetPasswordError(null) 
      props.handleVerifyPassword(props.match.params.email, props.match.params.token, passwords.password)
      setPasswords({password : "", repeatPassword : ""})
    }
  }

  return (
    <form className={`resetPasswordForm ${resetPasswordError ? 'withErrors' : null}`} onSubmit={submitVerifyPasswordForm}>
        <InputWrapper>
          <h1>{t('account.change_password_title')}</h1>
          { passwordChanged && props.state.errors.verifyPasswordError === null
            ? <>
                <h2 style={{marginTop: "50px", marginBottom: "40px"}}>{t('reset_password.password_changed')}</h2>
                <Link to="/login">{t("menu.login")}</Link>
              </>
            :  <>
                {/* <Input error={emailError ? t(emailError) : null} value={email} name="username" label={t("reset_password.username_email_address")} onChange={changeEmail}/> */}
                <Input type="password" value={passwords.password} error={null} name="password" label={t("account.new_password")} onChange={changePassword}/>
                <Input type="password" value={passwords.repeatPassword} error={resetPasswordError !== null ? t(`${resetPasswordError}`) : null} name="repeatPassword" label={t("account.repeat_new_password")} onChange={changePassword}/>
                {props.state.errors.verifyPasswordError &&
                  <FormError text={t(props.state.errors.verifyPasswordError)} />
                }
                <Button type="submit" text={t("account.save_password")} size="large" icon={ArrowRightIcon} alignIcon="right" colorScheme="white" float="right" style={{marginTop: "40px"}}/>
              </>
          }
        </InputWrapper>
    </form>
  )
}


const Authentication =(props : any) => {
  const { t } = useTranslation()

  return (
    <div>
      <Switch>
        <Route exact path={"/login"}>
          <EqualColumnLayout>
            <div>
              <BackgroundImage/>
              <LogoLink/>
            </div>
            <div>
              <Login
                state={props.state}
                handleSubmitLogin={props.handleSubmitLogin}
                />
            </div>
          </EqualColumnLayout>
        </Route>
        <Route exact path={"/register"}>
          <EqualColumnLayout>
            <div>
              <BackgroundImage/>
              <LogoLink/>
            </div>
            <div>
            <section>
              <InputWrapper>
                <h1>{t('register.title')}</h1>
              </InputWrapper>
            </section>
              {props.state.registrationSent
              ? <InputWrapper>
                  <h1 style={{marginTop: "70px", marginBottom: "40px"}}>{t('register.registration_sent')}</h1>
                  <Link to="/">{t("menu.start")}</Link>
                </InputWrapper>
              : <UserForm
                  variant="register"
                  state={props.state}
                  handleCreateUser={props.handleCreateUser}
                />
              }
              
            </div>
          </EqualColumnLayout>
        </Route>
        <Route exact path={"/reset-password"}>
          <EqualColumnLayout>
            <div>
              <BackgroundImage/>
              <LogoLink/>
            </div>
            <div>
            <ResetPassword
              state={props.state}
              handleForgotPassword={props.handleForgotPassword}
            />
            </div>
          </EqualColumnLayout>
        </Route>

        <Route path={"/reset-password/:email/:token"} children={ ({ match }) => ( 
          <EqualColumnLayout>
            <div>^
              <BackgroundImage/>
              <LogoLink/>
            </div>
            <div>
            <VerifyPassword
              state={props.state}
              match={match}
              handleVerifyPassword={props.handleVerifyPassword}
            />
            </div>
          </EqualColumnLayout>

        )} />

      </Switch>
      <Loading loading={props.state.loading} colorScheme="dark" variant="fixed"/> 
    </div>
  )
}

Authentication.defaultProps = {
  handleCreateUser : () => {},
  handleUpdateUser : () => {},
}

export default Authentication