import { Link, useLocation } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import Icon from '@mdi/react'
import { mdiMenu as MenuIcon} from '@mdi/js'
import { mdiGhost as GhostIcon} from '@mdi/js'
import { mdiBasket as BasketIcon} from '@mdi/js'
import { mdiViewGrid as GridIcon} from '@mdi/js'
import { mdiMagnify as SearchIcon } from '@mdi/js'
import { mdiCircleOpacity as ThemeIcon } from '@mdi/js'
import { mdiEmoticonHappyOutline as UserIcon} from '@mdi/js'
import { mdiCheckCircle as CheckIcon} from '@mdi/js';
import { mdiCloseCircle as FailIcon } from '@mdi/js';
import { StyledHeader, StyledHomeLink, StyledMenuBar, StatusIcon } from './StyledHeader'
import Menu from '../../components/Menu'
import SearchForm from '../../components/SearchForm'
import Button from '../../components/Button'
import { ReactComponent as LogoBig } from './agfopro-w-beta.svg'
import { ReactComponent as LogoSmall } from './agfopro-w-beta.svg'
import { useState } from "react"

const Header = (props : any) => {

  const { t, i18n } = useTranslation()
  const location = useLocation()
  const variant = location.pathname === '/' || location.pathname.includes("start") ? 'full' : ''
  const [showMenu, setShowMenu] = useState(false)
  const [showSearchMobile, setShowSearchMobile] = useState(false)

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const toggleSearch = () => {
    console.log(!showSearchMobile)
    setShowSearchMobile(!showSearchMobile)
  }

  const mobileSearchToggle = <Button 
    variant="icon"
    icon={SearchIcon} 
    colorScheme="light" 
    float="right" 
    className="search-icon"
    onClick={toggleSearch}
  /> 


  return (
    <StyledHeader className={variant === 'full' ? "" : "sticky"}>
      <div className="fixed-wrapper">
        <StyledHomeLink>
          <Link onClick={() => props.handleHomeLink()} to="/">
            <LogoSmall/> 
            <LogoBig/> 
          </Link>
        </StyledHomeLink>
        <div className="center-links">
          <Link to={`/feeds`}>{t("menu.new_photos")}</Link>
          <Link to={`/stories`}>{t("menu.new_stories")}</Link>
          <a href="https://agentur-focus.com/fotografinnen" target="top">{t("menu.photographers")}</a>
        </div>  
      </div>
      
      <SearchForm 
        variant={variant}
        showSearchMobile={showSearchMobile}
        state={props.state} 
        handleSubmitSearch={props.handleSubmitSearch}
        handleClearSearchTerm={props.handleClearSearchTerm} 
        handleSelectPool={props.handleSelectPool} 
        handleSetShowMobilePoolSelector={props.handleSetShowMobilePoolSelector}
        setShowSearch={setShowSearchMobile}
        searchInputRef={props.searchInputRef}
      />
      <StyledMenuBar>
        <div onClick={toggleMenu} className="menu-icon">
          <Icon path={MenuIcon} title="Menu" />
        </div>
        <Button 
          className="language-button"
          text={i18n.language === "de" ? "en" : "de"}
          colorScheme="light" 
          float="right" 
          // style={{'border': 'none', 'textTransform': 'uppercase', 'marginRight': '15px', 'marginTop': '1px'}} 
          onClick={() => i18n.changeLanguage(i18n.language === "de" ? "en" : "de") } // perhaps reload assets?
          />
        {!props.state.userToken // check for token because is set first 
          ? <>
            <Button 
              // text={t('header.login_register')}
              children={<><span className="login_text_desktop">{t('header.login_register')}</span><span className="login_text_mobile">{t('header.login_mobile')}</span></>}
              href="/login" 
              icon={GhostIcon} 
              colorScheme="light" 
              float="right" 
              style={{ 'marginTop': '1px', 'border': 'none'}} 
              /> 
              {mobileSearchToggle}
            </>
          : <>
              <Button 
                text={props.state.carts && props.state.carts.length > 0 && props.state.carts[0].articles ? props.state.carts[0].articles.length : "" }
                variant="icon"
                href="/my-account/cart"
                icon={BasketIcon} 
                colorScheme="light" 
                float="right" 
                className="desktop-only"
                /> 
              <Button 
                text={props.state.lightboxes && props.state.lightboxes.length > 0 ? props.state.lightboxes.length : "" }
                variant="icon"
                href="/my-account/lightboxes"
                icon={GridIcon} 
                colorScheme="light" 
                float="right"
                className="desktop-only"
              /> 
              <Button 
                variant="icon"
                icon={ThemeIcon} 
                colorScheme="light" 
                float="right" 
                onClick={props.toggleTheme}
                className="desktop-only"
              /> 
              <Button 
                variant="icon"
                href="/my-account"
                icon={UserIcon} 
                colorScheme="light" 
                float="right" 
              /> 
              {mobileSearchToggle} 
            </>
        }
        {props.state.success &&
          <StatusIcon className="success">
            <Icon path={CheckIcon} />
          </StatusIcon>
        }
        {props.state.fail &&
          <StatusIcon className="fail">
            <Icon path={FailIcon} />
          </StatusIcon>
        }
        
      </StyledMenuBar>
      <Menu showMenu={showMenu} closeHandler={toggleMenu} handleLogOut={props.handleLogOut} userLoggedIn={props.state.userLoggedIn}/>
    </StyledHeader>
  )
}

export default Header