import styled from '@emotion/styled'
import hexToRgba from 'hex-to-rgba'

const StyledGrid = styled('div')`
  width: 100%;
  padding: 0 4% 0 4%;   
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    padding: 0;
    
  }
  &.wall {
    padding-bottom: 80px;
    ul {
      margin-right: -8px;
    }
    li.card {
      position: relative;
      /* height: 330px; */
      height: 20vw;
      max-height: 350px;
      flex-grow: 1;
      list-style: none;
      margin-right: 8px;
      margin-bottom: 8px;
      max-width: 100%;
      overflow: hidden;
      cursor: pointer;
      background-color: ${props => props.theme.colors.secondaryAuxiliary};
      &:last-of-type {
        flex-grow: 10;
        /* background-color: ${props => props.theme.colors.secondaryAuxiliary}; */
        cursor: auto;
      }
      .image-caption-toolbar {
        width: 100%;
        height: 100%;
      }
      img {
        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
      }
      .card__caption {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        background-color: ${props => hexToRgba(props.theme.colors.secondaryContrast, 0.6)};
        font-size: 16px;
        padding: 20px;
        height: 100%;
        color: ${props => props.theme.colors.primaryContrast};
        pointer-events: none;

        display: flex;
        flex-direction: column;
        align-content: stretch;
        max-height: 100%;
        & > div:first-of-type {
          flex-grow: 999;
          width: 100%;
          overflow: hidden;
          margin-bottom: 10px;
          position: relative;
          /* &::after {
            content: "";
            top: 0;
            left: -20px;
            right: -20px;
            width: 100%;
            height: 100%;
            position: absolute;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 80%, rgba(0, 0, 0, 0.82) 100%) repeat scroll 0% 0%;
          } */
        }
        .card__toolbar {
          align-self: flex-start;
          flex-grow: 1;
          width: 100%;
          & > * {
            pointer-events: all;
          }
        }
      }
      .card__tags,
      .card__list-detail {
        display: none;
      }
      &:hover {
        div.card__caption {
          opacity: 1;
          transition: opacity 0.35s ease-out;
        }
      }
    }
    @media (max-width: 767px) {
      li.card {
        height: auto;
        max-height: none;
        .image-caption-toolbar {
          width: 100%;
          height: auto;
          a {
            display: block;
            height: auto;
            img {
              min-width: 0;
              max-width: 100%;
            }
          }
        }
      }
    }
  }
  &.grid {
    li.card {
      width: calc(25% - 22.5px);
      margin-right: 30px;
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 55px;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
    img {
      width: 100%;
      height: auto;
      margin-bottom: 3px;
    }
    .card__caption_truncate > div:first-of-type div {
      display: block;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .card__tags,
    .card__list-detail {
      display: none;
    }
    .card__toolbar {
      margin-top: 5px;
    }
    @media (max-width: 1199px) {
      li.card {
        width: calc(25% - 15px);
        margin-right: 20px;
        margin-bottom: 30px;
        
      }
    }
    @media (max-width: 767px) {
      li.card {
        width: calc(50% - 10px);
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }
  &.list { // maybe adapt to flex, c.f. detail
    li.card {
      width: 100%;
      font-size: 13px;
      border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
      margin-bottom: 55px;
      &:nth-of-type(4n) {
        margin-right: 0;
      }
      &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
    .image-caption-toolbar {
      float: left;
      width: calc(50% - 15px);
    }
    img {
      width: 100%;
      height: auto;
      float: left;
      &.card__img-portait {
        width: 80%;
      }
    }
    .card__caption {
      width: 100%;
      float: left;
      clear: left;
      & > div:first-of-type {
        display: none;
      }
      .card__toolbar {
        display: block;
        margin-top: 10px;
        padding-bottom: 30px;
        overflow: hidden;
        .button__icon-only.button__icon-large {
          width: 30px!important;
          height: 30px!important;
          svg {
            width: 25px!important;
            height: 25px!important;
          }
        }
      }
    }
    .card__tags {
      width: 100%;
      overflow: hidden;
      float: left;
      clear: left;
      margin-top: 20px;
      margin-bottom: 55px;
    }
    .card__list-detail {
      display: flex;
      width: calc(50% - 15px);
      float: right;
      margin-bottom: 55px;
      li {
        width: 100%;
        padding-bottom: 15px;
        clear: left;
      }
      div:first-of-type {
        width: 160px;
        float: left;
      }
      div:last-of-type {
        width: calc(100% - 160px);
        float: left;
      }
    }
    &.cart,
    &.downloads {
      padding-top: 50px;
      .image-caption-toolbar {
        width: calc(30% - 30px);
      }
      li.card {
        border-bottom: 1px solid ${props => props.theme.colors.primaryAuxiliary};
      }
      .card__list-detail {
        width: calc(70% - 30px);
      }
      /* .card__caption {
        margin-top: 15px;
      } */
    }
    @media (max-width: 1199px) {
      li.card {
        margin-bottom: 30px;
      }
    }
    @media (max-width: 767px) {
      li.card {
        margin-right: 0;
        margin-bottom: 30px;
      }
      .image-caption-toolbar {
        width: 100% !important;
      }
      img {
        &.card__img-portait {
          width: 100%;
        }
      }
      .card__caption {
        width: 100% !important;
        margin-bottom: 20px;
        .card__toolbar {
          padding-bottom: 0;
        }
      }
      .card__list-detail {
        width: 100% !important;
      }
      &.cart,
      &.downloads {
        padding-top: 30px;
        .image-caption-toolbar {
          width: 60% !important;;
        }
      }
    }
  }
  
`

export default StyledGrid