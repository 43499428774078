import styled from '@emotion/styled'

const StyledFeed = styled('div')`
  position: relative;
  margin-top: 80px;
  color: ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.secondary};
  transition: background-color 0.2s ease-out;
  min-height: 100vh;
  // section {
    .loading{
      .sticky{
        display: none;
      }
    }
  // }
  .center-button { // TODO: move all-button and center button to StyledButton+ option
    margin: 0 4% 0 4%;
    padding: 60px 0;
    text-align: center;
    border-top: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    border-bottom: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
    a {
      text-decoration: none !important;
    }
  }
  @media (max-width: 767px) {
    margin-top: 60px;
  }
`

export default StyledFeed