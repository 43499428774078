import styled from '@emotion/styled'

const SyledPaging = styled('div')`
  width: 100%;
  width: calc(100% - 8%);
  margin: 0 4% 0 4%;
  padding: 0 4% 0 4%; 
  border-top: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
  padding: 60px 0;
  & > div {
    margin: 0 auto;
    width: 310px;
    font-size: 13px;
    padding-bottom: 40px;
    input {
      float: left;
      height: 28px;
      width: 36px;
      border: 1px solid ${props => props.theme.colors.secondaryAuxiliary};
      margin-right: 6px;
      margin-bottom: 2px;
      padding: 0 5px;
      &.withErrors {
        border-color: ${props => props.theme.colors.warn};
      }
    }
    & > span {
      line-height: 28px;
      margin-right: 10px;
      float: left;
      user-select: none;
    }
  }
  
`
  
export default SyledPaging
